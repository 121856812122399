import React, { Component } from 'react';
import './ordersuccess.scss';
import LoadingAnimation from '../../components/checkmarkanimation';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
let outletDetails = null;  // Added by vijay on 04-11-2023-start
let urlPattern = '';
export default class OrderSuccess extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotoShopping = this.gotoShopping.bind(this);
        this.gotoMyOrders = this.gotoMyOrders.bind(this);
        this.state = {
            cartValue: 1500,
            loading: false,
            orderId: '',
            orderNo: '',
            outLetImage: '', // Added by vijay on 04-11-2023-start
        }
    }
    componentWillMount() {
        outletDetails = sessionStorage.getItem('user-outletDetails');   // Added by vijay on 04-11-2023-start
        const parsedOutletDetails = JSON.parse(outletDetails);
        this.setState({ outLetImage: parsedOutletDetails.outletImageUrl })

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.orderId === undefined || this.props.location.state.orderId === null) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/items' + urlPattern);
                }, 1000);
            }
            else if (this.props.location.state.orderNo === undefined || this.props.location.state.orderNo === null) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/items' + urlPattern);
                }, 1000);
            }
            else {
                this.setState({
                    orderId: this.props.location.state.orderId,
                    orderNo: this.props.location.state.orderNo
                });
                //Added by prem 16:20 due to vendor id null pass call api
                sessionStorage.removeItem("user-vendorOrderId");
                sessionStorage.removeItem('cartList');
                sessionStorage.removeItem('modifierCartList');
                sessionStorage.removeItem('taxCartList');
                sessionStorage.removeItem('chargeCartList');
            }
        }
        else {
            //  console.log('out' + this.props.location.state.orderId);
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, 200);
        }


        window.scrollTo(0, 0);

    }
    componentDidMount() {
        // this.setState({ loading: true });
        // added by dasprakash on 21-10-2023 jira-1644
        window.addEventListener('popstate', (event) => {
            this.props.history.push('/items' + urlPattern)
        });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/items' + urlPattern);
        }, Config.minMsgTimeout);
    }
    goBack() {
        this.props.history.push('/home' + urlPattern);
    }
    gotoShopping() {
        this.props.history.push('/items' + urlPattern);
    }
    gotoMyOrders() {
        this.props.history.push('/myorders' + urlPattern);
    }
    render() {
        return (
            <div>
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-ordersuccess">
                            {/* <div className="heading-page" ref="recommended">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span> */}
                            {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            {/* </div> */}

                            {/* //Added by vijay on 25-10-2023  Jira id 1687 */}
                            <div className="logo-v2-sucess">
                                <img style={{ width: "220px", marginLeft: "12px", marginBottom: "30px" }} src={this.state.outLetImage} alt="this.state.outLetImage" />

                                {/* //<img style={{ width: "220px", marginLeft: "12px", marginBottom: "30px" }} src='https://d1wq9c57vggmqp.cloudfront.net/LUCID/1000000013/d9e4ae4071884efe9d01b46c20f1273e_HAMR_1696828487926.png' alt="this.state.outLetImage" /> */}
                            </div>

                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">

                                        <div className="msg">Your order has been  <br /> placed successfully </div>
                                        <div><LoadingAnimation /></div>    {/* //Added by vijay on 25-10-2023  Jira id 1687 */}
                                        {/* {this.state.orderNo !== "" && <div className="msg-order-id">Order No : <span style={{ fontSize: "16px", color: "#212529" }}>{this.state.orderNo === "" ? "-" : this.state.orderNo.substr(-4)}</span></div>} */}
                                        {/* <div className="msg-order-id">Order Id : <span style={{fontSize: "16px", color: "#212529"}}>{this.state.orderId}</span></div> */}   {/* //Commended by vijay on 25-10-2023  Jira id 1687 */}
                                        {/* <div><button className="theme-button btn" onClick={this.gotoShopping}>Continue Ordering </button></div> */}
                                        {/* <div className="btn-orders" onClick={this.gotoMyOrders}>Go to My Orders</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

