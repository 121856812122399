import React, { Component } from "react"
import './payment.scss';
//import LoadingAnimation from '../../components/loadinganimation/verificationloading';
// import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
//import * as QueryString from "query-string"
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import CartCalCulation from '../../modules/cart/cartcalculation';
import sHelpers from '../../modules/helpers/statichelpers';
//import uuid from 'react-uuid';
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
//let vendorId = '';
//let prepaidCardAccountId = '';
//let vendorName = '';
let orderInstruction = '';
let cartList = {};
let modifierCartList = [];
let taxList = [];
let chargeList = [];
let orderChargeList = [];
let _uniquesGroups = [];
let totalItemAmount = 0;
let totalItemTaxAmount = 0;
let totalItemChargesAmount = 0;
let payonDeliveryMode = '';
let proceedPOD = null;
//let totalItems = 0;
let totalChargesAmount = 0;
let totalTaxAmount = 0;
let totalAmount = 0;
let vendorOrderId = '';
let mainGuestMobileNo = '';
let guestCurrentAddress = null;
let outletDetails = null;
let olDetail = {};
 let urlPattern  = '';
export default class PrepaidCardVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            paymentInitiatedId: "",
            statusMsg: "",
            roundOff: 0
        }
    }
    componentDidMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        this.setState({ statusMsg: "Processing Your Request" });
        // console.log(inputParam);

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');


        // vendorId = sessionStorage.getItem('vendorId');
        // vendorName = sessionStorage.getItem('vendorName');
        orderInstruction = sessionStorage.getItem('order-instruction');
        vendorOrderId = sessionStorage.getItem('user-vendorOrderId');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        guestCurrentAddress = sessionStorage.getItem('user-CurrentAddress') === null ? null : JSON.parse(sessionStorage.getItem('user-CurrentAddress'));
        outletDetails = sessionStorage.getItem('user-outletDetails');
        payonDeliveryMode = sessionStorage.getItem('payonDeliveryMode') === null ? null : sessionStorage.getItem('payonDeliveryMode');
        proceedPOD = sessionStorage.getItem('proceed-pod') === null ? null : sessionStorage.getItem('proceed-pod');
        urlPattern = sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
    
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
        }


        if (payonDeliveryMode === undefined || payonDeliveryMode === "" || payonDeliveryMode === null) {
            this.showMessage('Transaction failed. Please try again', "danger");
            this.setState({ loading: true });
            setTimeout(() => { this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
            return false;
        }
        else if (proceedPOD === undefined || proceedPOD === false || proceedPOD === null) {
            this.showMessage('Transaction failed. Please try again', "danger");
            this.setState({ loading: true });
            setTimeout(() => { this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
            return false;
        }

        taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        modifierCartList = sessionStorage.getItem("modifierCartList") === null ? [] : JSON.parse(sessionStorage.getItem("modifierCartList"));
        chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
        cartList = sessionStorage.getItem("cartList") === null ? [] : JSON.parse(sessionStorage.getItem("cartList"));

        window.scrollTo(0, 0);
        this.setState({ loading: true });
        setTimeout(() => {
            this.handlePlaceOrder();
        }, Config.minMsgTimeout);
    }

    handlePlaceOrder() {
        let postItemList = [];
        let addressObj = null;
        if (cartList.length === 0) {
            this.setState({ loading: true });
            this.showMessage('OOPS! Error Order was not placed', "danger");
            setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
            return false;
        }
        else if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.showMessage('OOPS! Error Order was not placed', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);

            return false;
        }

        orderChargeList = sessionStorage.getItem("order-charge-List") === null ? [] : JSON.parse(sessionStorage.getItem("order-charge-List"));

        Array.prototype.forEach.call(cartList.itemList, itm => {
            let postTaxes = [];
            let postModifiers = [];
            let postCharges = [];
            _uniquesGroups = [];


            // tax
            let ctax = taxList.filter(x => x.uid === itm.uid);
            ctax.map(tx =>
                postTaxes.push({ TaxCode: tx.taxCode, TaxValueType: tx.taxType, TaxValue: tx.taxValue, TaxAmount: tx.taxAmount })
            )

            //modifier
            let cmodifer = modifierCartList.filter(x => x.uid === itm.uid);
            // console.log(cmodifer);
            var _taxItmFound = {};
            for (var md = 0, lenc = cmodifer.length; md <= lenc - 1; md++) {
                var stringified = JSON.stringify(cmodifer[md].modifierGroupId);
                if (_taxItmFound[stringified]) { continue; }
                _uniquesGroups.push({ groupId: cmodifer[md].modifierGroupId, groupName: cmodifer[md].modifierGroupName });
                _taxItmFound[stringified] = true;
            };
            //  console.log(_uniquesGroups);
            // Modifier
            Array.prototype.forEach.call(_uniquesGroups, mdg => {
                var modifr = [];
                let mdlist = cmodifer.filter(x => x.modifierGroupId === mdg.groupId);
                Array.prototype.forEach.call(mdlist, md => {
                    let modifiertax = [];
                    let modifierCharge = [];

                    //tax
                    Array.prototype.forEach.call(md.taxes, x => {
                        modifiertax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                    });

                    //charge
                    Array.prototype.forEach.call(md.charges, chg => {
                        let chargetax = [];

                        //tax
                        Array.prototype.forEach.call(chg.taxes, x => {
                            chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                        })

                        // IsMerchantCharge is hardcorded 
                        modifierCharge.push({
                            ChargeId: chg.chargeId, ChargeName: chg.chargeName,
                            ChargeType: chg.chargeType, IsMerchantCharge: false, ChargeValueType: chg.chargeValueType,
                            ChargeValue: chg.chargeValue, ChargeAmount: chg.chargeAmount,
                            Taxes: chargetax
                        })
                    });

                    modifr.push({ ModfierId: md.modifierId, ModifierName: md.modifierName, Rate: md.rate, Quantity: itm.qty, Amount: md.rate * itm.qty, Taxes: modifiertax, Charges: modifierCharge })
                })
                postModifiers.push({ ModifierGroupId: mdg.groupId, GroupName: mdg.groupName, Modifiers: modifr });
            });
            // console.log(postModifiers);

            // charges           
            let ccharge = chargeList.filter(x => x.uid === itm.uid);

            Array.prototype.forEach.call(ccharge, chg => {
                let chargetax = [];

                //tax
                Array.prototype.forEach.call(chg.taxes, x => {
                    chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                })

                // IsMerchantCharge is hardcorded 
                postCharges.push({
                    ChargeId: chg.chargeId, ChargeName: chg.chargeName,
                    ChargeType: chg.chargeType, IsMerchantCharge: false, ChargeValueType: chg.chargeValueType,
                    ChargeValue: chg.chargeValue, ChargeAmount: chg.chargeAmount,
                    Taxes: chargetax
                })
            });
            //  console.log(postCharges);

            postItemList.push({
                ItemId: itm.itemId,
                ItemName: itm.itemName, Rate: itm.rate,
                Quantity: itm.qty, Amount: (itm.qty * itm.rate),
                Instruction: itm.specInsc === undefined ? "" : itm.specInsc,
                Taxes: postTaxes,
                ModifierGroups: postModifiers,
                Charges: postCharges
            });

        });
        let orderdate = new Date();
        let mseconds = orderdate.getTime(); //1440516958
        let deliveryTime = orderdate.getTime() + (olDetail.minimumDeliveryTime * 60000); //1440516958


        // Get Address 
        if (serviceType === Config.serviceType.DELIVERY) {
            addressObj = {
                AddressId: guestCurrentAddress.addressId,
                AddressLine1: guestCurrentAddress.addressLine1,
                AddressLine2: guestCurrentAddress.addressLine2,
                AddressLine3: guestCurrentAddress.addressLine3,
                Area: guestCurrentAddress.area,
                City: guestCurrentAddress.city,
                State: guestCurrentAddress.addressId,
                Landmark: guestCurrentAddress.landmark,
                ZipCode: guestCurrentAddress.zipCode,
                AddressType: guestCurrentAddress.addressType
            }
        }
        // console.log(mseconds);
        //  let paymentDetails = [];

        this.handleCartRecalculate();


        //vendorOrderId = uuid();
        vendorOrderId = sessionStorage.getItem('user-vendorOrderId');

        ///  Get QR Location 
        let specialInstu = '';
        // console.log(olDetail.orderInstructionIncludeQRCodeLocation);
        if (olDetail.orderInstructionIncludeQRCodeLocation === true) {
            specialInstu = sessionStorage.getItem('url-remarks') === null ? '' : sessionStorage.getItem('url-remarks') !== '' ? sessionStorage.getItem('url-remarks') : '';
            // console.log(specialInstu);
        }

        /// Order Charges
        let orderCharges = [];
        Array.prototype.forEach.call(orderChargeList, ochg => {
            let chargetax = [];
            //tax
            Array.prototype.forEach.call(ochg.taxes, x => {
                chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
            })

            // IsMerchantCharge is hardcorded 
            orderCharges.push({
                ChargeId: ochg.chargeId, ChargeName: ochg.chargeName,
                ChargeType: ochg.chargeType, IsMerchantCharge: false, ChargeValueType: ochg.chargeValueType,
                ChargeValue: ochg.chargeValue, ChargeAmount: ochg.chargeAmount,
                Taxes: chargetax
            });
        });

        // console.log(orderCharges);

        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            GuestDetails: {
                //MobileNo: mainGuestMobileNo,
                MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo ===  "0000000000") ? mobileNo : mainGuestMobileNo,
                OrderedGuestMobileNo: mobileNo,
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                Address: addressObj === null ? {} : addressObj
            },
            OrderDetails: {
                VendorOrderId: vendorOrderId,
                OrderDateTime: mseconds,
                DeliveryDateTime: serviceType === Config.serviceType.DELIVERY ? deliveryTime : null,
                TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo,
                OrderStatus: "placed",
                //Instruction: specialInstu + '' + (OrderInstruction === null ? '' : OrderInstruction),
                Instruction: (specialInstu !== '' ? specialInstu : '') + (orderInstruction === null ? '' : (specialInstu !== '' && orderInstruction !== '') ? ' - ' + orderInstruction : orderInstruction),
                Items: postItemList,
                TotalItemValue: totalItemAmount,
                TotalItemTaxes: totalItemTaxAmount,
                TotalItemCharges: totalItemChargesAmount,
                TotalTaxes: totalTaxAmount,
                TotalCharges: totalChargesAmount,
                TotalAmount: totalAmount,
                IsPaid: true,
                Charges: orderCharges,
                PayOnDelivery: true,
                PayOnDeliveryMode: payonDeliveryMode
            }
        }
        this.setState({ loading: true });

        //  console.log(postObj);

        fetch(Config.basePOSUrl + 'PlaceOrder', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        sessionStorage.removeItem("vendorId");
                        sessionStorage.removeItem("vendorName");
                        sessionStorage.removeItem("paymentStatus");
                        sessionStorage.removeItem("order-instruction");
                        //sessionStorage.removeItem("user-vendorOrderId");Commented by prem 16:20 due to vendor id null pass call api
                        sessionStorage.removeItem('payonDeliveryMode');
                        sessionStorage.removeItem('proceed-pod');
                        //  console.log(response);
                        this.props.history.push('/ordersuccess' + urlPattern,
                            {
                                // orderId: response.response.orderId
                                orderId: vendorOrderId,
                                orderNo: response.response != null ? response.response.orderNo : ''// orderNo: response.response.orderNo
                            });
                    }, Config.minMsgTimeout);
                }
                else {
                    // console.log(response.message);
                    this.setState({ loading: true });
                    this.showMessage('Error Order was not placed', "danger");
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
                }
            })
            .catch(err => {
                //  console.log(err);
                this.setState({ loading: true });
                this.showMessage('Error Order was not placed', "danger");
                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
            });
    };
    handleCartRecalculate() {
        // re calculate cart value     
        let cartItemValue = 0;
        // let cartItemQty = 0;
        let modifierCartValue = 0;

        totalTaxAmount = 0;
        totalChargesAmount = 0;
        totalAmount = 0;

        totalItemAmount = 0;
        totalItemTaxAmount = 0;
        totalItemChargesAmount = 0;


        //   console.log(cartList);

        if (sessionStorage.getItem("cartList") !== null) {
            Array.prototype.forEach.call(cartList.itemList, itm => {
                //   console.log(itm.qty);
                cartItemValue += (itm.rate * itm.qty);
                // cartItemQty += itm.qty;
                // console.log(cartItemValue);
                let mdcartItemList = modifierCartList.filter(x => x.uid === itm.uid);
                //  console.log(mdcartItemList);
                Array.prototype.forEach.call(mdcartItemList, md => {
                    modifierCartValue += md.rate * itm.qty;
                });
            });

            totalItemTaxAmount = taxList.reduce((prev, next) => prev + next.taxAmount, 0);
            totalItemTaxAmount += modifierCartList.reduce((prev, next) => prev + next.totalTaxAmount, 0);

            totalItemChargesAmount = chargeList.reduce((prev, next) => prev + next.chargeAmount, 0);
            totalItemChargesAmount += modifierCartList.reduce((prev, next) => prev + next.totalChargeAmount, 0);
            totalChargesAmount = totalItemChargesAmount;



            totalItemAmount = cartItemValue + modifierCartValue;


            totalTaxAmount = chargeList.reduce((prev, next) => prev + next.totalTaxAmount, 0);
            totalTaxAmount += totalItemTaxAmount;

            let onBillTaxValue = CartCalCulation.OnBillTaxCalculation(totalItemAmount);
            let orderLevelCharge = sessionStorage.getItem('order-charge-Amount') === null ? 0 : parseFloat(sessionStorage.getItem('order-charge-Amount'));
            let orderLevelChargeTaxAmount = sessionStorage.getItem('order-charge-Tax-Amount') === null ? 0 : parseFloat(sessionStorage.getItem('order-charge-Tax-Amount'));
            // console.log(orderLevelCharge);
            totalChargesAmount += orderLevelCharge;
            totalTaxAmount += onBillTaxValue;
            totalTaxAmount += orderLevelChargeTaxAmount;

            totalAmount = totalItemAmount + totalChargesAmount + totalTaxAmount;

            let roundOffAmt = sHelpers.roundOff(olDetail.roundOffType, totalAmount, olDetail.roundOffValue);
            // let rndOffAmt = roundOffAmt - totalAmount;
            totalAmount = roundOffAmt;
        }
    }
    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    render() {
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-verification">
                            <div className="heading-page">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">
                                        {/* <div><LoadingAnimation /></div> */}
                                        <div className="msg">{this.state.statusMsg}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}