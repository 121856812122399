import React, { Component } from 'react';
//import Footer from '../../components/footer';
import defaultimg from '../items/default.png'
import Header from '../../components/Header';
import './items.scss';
import Cart from '../../components/cart';
// import PopupMenus from '../../components/popumenus';
import Modifier from '../../components/modifier';
import uuid from 'react-uuid';
import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../components/loadinganimation';
import { isMobile } from 'react-device-detect';
import enmConfig from '../../data/enumConfig';
import Config from '../../data/SiteConfig';
import Request from '../../components/request';
import GroupMembers from '../../components/popup/groupmembers';
import ImagePopup from '../../components/popup/image';
// commented by dasprakash on 21-11-2023 jira-1741
// import Slider from "react-slick";
import { css } from 'glamor';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Currency from '../../components/currency';
import sHelpers from '../../modules/helpers/statichelpers';


// import { lstat } from 'fs-extra';
// const homeslidersettings = {
//     autoplay: true,
//     dots: true,
//     infinite: true,
//     arrows: false,
//     speed: 300,
//     slidesToShow: 1,
//     adaptiveHeight: false

// };


// commented by dasprakash on 21-11-2023 jira-1741
// const clfSettings = {
//     // dots: false,
//     // infinite: false,
//     // speed: 500,
//     // slidesToShow: 4,
//     // slidesToScroll: 4,
//     // initialSlide: 0,
//     // nextArrow: <></>,
//     // prevArrow: <></>,
//     // variableWidth: false,    
//     // responsive: [
//     //     {
//     //         breakpoint: 1024,
//     //         settings: {
//     //             slidesToShow: 3,
//     //             slidesToScroll: 3
//     //         }
//     //     },
//     //     {
//     //         breakpoint: 600,
//     //         settings: {
//     //             slidesToShow: 3,
//     //             slidesToScroll: 3,
//     //             initialSlide: 0
//     //         }
//     //     },
//     //     {
//     //         breakpoint: 480,
//     //         settings: {
//     //             slidesToShow: 3,
//     //             slidesToScroll: 3,
//     //             initialSlide: 0
//     //         }
//     //     }
//     // ]

//     className: "slider variable-width",
//     dots: false,
//     infinite: false,
//     centerMode: false,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     variableWidth: true,
//     nextArrow: <></>,
//     prevArrow: <></>
// };


// const mngpSettings = {
//     // dots: false,
//     // infinite: false,
//     // speed: 500,
//     // slidesToShow: 4,
//     // slidesToScroll: 4,
//     // initialSlide: 0,
//     nextArrow: <></>,
//     prevArrow: <></>,
//     // variableWidth: true,
//     className: "slider variable-width",
//     speed: 500,
//     dots: false,
//     infinite: false,
//     centerMode: false,
//     // slidesToShow: 1,
//     slidesToScroll: 2,
//     variableWidth: true,
//     initialSlide: 0,
//     cssEase: "linear"
//     // responsive: [
//     //     {
//     //         breakpoint: 1024,
//     //         settings: {
//     //             slidesToShow: 3,
//     //             slidesToScroll: 3
//     //         }
//     //     },
//     //     {
//     //         breakpoint: 600,
//     //         settings: {
//     //             slidesToShow: 3,
//     //             slidesToScroll: 3,
//     //             initialSlide: 0
//     //         }
//     //     },
//     //     {
//     //         breakpoint: 480,
//     //         settings: {
//     //             slidesToShow: 3,
//     //             slidesToScroll: 3,
//     //             initialSlide: 0
//     //         }
//     //     }
//     // ]
// };
//import Cookies from 'universal-cookie';
let loadcomfn = null;
let guestLoggedIn = false;
const imageUrl = '/img/item/item-icon.png';
let totalItems = 0;
let totalAmount = 0;
let cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
let taxList = [];
//let modifierList = [];
let chargeList = [];
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let verifiedTable = '';
let showFilterDetails = false;
let getClassification = [];
let getMenus = [];
let getItems = [];
let getGlobalItems = [];
let getTaxes = [];
let getModifiers = [];
let getCharges = [];
let recommendedList = [];
let isUpdateMenu = false;
let isorderTracking = "false";
// group pin added by dasprakash on 06-10-2023 jira-1490
let tablePins = '';
// added by dasprakash on 20-11-2023 jira-1741
let previousNumericId = null;
let isCheckVeg = false;
let isCheckNonVeg = false;
var getFiltersList = [];
// tem add



//let getCMSSettings = {};
//const BrowserHistory = require('react-router/lib/BrowserHistory').default;
// const itemslist = [
//     {
//         mnuId: 1, itemImageUrl: "/img/item/item-icon.png", mnuName: "Veg Manchurian", price: 127, foodType: 1,badge:1,shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 2, itemImageUrl: "/img/item/chilli_paneer.jpg", mnuName: "Chilli Paneer", price: 127, foodType: 1,badge:0, shortName: "Chilli Paneer", description: "Chilli Paneer",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 3, itemImageUrl: "/img/item/item-icon.png", mnuName: "Wok Tossed Chilli Chicken", price: 166, foodType: 2,badge:2, shortName: "Chilli Chicken", description: "Wok Tossed Chilli Chicken",
//         modifier: []
//     },
//     {
//         mnuId: 4, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 2,badge:0, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 5, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 1,badge:2, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: []
//     },
//     {
//         mnuId: 6, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 1,badge:1, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 7, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 2,badge:1, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },


// ];
// const itemGroupList = [
//     { groupId: 1, groupName: "Compos" }, { groupId: 2, groupName: "Fries" }, { groupId: 3, groupName: "Shawarmas" }
// ];
const modifierRef = React.createRef();
const reqeustRef = React.createRef();
const imageRef = React.createRef();
const groupMemberRef = React.createRef();
//const [isVisible, setIsVisible] = useState(true);
let outletDetailsStr = null;
let outletDetails = {}
//let groupPin = '';
let grouppinstyle = {
    marginBottom: "40px",
    marginTop: "162px"
};
//let mainGuestMobileNo = '';
let isappOrders = true;
let mobileApp = true;
let pageTitle = '';
let userSessionId = '';
let urlPattern = '';
//  temp add

const SmartText = ({ text, length = 20 }) => {
    const [showLess, setShowLess] = React.useState(true);

    const toggleShowLess = () => {
        setShowLess(!showLess);
    };

    if (text.length < length) {
        return <p>{text}</p>;
    }

    return (
        <div>
            <span
                dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}.` : text,
                }}
            ></span>
            <span
                style={{ color: "#000", fontWeight: "500", fontSize: "14px", cursor: "pointer", textTransform: "lowercase" }}
                onClick={toggleShowLess}
            >
                {showLess ? " more" : ""}
            </span>
        </div>
    );
};

export default class Items extends Component {

    constructor(props) {
        super(props);


        this.goBack = this.goBack.bind(this);
        this.goMyOrders = this.goMyOrders.bind(this);
        this.goCart = this.goCart.bind(this);
        this.callBackModifier = this.callBackModifier.bind(this);
        this.callBackRequest = this.callBackRequest.bind(this);
        this.itemTaxCalc = this.itemTaxCalc.bind(this);
        this.goBill = this.goBill.bind(this);
        this.goToOrderTracking = this.goToOrderTracking.bind(this);
        this.goToHelpRequest = this.goToHelpRequest.bind(this);
        this.goToGroupMemberList = this.goToGroupMemberList.bind(this);
        this.gotoLogin = this.gotoLogin.bind(this);
        this.handleQtyIncDec = this.handleQtyIncDec.bind(this);
        this.handleclearfilter = this.handleclearfilter.bind(this);
        this.state = {
            // state created by dasprakash on 25-08-2023 jira-1302
            id: "",
            pagnitationPrvShow: -1,
            pagnitationNextShow: -1,
            previousMenu: [],
            nextMenu: [],
            lastIndex: 0,
            menuGroupIndex: 0,
            selectedClsf: [],
            selectedClassificationIndex: 0,
            expanded: false,
            nextPage: [],
            perviousPage: [],
            paginationRecommendedList: [],
            paginationEnable: true,
            fliteredRecList: [],
            searchSelectedMenuGroup: [],
            searchselectedMenugroupIndex: "",
            searchClassificationIndex: "",
            searchSelectedClassification: [],
            // level 6 change
            recpagination: true,
            classificationRecommendedImageIndex: '',
            accessModeShow: false,
            //added by dasprakash on 22-11-2023
            fliteredMenuGroup: [],

            selectedItem: [],
            loading: false,
            getClassification: [],
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            recommendedList: [],
            isRecommendedItemEnabled: false,
            recommendedItemCount: 0,
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            groupName: '',
            groupId: '',
            switch1: false,
            switch2: false,
            activeMenuGroup: -1,
            totalItems: 0,
            totalAmount: 0,
            cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] },
            modifierGroupIds: [],
            modifierItemName: '',
            modifierItemId: 0,
            modifierItemCQty: 0,
            modifierItemCRate: 0,
            modifierItemCId: "",
            modifierItemCGroupId: 0,
            modifierItemCTaxId: 0,
            modifierItemCChargeIds: [],
            modifierFoodType: '',
            modifierTotalItemAmount: 0,
            checkNoQty: new Map(),
            searchText: "",
            themeId: 0, // No Theme,
            orders: [],
            showModal: false,
            imgItemName: '',
            imgItemDesc: '',
            imgItemPrice: '',
            imgItemFType: '',
            itemImgUrl: '',
            menuGroupsId: '',
            styleBackgroundImage: {
                background: `url(${imageUrl}) no-repeat center center fixed`,
                WebkitBackgroundSize: "cover",
                BackgroundSize: "cover",
                MozBackgroundSize: "cover",
                OBackgroundSize: "cover",
                // fontFamily: "Nunito, sans-serif ",
                border: "none",
                paddingBottom: "52px",
            },
            styleHeading: {
                backgroundColor: "#59324c",
                color: "#fff",
                height: "110px",
                fontSize: "18px",
                // fontFamily: "Nunito, sans-serif ",
                padding: "10px 14px 8px 40px",
                borderBottom: "1px solid #fff"
            },
            styleSubHeading: {
                color: "#f9bd09",
                fontSize: "12px",
                // fontFamily: "Nunito, sans-serif "
            },
            styleMenuGroup: {
                color: "#000",
                fontSize: "6.94vw",
                // fontFamily: "Nunito, sans-serif ",
                backgroundColor: "#fff",
                lineHeight: "2rem",
                fontWeight: "600"
            },
            styleMengroupAfter: css({
                ':after': [{ borderLeft: "16px solid #59324c", content: `''`, }]
            }),
            styleMengroupBefore: css({
                ':before': [{ borderBottom: "9px solid #59324c", content: `''`, }]
            }),

            styleSubMenuGroup: {
                color: "#000",
                fontSize: "4.94vw",
                // fontFamily: "Nunito, sans-serif ",
                backgroundColor: "#fff",
                lineHeight: "2rem",
                fontWeight: "600"
            },

            styleMenuItem: {
                color: "#262626",
                fontSize: "4.27vw",
                // fontFamily: "Nunito, sans-serif ",
                fontWeight: "600",
                lineHeight: "1.2",
            },

            styleMenuDesc: {
                color: "#444",
                fontSize: "2.67vw",
                // fontFamily: "Nunito, sans-serif ",
                marginTop: "4px",
                // letterSpacing: "1px",
                lineHeight: "1.2"
            },
            styleMenuPrice: {
                color: "#262626",
                fontSize: "3.2vw",
                // fontFamily: "Nunito, sans-serif ",
                fontWeight: "600",
                letterSpacing: "-1px"
            },
            stylePopupMenubBtn:
            {
                color: "#f9f2ea",
                // fontFamily: "Nunito, sans-serif ",
                backgroundColor: "#59324c",
            },
            stylePopupText:
            {
                color: "#59324c",
                // fontFamily: "Nunito, sans-serif ",
            },
            styleContent:
            {
                color: "#f9f2ea",
                fontSize: "13px",
                backgroundColor: "#fcf9ea",
            },
            styleRefresh:
            {
                color: "#fff",
            },
            stylegroupPin:
            {
                marginBottom: "40px",
                marginTop: "214px"
            },
            pagePreview: "",
            pageContent: "",
            selectedFiltersCount: 0,
            groupPin: '',
            ordertrack: false,
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/no-image.png",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            activeClassification: -1,
            selectedClassification: 0,
            selectedMenuGroupId: 0,
            recommendedTxt: "Recommended",
            pageTitle: '',
            groupMemberList: [],
            showOnlySelectedMenuGroupItems: false,
            showOnlyVeg: false,
            showOnlyNonVeg: false,
            isFunctionCall: true,
            styleMoveMenuCLFFilterDivTop:
            {
                top: ""
            },
            styleItemFilterDiv:
            {
                display: ""
            },
            styleItemFilterSearchDiv:
            {
                display: ""
            },
            styleItemFilterVegDiv:
            {
                display: "",
                marginTop: "16px"
            },
            styleItemFilterRefreshDiv:
            {
                display: ""
            },
            tablePin: '',
            isExpanded: true,  //Added by prem LDT-2746

        }
        this.menuListRef = React.createRef();
    }


    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        // added by dasprakash on 21-11-2023 jira-1741

        //sessionStorage.removeItem("user-vendorOrderId"); //Commented by prem 16:20 due to vendor id null pass call api

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        verifiedTable = sessionStorage.getItem('user-table-verified');
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        //  mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        isorderTracking = sessionStorage.getItem('order-track-status') == null ? "false" : sessionStorage.getItem('order-track-status');
        // group pin added by dasprakash on 06-10-2023 jira-1490
        tablePins = sHelpers.secureStorage.getItem('user-table-pin');
        //tablePins = sessionStorage.getItem('table-pins') == null ? '' : sessionStorage.getItem('table-pins');
        userSessionId = sessionStorage.getItem('user-session-id') == null ? "" : sessionStorage.getItem('user-session-id');
        //getCMSSettings = sessionStorage.getItem('pageCMSSettings') == null ? null : JSON.parse(sessionStorage.getItem('pageCMSSettings'));
        urlPattern = sessionStorage.getItem('url-pattern');
        // ////////////////console.log(mobileNo);
        // ////////////////console.log(token);
        // ////////////////console.log(tableNo);
        // ////////////////console.log(servic0eType);
        // ////////////////console.log(orgnationTd);
        // ////////////////console.log(propertyId);
        // ////////////////console.log(outletCode);

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }


        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (verifiedTable === undefined || verifiedTable === "" || verifiedTable === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            outletDetails = JSON.parse(outletDetailsStr);
            enmConfig.checkOutTypewithPay = outletDetails.paymentVendors.length > 0 ? true : false;
            this.setState({ outletImg: outletDetails.outletImageUrl });
        }


        if (isorderTracking === undefined || isorderTracking === "" || isorderTracking === null) {
            isorderTracking = "false";
        }


        if (isorderTracking === "true")
            this.setState({ ordertrack: true });
        else
            this.setState({ ordertrack: false });

        window.scrollTo(0, 0);
        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }
        const selectedFilters = JSON.parse(sessionStorage.getItem('selectedFilters') || '[]');
        // console.log(selectedFilters.length,'selectedFilters');
        // Added by vijay on 31-01-2025
        getFiltersList = sessionStorage.getItem("item-tag-filter-list") === null ? [] : JSON.parse(sessionStorage.getItem("item-tag-filter-list"));
        //  console.log(getFiltersList, 'getFiltersList');

        getClassification = sessionStorage.getItem("getclf") === null ? [] : JSON.parse(sessionStorage.getItem("getclf"));

        getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
        // Added by vijay on 31-01-2025
        //  console.log(this.state.selectedFiltersCount);
        if (getFiltersList.length > 0 && selectedFilters.length > 0) {

            const classificationIds = getFiltersList.map(item => item.classification);
            // Get the classification list from session storage
            var getClassificationList = sessionStorage.getItem("getclf") === null ? [] : JSON.parse(sessionStorage.getItem("getclf"));
            getClassification = getClassificationList.filter(classItem => classificationIds.includes(classItem.classification));
            //  console.log(getClassification, 'Filtered getClassification');
            getItems = sessionStorage.getItem("item-tag-filter-list") === null ? [] : JSON.parse(sessionStorage.getItem("item-tag-filter-list"));

        }
        else {
            getClassification = sessionStorage.getItem("getclf") === null ? [] : JSON.parse(sessionStorage.getItem("getclf"));
            getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));

        }

        getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
        getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
        getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));
        recommendedList = getItems.filter(itm => itm.isRecommended);
        getGlobalItems = getItems;

        this.setState({
            recommendedList: recommendedList,
            getMenus: getMenus,
            getItems: getItems,
            getClassification: getClassification
        });
        //  console.log(recommendedList,'recommendedList');
        //  console.log(getItems,'getItems');
        // console.log(getMenus,'getMenus');
        // console.log(getGlobalItems,'getGlobalItems');

        this.getCMSDetails();
        // if (getCMSSettings === null) {
        //     // CMS Settings
        //     this.getCMSDetails();
        // }
        // else {
        //     // ////////////////console.log(getCMSSettings);
        //     this.setState({ themeId: getCMSSettings.page.themeId });
        // }

        //////console.log("outletDetails.showOnlySelectedMenuGroupItems", outletDetails.showOnlySelectedMenuGroupItems)
        if (outletDetails.showOnlySelectedMenuGroupItems === false && outletDetails.showMenuGroupInGuestApp === true) {
            //////console.log("running in will mount")
            window.addEventListener('scroll', this.handleScroll);
        }

        // Enable Modifier
        this.setState({ showModal: true });
        //  if no special[ex- new, bestseller, musttry] items no means need to hide that filter control
        const items = sessionStorage.getItem("getmenuItems");
        if (items) {
            const parsedItems = JSON.parse(items);

            // Check if at least one category has a `true` value
            showFilterDetails = parsedItems.some(item =>
                item.newItem || item.popularItem || item.todaySpecialItem ||
                item.chefSpecialItem || item.mustTryItem || item.bestSellerItem
            );
        }
    }

    componentDidMount() {

        // ////////////////console.log(this.props.location.state.selectedClassification);
        // if (this.props.location.state && this.props.location.state.selectedClassification) {
        // } else {
        //     this.props.history.push('/home');
        // }


        if (this.props.location.state && this.props.location.state.selectedMenuGroup) {

            this.setState({
                searchSelectedMenuGroup: this.props.location.state.selectedMenuGroup,
                searchselectedMenugroupIndex: this.props.location.state.selectedMenugroupIndex,
                searchClassificationIndex: this.props.location.state.classificationindex,
                searchSelectedClassification: this.props.location.state.searchSelectedClassification
            });

        }
        else {
            // ////////////////console.log("No selectedMenuGroup data available from search page");

        }
        if (this.props.location.state && this.props.location.state.selectedClassification) {
            // ////////////////console.log("--------------------");
            // ////////////////console.log("--------------------");
            this.setState({ selectedClsf: this.props.location.state.selectedClassification, selectedClassificationIndex: this.props.location.state.selectedClassificationIndex }, () => {
                // ////////////////console.log("Selected Classification:", this.state.selectedClsf);
                // const indexInGetMenus = this.state.getClassification.findIndex(item => item.classification === this.state.selectedClsf.classification);
                // ////////////////console.log("--------------------");
                // ////////////////console.log(indexInGetMenus)
                // ////////////////console.log("--------------------");
                // this.setState({ selectedClassificationIndex: indexInGetMenus })
            });
        }
        // else {
        //     this.getUpdateMenus()
        // }


        // added by dasprakash on 21-11-2023 jira-1741
        if (outletDetails.showOnlySelectedMenuGroupItems === false) {
            //////console.log("running in did mount")
            window.addEventListener('scroll', this.handleScroll);
        }
        this.setState({ lastIndex: this.state.getMenus.length - 1 }, () => {
            // ////////////////console.log(this.state.lastIndex); // This will log the updated value
        });
        // added for side bar by jeyakumar sir on 22-08-2023 jira-1302
        const script = document.createElement("script");
        script.src = "/js/theme.js"; // it is not calling mentioned for to avoid null value
        script.async = true;
        script.onload = () => this.loadwindow();
        document.body.appendChild(script);
        this.setState({ selectedMenuGroupId: -1 });
        // const getMenus = this.state.getMenus;
        // getMenus.forEach(menu => {
        //     ////////////////console.log(menu.description);
        // });
        // ////////////////console.log(this.state)
        window.scrollTo(0, 0);
        //  ////////////////console.log(getModifiers);
        //     ////////////////console.log(sessionStorage.getItem("cartList"));
        if (guestLoggedIn === "true") {
            this.setState({ loggedIn: true });
        }

        // if (sessionStorage.getItem("cartList") != null) {
        //     cartList = JSON.parse(sessionStorage.getItem("cartList"));
        //     //////////////////console.log(cartList);
        //     this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
        //     totalItems = cartList.totalItems;
        //     totalAmount = cartList.totalAmount;
        //     taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        //     chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
        //     // group by item
        //     Array.prototype.forEach.call(cartList.itemList, itm => {
        //         let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);
        //         document.getElementById("" + itm.id + "").innerHTML = (totlaQty + itm.qty);
        //         let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
        //         this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
        //     });

        // }
        // else {
        //     cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
        //     this.setState({ cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] } });
        //     this.setState({ totalItems: 0, totalAmount: 0 });
        //     totalAmount = 0;
        //     totalItems = 0;
        //     taxList = [];
        //     chargeList = [];
        // }



        // ////////////////console.log(getTaxes);
        // ////////////////console.log(getCharges);


        // if (this.props.location.state !== undefined) {
        //     var stickyContainer = document.getElementById("" + this.props.location.state.focusGroup + "");
        //     if (stickyContainer != null) {
        //         // ////////////////console.log(this.props.location.state.focusGroup);
        //         window.scrollTo({
        //             top: stickyContainer.offsetTop,
        //             left: 0,
        //             behavior: 'smooth'
        //         });
        //     }
        // }

        // check Guest Order list
        // ////////////////console.log(isorderTracking);
        if (outletDetails.enableOrderTracking) {
            if (isorderTracking === "false")
                this.getGuestProcessOrders();
            else
                this.setState({ ordertrack: true });
        }
        if (serviceType === "FINE-DINE") {
            if (guestLoggedIn === "true") {   // Added by vijay 
                if (outletDetails.enableGuestAppOrders === true) {
                    this.getGuestDashboardDetails();
                    grouppinstyle = {
                        marginBottom: "40px",
                        marginTop: "256px"
                    }
                    this.handleCheckGuestTableStatus(mobileNo, tableNo);
                }

            }
            else {
                grouppinstyle = {
                    marginBottom: "40px",
                    marginTop: "214px"
                }
            }
            this.setState({ stylegroupPin: grouppinstyle });
        }
        else {
            if (guestLoggedIn === "true") {
                grouppinstyle = {
                    marginBottom: "40px",
                    marginTop: "214px"
                }
                this.setState({ stylegroupPin: grouppinstyle });
            }
        }

        // update outlet switch

        this.setState({ showOnlySelectedMenuGroupItems: outletDetails.showOnlySelectedMenuGroupItems });


        // Condition Added by vijay on 20-12-2023 Jira id-2023 -start
        // menugroup & clf filter div Position alignment
        if (!outletDetails.enableVegOnly && !outletDetails.enableSearch && !outletDetails.enableRefresh) {
            let setStyle = { top: "70px" }
            this.setState({ styleMoveMenuCLFFilterDivTop: setStyle });
            setStyle = { display: "none" }
            this.setState({ styleItemFilterDiv: setStyle });
        }

        //Only Veg
        if (outletDetails.enableVegOnly && !outletDetails.enableSearch && !outletDetails.enableRefresh) {
            let setStyle = { display: "block", marginTop: "16px", marginLeft: "5px" }
            this.setState({ styleItemFilterVegDiv: setStyle });
            setStyle = { display: "none" }
            this.setState({ styleItemFilterRefreshDiv: setStyle });
            this.setState({ styleItemFilterSearchDiv: setStyle });

            setStyle = { justifyContent: "left", paddingBottom: "15px" }
            this.setState({ styleItemFilterDiv: setStyle });
        }

        //Only Search
        if (!outletDetails.enableVegOnly && outletDetails.enableSearch && !outletDetails.enableRefresh) {
            let setStyle = { display: "none", marginTop: "16px", marginLeft: "-3px" }
            this.setState({ styleItemFilterVegDiv: setStyle });
            setStyle = { display: "none" }
            this.setState({ styleItemFilterRefreshDiv: setStyle });
            setStyle = { display: "flex", marginLeft: "-4px" }
            this.setState({ styleItemFilterSearchDiv: setStyle });

            setStyle = { justifyContent: "left" }
            this.setState({ styleItemFilterDiv: setStyle });
        }

        //Only Refersh
        if (!outletDetails.enableVegOnly && !outletDetails.enableSearch && outletDetails.enableRefresh) {
            let setStyle = { display: "none", marginTop: "16px" }
            this.setState({ styleItemFilterVegDiv: setStyle });

            setStyle = { display: "none" }
            this.setState({ styleItemFilterSearchDiv: setStyle });

            setStyle = { display: "flex", paddingLeft: "5px" }
            this.setState({ styleItemFilterRefreshDiv: setStyle });

            setStyle = { justifyContent: "left", paddingBottom: "23px" }
            this.setState({ styleItemFilterDiv: setStyle });


            //   let setStyleclassification = {top:"102px"}          
            //   this.setState({styleMoveMenuCLFFilterDivTop : setStyleclassification});

        }

        //search, Refersh
        if (!outletDetails.enableVegOnly && outletDetails.enableSearch && outletDetails.enableRefresh) {
            let setStyle = { display: "none", marginTop: "16px" }
            this.setState({ styleItemFilterVegDiv: setStyle });

            setStyle = { paddingLeft: "15px" }
            this.setState({ styleItemFilterRefreshDiv: setStyle });

            setStyle = { display: "flex", marginLeft: "-4px" }
            this.setState({ styleItemFilterSearchDiv: setStyle });

            setStyle = { justifyContent: "left" }
            this.setState({ styleItemFilterDiv: setStyle });
        }

        //search,VegOnly
        if (outletDetails.enableVegOnly && outletDetails.enableSearch && !outletDetails.enableRefresh) {
            let setStyle = { display: "flex", marginTop: "16px", marginLeft: "5px" }
            this.setState({ styleItemFilterVegDiv: setStyle });

            //    setStyle = {display:"none"}              
            //    this.setState({styleItemFilterSearchDiv : setStyle});  

            setStyle = { display: "none" }
            this.setState({ styleItemFilterRefreshDiv: setStyle });

            setStyle = { justifyContent: "left" }
            this.setState({ styleItemFilterDiv: setStyle });
        }

        // VegOnly, Refersh
        if (outletDetails.enableVegOnly && !outletDetails.enableSearch && outletDetails.enableRefresh) {
            let setStyle = { display: "flex", marginTop: "16px", marginLeft: "5px" }
            this.setState({ styleItemFilterVegDiv: setStyle });

            setStyle = { display: "none" }
            this.setState({ styleItemFilterSearchDiv: setStyle });

            setStyle = { paddingLeft: "20px" }
            this.setState({ styleItemFilterRefreshDiv: setStyle });

            setStyle = { justifyContent: "left", paddingBottom: "14px" }
            this.setState({ styleItemFilterDiv: setStyle });
        }

        //Added by prem LDT-2746
        this.setState({ isExpanded: !outletDetails.menuGroupCollapsibleByDefaultInGuestApp });
        // Added by vijay on 31-01-2025
        const selectedFilters = JSON.parse(sessionStorage.getItem('selectedFilters') || '[]');
       // console.log(selectedFilters.length, 'selectedFilters');
        this.setState({ selectedFiltersCount: selectedFilters.length });

    }


    // filters list like new , bestseller 
    // Added by vijay on 31-01-2025
    itemsfilterslist = () => {
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                this.props.history.push('/home' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        this.props.history.push('/itemfilters' + urlPattern);
    }

    loadwindow() {
        loadcomfn = window.initiate;
        loadcomfn();
    }

    // qtyEntryMode() {
    //     if (sessionStorage.getItem("cartList") != null) {
    //         cartList = JSON.parse(sessionStorage.getItem("cartList"));
    //         ////////////////console.log("cartList");
    //         ////////////////console.log(cartList);
    //         this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
    //         totalItems = cartList.totalItems;
    //         totalAmount = cartList.totalAmount;
    //         taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
    //         chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
    //         // group by item
    //         // ////////////////console.log("itm.id")
    //         Array.prototype.forEach.call(cartList.itemList, itm => {
    //             ////////////////console.log(itm)
    //             let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);
    //             // ////////////////console.log("totlaQty-" + totlaQty)
    //             document.getElementById("" + itm.id + "").innerHTML = (totlaQty + itm.qty);
    //             let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
    //             this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
    //         });

    //      }
    //      else {
    //         cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
    //         this.setState({ cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] } });
    //         this.setState({ totalItems: 0, totalAmount: 0 });
    //         totalAmount = 0;
    //         totalItems = 0;
    //         taxList = [];
    //         chargeList = [];
    //     }
    // }

    getCMSDetails() {
        let postobj = {
            organizationId: parseInt(orgnationTd),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.menu, // Default Menu
            PageType: Config.pageType.menu, // Menu           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }
        ////////////////console.log(postobj);

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                //////console.log("cms callimg", response);
                if (response.isSuccessful) {
                    sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
                    // this.setState({ themeId: response.page.themeId, pageTitle: response.page.pageTitle });
                    this.setState({ pageTitle: response.page.pageTitle });
                    this.themeStyle(response);
                }
                else {
                    this.setState({ themeId: 1, pageTitle: '' }); // Default Theme Value
                    // this.showMessage(response.message, "danger");
                    this.setState({ sliderImages: [] });
                }

                // if (sessionStorage.getItem("cartList") != null) {
                //     cartList = JSON.parse(sessionStorage.getItem("cartList"));
                //     ////////////////console.log(cartList);
                //     this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
                //     totalItems = cartList.totalItems;
                //     totalAmount = cartList.totalAmount;
                //     taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
                //     chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
                //     // group by item
                //     //////////////console.log("itm.id")
                //     Array.prototype.forEach.call(cartList.itemList, itm => {
                //         //////////////console.log(itm)
                //         //////////////console.log(cartList.itemList)
                //         let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);
                //         //////////////console.log("totlaQty-" + totlaQty + itm.qty)
                //         document.getElementById("" + itm.id + "").innerHTML = (totlaQty + itm.qty);
                //         let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
                //         //////////////console.log("sout")
                //         //////////////console.log(sout)
                //         this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
                //     });

                // }
                // else {
                //     cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
                //     this.setState({ cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] } });
                //     this.setState({ totalItems: 0, totalAmount: 0 });
                //     totalAmount = 0;
                //     totalItems = 0;
                //     taxList = [];
                //     chargeList = [];
                // }

                this.setState({ loading: true });
                // Classification Filter
                if (getClassification.length > 0) {
                    // Default -> First Classification Select
                    this.setState({ activeClassification: 0 });
                    // ////////////////console.log("default")
                    // commented by dasprakash on 29-08-2023 jira-1302
                    // ////////////////console.log("this.state.searchSelectedMenuGroup")
                    // ////////////////console.log(this.state.searchSelectedMenuGroup)

                    if (this.state.searchSelectedMenuGroup.length === 0) {
                        if (this.state.selectedClassificationIndex === 0) {
                            // ////////////////console.log("this.state.selectedClassificationIndex")
                            // ////////////////console.log(this.state.selectedClassificationIndex)
                            this.handleClassificationClick(0, getClassification[0].classificationName, getClassification[0].classification);
                        } else {
                            this.handleClassificationClick(this.state.selectedClassificationIndex, this.state.selectedClsf.classificationName, this.state.selectedClsf.classification);
                        }
                    } else {
                        this.handleClassificationClick(this.state.searchClassificationIndex, this.state.searchSelectedClassification[0].classificationName, this.state.searchSelectedClassification[0].classification);
                        // //////console.log(this.state.searchSelectedClassification[0].classificationName);
                        // //////console.log(this.state.searchSelectedClassification.classification)
                    }

                    // added by dasprakash on 29-08-2023 jira-1302                   
                }

                this.setState({ loading: false });

            })
            .catch(err => {
                // ////////////////console.log(err);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });

        // Update Cart Infomation
    }

    themeStyle(response) {
        // ////////////////console.log(response.brands);
        // Slider
        if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
            this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });
        else
            this.setState({ sliderImages: [] });

        if (response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage).length > 0) {
            let img = JSON.stringify(response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage)[0].primaryImageUrl);
            let fontFamily = response.brands.filter(b => b.brandCode === 'p-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'p-f-f')[0].brandValue : "Montserrat";
            let styleBGPattern = {
                background: `url(${img}) no-repeat center center fixed`,
                fontFamily: fontFamily,
                border: "none",
                paddingBottom: "114px",
            }
            this.setState({ styleBackgroundImage: styleBGPattern });
        }

        // Heading
        if (response.brands.length > 0) {
            let bgColor = response.brands.filter(b => b.brandCode === 't-h-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-b-c')[0].brandValue : "#59324c";
            let fontColor = response.brands.filter(b => b.brandCode === 't-h-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-c')[0].brandValue : "#fff";
            let fontSize = response.brands.filter(b => b.brandCode === 't-h-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-s')[0].brandValue : "#18px";
            let fontFamily = response.brands.filter(b => b.brandCode === 't-h-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-f')[0].brandValue : "Montserrat";
            //////////////////console.log(response.brands.filter(b => b.brandCode === 't-h-b-c')[0].brandValue);
            let styleHeading = {
                backgroundColor: bgColor,
                color: fontColor,
                fontFamily: fontFamily,
                height: "110px",
                fontSize: fontSize,
                padding: "10px 14px 8px 40px",
                borderBottom: "1px solid #fff"
            }
            this.setState({ styleHeading: styleHeading });
            // Refresh button
            let styleRefresh = {
                color: fontColor,
            }
            this.setState({ styleRefresh: styleRefresh });

            // SubHeading                
            fontColor = response.brands.filter(b => b.brandCode === 't-s-h-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-c')[0].brandValue : "#f9bd09";
            fontSize = response.brands.filter(b => b.brandCode === 't-s-h-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-s')[0].brandValue : "#12px";
            fontFamily = response.brands.filter(b => b.brandCode === 't-s-h-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-f')[0].brandValue : "Montserrat";

            let styleSubHeading = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }
            this.setState({ styleSubHeading: styleSubHeading });

            // Menu Group
            bgColor = response.brands.filter(b => b.brandCode === 'mg-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-b-c')[0].brandValue : "#59324c";
            fontColor = response.brands.filter(b => b.brandCode === 'mg-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-c')[0].brandValue : "#fff";
            fontSize = response.brands.filter(b => b.brandCode === 'mg-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-s')[0].brandValue : "#17px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mg-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-f')[0].brandValue : "Montserrat";

            let styleMenuGroup = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
            }
            let afterbg = css({
                ':after': [{ borderLeft: "16px solid" + bgColor + "", content: `''`, }]
            });
            let beforebg = css({
                ':before': [{ borderBottom: "9px solid " + bgColor + "", content: `''`, }]
            });

            this.setState({ styleMenuGroup: styleMenuGroup });
            this.setState({ styleMengroupAfter: afterbg });
            this.setState({ styleMengroupBefore: beforebg });


            // Sub Menu Group                
            fontColor = response.brands.filter(b => b.brandCode === 'smg-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-c')[0].brandValue : "#000";
            fontSize = response.brands.filter(b => b.brandCode === 'smg-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-s')[0].brandValue : "#15px";
            fontFamily = response.brands.filter(b => b.brandCode === 'smg-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-f')[0].brandValue : "Montserrat";
            let _styleSubMenuGroup = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }

            this.setState({ styleSubMenuGroup: _styleSubMenuGroup });

            // Menu Item            
            fontColor = response.brands.filter(b => b.brandCode === 'mi-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mi-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'mi-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mi-f-s')[0].brandValue : "#16px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mi-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mi-f-f')[0].brandValue : "Montserrat";
            let fontWeight = response.brands.filter(b => b.brandCode === 'mi-f-w').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mi-f-w')[0].brandValue + '' : '500';
            let styleMenuItem = {
                color: fontColor,
                fontFamily: fontFamily,
                fontWeight: fontWeight,
                fontSize: fontSize
            }

            this.setState({ styleMenuItem: styleMenuItem });

            // Menu Desc            
            fontColor = response.brands.filter(b => b.brandCode === 'md-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'md-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'md-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'md-f-s')[0].brandValue : "#12px";
            fontFamily = response.brands.filter(b => b.brandCode === 'md-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'md-f-f')[0].brandValue : "Montserrat";
            fontWeight = response.brands.filter(b => b.brandCode === 'md-f-w').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'md-f-w')[0].brandValue + '' : '600';
            let styleMenuDesc = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                fontWeight: fontWeight,
                marginTop: "4px"
            }

            this.setState({ styleMenuDesc: styleMenuDesc });

            // Menu Price            
            fontColor = response.brands.filter(b => b.brandCode === 'mp-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mp-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'mp-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mp-f-s')[0].brandValue : "#16px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mp-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mp-f-f')[0].brandValue : "inherit";
            fontWeight = response.brands.filter(b => b.brandCode === 'mp-f-w').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mp-f-w')[0].brandValue + '' : '400';

            let styleMenuPrice = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                fontWeight: fontWeight
            }

            this.setState({ styleMenuPrice: styleMenuPrice });

            // Menu popup
            fontColor = response.brands.filter(b => b.brandCode === 'mpu-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mpu-f-c')[0].brandValue + '' : '#f9f2ea';
            fontSize = response.brands.filter(b => b.brandCode === 'mpu-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-f-s')[0].brandValue : "#16px";
            bgColor = response.brands.filter(b => b.brandCode === 'mpu-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-f-f')[0].brandValue : "Montserrat";

            let stylePopupMenubBtn = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
            }
            this.setState({ stylePopupMenubBtn: stylePopupMenubBtn });

            // Menu popup Text
            fontColor = response.brands.filter(b => b.brandCode === 'mpu-t-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mpu-t-f-c')[0].brandValue + '' : '#59324c';
            fontFamily = response.brands.filter(b => b.brandCode === 'mpu-t-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-t-f-f')[0].brandValue : "Montserrat";

            let stylePopupText = {
                color: fontColor,
                fontFamily: fontFamily
            }
            this.setState({ stylePopupText: stylePopupText });

            // Conte Style
            bgColor = response.brands.filter(b => b.brandCode === 'cont-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-b-c')[0].brandValue : "#fcf9ea";
            fontColor = response.brands.filter(b => b.brandCode === 'cont-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-f-c')[0].brandValue : "#f9f2ea";
            fontSize = response.brands.filter(b => b.brandCode === 'cont-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-f-s')[0].brandValue : "#13px";

            let styleContent = {
                backgroundColor: bgColor,
                color: fontColor,
                fontSize: fontSize
            }
            this.setState({ styleContent: styleContent });


        }

        // Content                   
        if (response.page != null) {
            //////////////////console.log(response.page.preview);
            this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
        }
    }

    qtyMode(itmAddClassName, itmEntryClassName, itemId, outOfStock) {
        //console.log('itmAddClassName-' + itmAddClassName)
        // console.log('itmEntryClassName-' + itmEntryClassName)
        // console.log('itemId-' + itemId)
        // console.log('outOfStock-' + outOfStock)
        let itmAddcls = document.querySelector("." + itmAddClassName + "");
        let itmEntrycls = document.querySelector("." + itmEntryClassName + "");
        let itmDivId = document.getElementById("" + itemId + "");

        let itmqTy = 0;
        if (itmDivId !== null)
            itmqTy = document.getElementById("" + itemId + "").innerHTML === null ? 0 : JSON.parse(document.getElementById("" + itemId + "").innerHTML);

        ////////////////console.log("itmqTy");
        ////////////////console.log(itmqTy);
        // ////////////////console.log(itmAddClassName);
        // ////////////////console.log(itmEntryClassName);
        // ////////////////console.log(itmEntrycls);
        // ////////////////console.log(itmAddcls);
        // ////////////////console.log(itmDivId);
        // ////////////////console.log(itemId);

        if (itmqTy > 0) {
            if (itmEntrycls !== null) itmEntrycls.classList.remove('qty-hide');
            if (itmAddcls !== null) itmAddcls.classList.add('qty-hide');
        }
        else {
            if (itmEntrycls !== null) itmEntrycls.classList.add('qty-hide');
            if (itmAddcls !== null) itmAddcls.classList.remove('qty-hide');
        }

        // out of stock       
        if (outOfStock === "true") {
            if (itmEntrycls !== null) itmEntrycls.classList.add('qty-hide');
            if (itmAddcls !== null) itmAddcls.classList.add('qty-hide');
        }


    }

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/home' + urlPattern);
    }

    goMyOrders() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/myorders' + urlPattern);
            }, Config.minMsgTimeout);
        }
        else {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 8); // My ORders
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
    }

    gotoLogin() {
        this.setState({ loading: true });
        sessionStorage.setItem("login-page-session", 2); //Items
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/login' + urlPattern);
        }, Config.minMsgTimeout);
        return false;
    }

    filterMenus(menuList, recommendedList, clfFilterItems = null) {
        //let filterItems = clfFilterItems === null ? menuList : clfFilterItems;

        let filterItems = menuList;
        let clfitems = clfFilterItems;
        let recList = recommendedList;

        let filterGroups = [];


        //Menu Group Filter
        let _uniquesGroups = [];

        var _taxItmFound = {};
        for (var md = 0, lenc = clfitems.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(clfitems[md].menuGroupId);
            if (_taxItmFound[stringified]) { continue; }
            _uniquesGroups.push({ groupId: clfitems[md].menuGroupId });
            _taxItmFound[stringified] = true;
        };
        // ////////////////console.log(_uniquesGroups);

        Array.prototype.forEach.call(getMenus, grp => {
            // //console.log("grp,",grp)
            let grplst = [];
            let subgrplst = []
            let itemCount = clfitems.filter(itm => itm.menuGroupId === grp.menuGroupId).length;
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    grplst = _uniquesGroups.filter(x => x.groupId === sgp.menuGroupId);
                    if (grplst.length > 0) {
                        itemCount += clfitems.filter(itm => itm.menuGroupId === sgp.menuGroupId).length;
                        subgrplst.push({ classification: sgp.classification, description: sgp.description, displayOrder: sgp.displayOrder, hasSubMenuGroup: sgp.hasSubMenuGroup, hsnsac: sgp.hsnsac, isActive: sgp.isActive, menuGroupId: sgp.menuGroupId, menuGroupName: sgp.menuGroupName, subMenuGroup: sgp.subMenuGroup, noOfItems: itemCount, imageUrl: sgp.imageUrl });
                    }
                    // third Level menus 
                    Array.prototype.forEach.call(sgp.subMenuGroup, ssgp => {
                        grplst = _uniquesGroups.filter(x => x.groupId === ssgp.menuGroupId);
                        if (grplst.length > 0) {
                            itemCount += clfitems.filter(itm => itm.menuGroupId === ssgp.menuGroupId).length;
                            subgrplst.push({ classification: ssgp.classification, description: ssgp.description, displayOrder: ssgp.displayOrder, hasSubMenuGroup: ssgp.hasSubMenuGroup, hsnsac: ssgp.hsnsac, isActive: ssgp.isActive, menuGroupId: ssgp.menuGroupId, menuGroupName: ssgp.menuGroupName, subMenuGroup: ssgp.subMenuGroup, noOfItems: itemCount });
                        }
                    });

                });
                // ////////////////console.log(subgrplst);
                if (subgrplst.length > 0) {
                    // //console.log("|going inside without sub groups")
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: subgrplst, noOfItems: itemCount, imageUrl: grp.imageUrl });
                }
            }
            else {
                // //console.log(_uniquesGroups)
                grplst = _uniquesGroups.filter(x => x.groupId === grp.menuGroupId);
                // //console.log("grplst",grplst)

                if (grplst.length > 0) {
                    // //console.log("|going inside")

                    // added image to menugroup on 1-09-2023 by dasprakash jira-1302
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: [], noOfItems: itemCount, imageUrl: grp.imageUrl });

                }
            }
        });
        // console.log("filterGroups", filterGroups);
        this.setState({ fliteredMenuGroup: filterGroups })



        // ////////////////console.log(recommendedList);


        // this.setState({ getItems: clfFilterItems === null ? filterItems : menuList });
        this.setState({ getItems: filterItems });

        this.setState({ recommendedList: recList });
        this.setState({ getMenus: filterGroups });
        //console.log("dasprakashg", this.state.getMenus);
        this.setState({ previousMenu: this.state.getMenus[0] });
        this.setState({ nextMenu: this.state.getMenus[0] });
        // ////////////////console.log("def click")
        // ////////////////console.log(this.state.recommendedList)
        this.setState({ paginationRecommendedList: this.state.recommendedList })

        // fillCart Details
        setTimeout(() => {
            this.getCartDetails();
        }, Config.minMsgTimeout);
    }

    handleSwitchChange = nr => (event) => {
        const isChecked = event.target.checked; // Get the current checked state of the checkbox

        // Reset both flags first
        isCheckVeg = false;
        isCheckNonVeg = false;

        // Set the appropriate flag based on which option was clicked
        if (nr === 1) { // Veg option clicked
            isCheckVeg = isChecked;
            this.setState({ switch1: isChecked, switch2: false });
        } else if (nr === 2) { // Non-veg option clicked
            isCheckNonVeg = isChecked;
            this.setState({ switch1: false, switch2: isChecked });
        }
        this.menuGroupInitialScroll()

        // if veg or non veg
        if (isChecked) {
            if (isCheckVeg === true) {
                // this.setState( showOnlyNonVeg: isCheckNonVeg);
                if (outletDetails.showOnlySelectedMenuGroupItems) {
                    getGlobalItems = getItems;
                    // getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) && obj.isRecommended === false });
                    getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) });

                    if (this.state.searchText !== "")
                        getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) && obj.isRecommended === false && (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });
                    ////console.log(getGlobalItems)

                    let clfList = this.getFilterClassification(getGlobalItems);
                    ////console.log(clfList)
                    this.setState({ getClassification: clfList }, () => {
                        ////////////console.log("this.state.getClassification");
                    });

                    if (clfList.length > 0) {
                        ////////////console.log("clfList.length > 0")
                        ////////////console.log(clfList)
                        // added by dasprakash on 30-09-2023 jira-1302
                        clfList.sort((a, b) => a.classification - b.classification);

                        this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)

                    }
                    else this.filterMenus([], [], []);

                }

                else {
                    let Isfocus = false;
                    let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });
                    let filterItems = clffilterItems.filter(x => x.foodType === 1);

                    if (this.state.searchText !== "")
                        filterItems = filterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });

                    let recList = filterItems.filter(itm => itm.isRecommended);
                    // Added by vijay on 06-02-2024 Jira id -2075 - Selected Menugroup not showing on top select/deselect  veg only option. -start
                    recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })
                    this.filterMenus(filterItems, recList, filterItems);


                    this.setState(
                        {
                            activeMenuGroup: recList.length > 0 && this.state.searchSelectedMenuGroup.length === 0 ? -1 : 0
                        },

                        () => {
                            console.log('recList', recList);
                        }
                    );

                    if (this.state.searchSelectedMenuGroup.length !== 0) {

                        this.handleMenugroupsClick(this.state.searchselectedMenugroupIndex, recList, this.state.searchSelectedMenuGroup.menuGroupId);
                    }

                    if (Isfocus === true) {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    } else {

                    }

                    setTimeout(() => {
                        this.setState({
                            loading: false
                        });
                    }, Config.minMsgTimeout);
                    // Added by vijay on 06-02-2024 Jira id -2075 - Selected Menugroup not showing on top select/deselect  veg only option. -start
                }


            }
            // Added by vijay on 23-02-2023 due to veg and non veg option 
            else if (isCheckNonVeg === true) {
                if (outletDetails.showOnlySelectedMenuGroupItems) {
                    getGlobalItems = getItems;
                    getGlobalItems = getItems.filter(obj => { return (obj.foodType === 2) });
                    if (this.state.searchText !== "")
                        getGlobalItems = getItems.filter(obj => { return (obj.foodType === 2) && obj.isRecommended === false && (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });
                    let clfList = this.getFilterClassification(getGlobalItems);

                    this.setState({ getClassification: clfList }, () => {
                    });

                    if (clfList.length > 0) {
                        clfList.sort((a, b) => a.classification - b.classification);
                        this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
                    }
                    else this.filterMenus([], [], []);
                }

                else {
                    let Isfocus = false;
                    let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });
                    let filterItems = clffilterItems.filter(x => x.foodType === 2);
                    if (this.state.searchText !== "")
                        filterItems = filterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });
                    let recList = filterItems.filter(itm => itm.isRecommended);
                    recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })
                    this.filterMenus(filterItems, recList, filterItems);
                    this.setState(
                        {
                            activeMenuGroup: recList.length > 0 && this.state.searchSelectedMenuGroup.length === 0 ? -1 : 0
                        }, () => { console.log('recList', recList); }
                    );
                    if (this.state.searchSelectedMenuGroup.length !== 0) {

                        this.handleMenugroupsClick(this.state.searchselectedMenugroupIndex, recList, this.state.searchSelectedMenuGroup.menuGroupId);
                    }
                    if (Isfocus === true) {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    } else { }
                    setTimeout(() => {
                        this.setState({
                            loading: false
                        });
                    }, Config.minMsgTimeout);
                }

            }
        }

        //both items list
        else {
            //  console.log("else in veg only")
            if (outletDetails.showOnlySelectedMenuGroupItems) {
                getGlobalItems = getItems;

                if (this.state.searchText !== "")
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });


                let clfList = this.getFilterClassification(getGlobalItems);
                this.setState({ getClassification: clfList });

                if (clfList.length > 0) {
                    this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
                }
                else this.filterMenus([], [], []);
            }

            else {
                let Isfocus = false;
                let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });
                if (this.state.searchText !== "")
                    clffilterItems = clffilterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });

                let recList = clffilterItems.filter(itm => itm.isRecommended);
                // Added by vijay on 06-02-2024 Jira id -2075 - Selected Menugroup not showing on top select/deselect  veg only option. -start
                recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })

                this.filterMenus(clffilterItems, recList, clffilterItems);
                this.setState(
                    {
                        activeMenuGroup: recList.length > 0 && this.state.searchSelectedMenuGroup.length === 0 ? -1 : 0
                    },

                    () => {
                        //console.log('recList', recList);
                    }
                );

                if (this.state.searchSelectedMenuGroup.length !== 0) {

                    this.handleMenugroupsClick(this.state.searchselectedMenugroupIndex, recList, this.state.searchSelectedMenuGroup.menuGroupId);
                }

                if (Isfocus === true) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                } else {

                }

                setTimeout(() => {
                    this.setState({
                        loading: false
                    });
                }, Config.minMsgTimeout);
                //  Added by vijay on 06-02-2024 Jira id -2075 - Selected Menugroup not showing on top select/deselect  veg only option. -End           
            }
            // this.setState({ getItems: getItems });
            // this.setState({ recommendedList: recommendedList });
            // this.setState({ getMenus: getMenus });
        }

        window.scrollTo(0, 0);

        // this.setState({
        //     [switchNumber]: !this.state[switchNumber]
        // });

    };

    // navigate to itemsearch page
    itemsearch = () => {
        /// user Session Validation        
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                this.props.history.push('/home' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        this.props.history.push('/itemsearch' + urlPattern)
    }


    // Menu Search
    handlechange = event => {
        const val = event.target.value;

        this.setState({ searchText: val });

        if (val === "") {

            if (outletDetails.showOnlySelectedMenuGroupItems) {

                getGlobalItems = getItems;

                if (isCheckVeg) {
                    getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) });
                }
                else if (isCheckNonVeg) {
                    getGlobalItems = getItems.filter(obj => { return (obj.foodType === 2) });
                }


                let clfList = this.getFilterClassification(getGlobalItems);
                //////////////////console.log(clfList);
                this.setState({ getClassification: clfList });

                if (clfList.length > 0) {
                    this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
                }
                else this.filterMenus([], [], []);
            }
            else {

                let clfList = this.getFilterClassification(getItems);
                this.setState({ getClassification: clfList });

                getGlobalItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });

                if (isCheckVeg) {
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 1) });

                }

                else if (isCheckNonVeg) {
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 2) });
                }


                let recList = getGlobalItems.filter(itm => itm.isRecommended);

                this.filterMenus(getGlobalItems, recList, getGlobalItems);
            }
        }
        else {
            if (outletDetails.showOnlySelectedMenuGroupItems) {

                // ////////////////console.log(val);

                // let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });

                getGlobalItems = getItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(val.toLowerCase()) >= 0) && obj.isRecommended === false });

                if (isCheckVeg) {
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 1) });
                }
                else if (isCheckNonVeg) {
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 2) });
                }

                //  ////////////////console.log(getGlobalItems);


                let clfList = this.getFilterClassification(getGlobalItems);
                this.setState({ getClassification: clfList });
                if (clfList.length > 0) {
                    this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
                }
                else this.filterMenus([], [], []);
            }
            else {
                // ////////////////console.log(val);

                let clfList = this.getFilterClassification(getItems);
                this.setState({ getClassification: clfList });

                let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });



                getGlobalItems = clffilterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(val.toLowerCase()) >= 0) });

                if (isCheckVeg) {
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 1) });
                }


                else if (isCheckNonVeg) {
                    getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 2) });
                }
                // ////////////////console.log(getGlobalItems);

                let recList = getGlobalItems.filter(itm => itm.isRecommended);
                //   ////////////////console.log(recList);
                this.filterMenus(getGlobalItems, recList, getGlobalItems);
            }

        }
        window.scrollTo(0, 0);
    };

    getFilterClassification(itemList) {

        ////////////console.log(itemList)
        let _uniquesCLF = [];

        var _clfFound = {};
        for (var md = 0, lenc = itemList.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(itemList[md].classification);
            if (_clfFound[stringified]) { continue; }
            _uniquesCLF.push({ classification: itemList[md].classification });
            _clfFound[stringified] = true;
        };

        ////////////////console.log("_uniquesCLF");
        ////////////////console.log(_uniquesCLF);

        let clfList = [];
        Array.prototype.forEach.call(_uniquesCLF, clf => {
            let list = getClassification.filter(c => c.classification === clf.classification);
            ////////////console.log("list");
            ////////////console.log(list);
            // commented by dasprakash on 30-09-2023 jira-1302
            // if (list.length > 0) clfList.push({ classification: list[0].classification, classificationName: list[0].classificationName, displayOrder: list[0].displayOrder, imageUrl: list[0].imageUrl });
            // added by dasprakash on 30-09-2023 jira-1302
            //console.log("clfList")
            if (list.length > 0) clfList.push({ classification: list[0].classification, classificationName: list[0].classificationName, displayOrder: list[0].displayOrder, imageUrl: list[0].imageUrl, recommendedItemImageUrl: list[0].recommendedItemImageUrl });
        });

        ////////////console.log("clfList")
        ////////////console.log(clfList)
        clfList = [...clfList].sort((a, b) => a.displayOrder - b.displayOrder);

        return clfList;
    }

    goCart() {

        /// user Session Validation        
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                this.props.history.push('/home' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }


        window.scrollTo(0, 0);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern, {
                getMenus: this.props.location.state.getMenus,
                getItems: this.props.location.state.getItems,
                getModifiers: this.props.location.state.getModifiers,
                getTaxes: this.props.location.state.getTaxes,
                getCharges: this.props.location.state.getCharges
            });
        }, Config.minMsgTimeout);
    }

    handleMenuGroupClick(index, mnuid) {
        this.setState({
            activeMenuGroup: index,
        });

        if (!outletDetails.showOnlySelectedMenuGroupItems) {
            //  window.location.hash = '#' + mnuid;           
            var stickyContainer = document.getElementById("" + mnuid + "");
            //  ////////////////console.log(stickyContainer.offsetTop + 170);
            // window.scrollTo(0, stickyContainer.offsetTop);
            window.scrollTo({
                top: stickyContainer.offsetTop - 185,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    handleAddQty(itmId, val, rate, itmName, itemId, groupId, foodType, modifierGroupIds, taxId, chargeId) {
        this.handleQtyIncDec(itmId, val, rate, itmName, itemId, groupId, foodType, modifierGroupIds, taxId, chargeId);
    }

    handleQtyIncDec(itmId, val, rate, itmName, itemId, groupId, foodType, modifierGroupIds, taxId, chargeId) {
        //  console.log("itemId")
        // console.log(itmId)
        //   console.log("groupId")
        //  console.log(groupId)
        //console.log(itmName)
        if (modifierGroupIds.length === 0) { // Find the modifier
            // ////////////////console.log("starting")
            let qty = document.getElementById("" + itmId + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itmId + "").innerHTML);
            // ////////////////console.log(qty)

            if (qty === 0 && val === 0) {
                //   ////////////////console.log('invalid');
                return false;
            }
            let _qtyVal = val === 1 ? qty += 1 : qty -= 1;

            if (qty >= 0) {
                this.setState({ qty: _qtyVal });
                document.getElementById("" + itmId + "").innerHTML = _qtyVal;
                totalItems = val === 1 ? totalItems += 1 : totalItems -= 1;
                totalAmount = val === 1 ? totalAmount += rate : totalAmount -= rate;
            }
            ////////////////console.log("itmId")
            // console.log(itmId)
            this.qtyMode('item-add-' + itmId, 'item-entry-' + itmId, itmId);


            //let _amtVal = _qtyVal * rate;
            // totalAmount += _amtVal;        

            this.setState({ totalItems: totalItems });
            this.setState({ totalAmount: totalAmount });

            cartList.totalItems = totalItems;
            cartList.totalAmount = totalAmount;
            let uid = uuid();
            taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
            chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));

            let index = cartList.itemList.findIndex(x => x.id === "" + itmId + "");
            if (index < 0) {
                this.itemTaxCalc(taxId, rate, qty, itemId, uid);
                this.itemChargeCalc(chargeId, rate, qty, itemId, uid);
                cartList.itemList.push({ id: "" + itmId + "", itemId: itemId, groupId: groupId, foodType: foodType, qty: _qtyVal, rate: rate, itemName: itmName, isModifier: 0, uid: uid, taxId: taxId, chargeIds: chargeId });
            }
            else {
                if (_qtyVal > 0) {
                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    cartList.itemList[index].qty = _qtyVal;
                    this.itemTaxCalc(taxId, rate, _qtyVal, itemId, cartList.itemList[index].uid);

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });
                    // ////////////////console.log(chargeList);
                    this.itemChargeCalc(chargeId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                }
                else {
                    //tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });
                    cartList.itemList.splice(index, 1);
                }
            }


            sessionStorage.setItem("cartList", JSON.stringify(cartList));
            sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
            sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
            // ////////////////console.log(cartList);
            // ////////////////console.log(taxList);
            // ////////////////console.log(chargeList);
        }
        else {

            let qty = document.getElementById("" + itmId + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itmId + "").innerHTML);
            // ////////////////console.log(qty)
            if (val === 1) {

                this.setState({
                    modifierFoodType: foodType,
                    modifierItemName: itmName,
                    modifierItemId: itemId,
                    modifierItemCId: itmId,
                    modifierItemCQty: qty,
                    modifierItemCRate: rate,
                    modifierItemCGroupId: groupId,
                    modifierItemCTaxId: taxId,
                    modifierItemCChargeIds: chargeId,
                    showModal: true
                });
                this.setState({ modifierGroupIds: modifierGroupIds })
                setTimeout(() => {
                    this.handleModifier();
                }, Config.minMsgTimeout);
            }
            else { // validating modifier minus process
                if (qty > 0) {
                    this.showMessage('This item has multiple customizations added. Proceed to cart to remove item.', "danger");
                    this.setState({ loading: true });
                    setTimeout(() => { this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
                    return false;
                }
            }
            // this.handleModifier();
        }


    };

    itemTaxCalc(taxId, rate, qty, itemId, uid) {
        let cList = getTaxes.filter(x => x.taxId === taxId);
        let taxObj = [];
        let taxAmt = 0;
        let itemValue = rate * qty;
        let bInsertTax = false;
        if (cList.length > 0) {
            Array.prototype.forEach.call(cList[0].structure, tx => {
                bInsertTax = false;
                taxAmt = 0;
                //Applicable on = 2 Discount Value  && applicableon = 1 direct value
                if (tx.applicableOn === 3) { // Tax on Tax     
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.length > 0) {
                        if (tx.taxType === 1) // (%)                                                
                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                        else if (tx.taxType === 2)  // Amount
                            taxAmt = tx.taxValue * qty;

                        bInsertTax = true;

                    }
                }
                else {
                    if (tx.taxType === 1) // (%)             
                        taxAmt = itemValue * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue * qty; // 

                    bInsertTax = true;
                }

                if (bInsertTax === true) {
                    taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                    taxList.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                }
            });
        }
    }

    itemChargeCalc(chargeId, rate, qty, itemId, uid) {
        //  ////////////////console.log(chargeId);
        chargeId = chargeId === null ? [] : chargeId;
        if (chargeId.length > 0) {
            Array.prototype.forEach.call(chargeId, cId => {
                // filter based on Item wise & Item Qty wise => apply category
                let chargelist = getCharges.filter(x => x.chargeId === cId && x.applyTo === 1);
                let taxlist = [];
                let taxObj = [];
                let taxAmt = 0;

                let itemValue = rate * qty;
                //  ////////////////console.log(chargelist);
                let chargeAmt = 0;
                if (chargelist.length > 0) {
                    Array.prototype.forEach.call(chargelist, chg => {
                        let rnTaxAmt = 0;
                        if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                            chg.chargeSlab = chg.chargeSlab === null ? [] : chg.chargeSlab;
                            let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                            if (cslab.length > 0) {
                                if (cslab.chargeValueType === 1)
                                    chargeAmt = itemValue * chg.chargeValue * 0.01;
                                else if (cslab.chargeValueType === 2)
                                    chargeAmt = cslab.ChargeValue;
                            }
                        }
                        else if (chg.chargeValueType === 2) // Amount only qty based 
                            chargeAmt = chg.chargeValue * qty; // 
                        //  ////////////////console.log(chargeAmt);
                        // Tax 
                        taxlist = getTaxes.filter(x => x.taxId === chg.taxId);
                        if (taxlist.length > 0) {
                            Array.prototype.forEach.call(taxlist[0].structure, tx => {
                                taxAmt = 0;
                                if (tx.applicableOn === 3) {
                                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                                    if (serialTax.length > 0) {
                                        if (tx.taxType === 1) // (%) 
                                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                                        else if (tx.taxType === 2) // Amount
                                            taxAmt = tx.taxValue;
                                    }
                                }
                                else {

                                    if (tx.taxType === 1) // (%)             
                                        taxAmt = (chargeAmt * tx.taxValue * 0.01);
                                    else if (tx.taxType === 2) // Amount
                                        taxAmt = tx.taxValue; // 
                                }
                                rnTaxAmt += taxAmt;
                                taxObj.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                            })
                            // ////////////////console.log(taxObj);
                        }
                        chargeList.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: rnTaxAmt });

                    });
                    // ////////////////console.log(chargeList);
                }
            });
        }
    }

    handleModifier(_this) {
        if (modifierRef.current) {
            modifierRef.current.handleModifier();
        }
    }

    callBackModifier() {
        if (sessionStorage.getItem("cartList") != null) {
            cartList = JSON.parse(sessionStorage.getItem("cartList"));

            Array.prototype.forEach.call(cartList.itemList, itm => {
                this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id);
            });
            //  ////////////////console.log(cartList);
            this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
            totalItems = cartList.totalItems;
            totalAmount = cartList.totalAmount;
        }

        this.setState({ showModal: true });
        //  ////////////////console.log(this.state.showModal);
    }

    callBackRequest(msg, type) {
        // //////////console.log("from items");
        //   //////////console.log(msg + '-' + type);

        setTimeout(() => {
            if (type === 1)
                this.showMessage(msg, "success");
            else
                this.showMessage(msg, "danger");
        }, Config.minMsgTimeout);
    }

    callBackGroupMembers(msg, type) {

        setTimeout(() => {
            if (type === 1)
                this.showMessage(msg, "success");
            else
                this.showMessage(msg, "danger");
        }, Config.minMsgTimeout);
    }

    handleClearText() {
        if (this.state.searchText !== "") {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ searchText: "" });
                // this.setState({ getItems: getItems });
                // this.setState({ recommendedList: recommendedList });
                // this.setState({ getMenus: getMenus });

                if (getClassification.length > 0) {
                    // Default -> First Classification Select
                    this.setState({ activeClassification: 0 })
                    getGlobalItems = getItems;
                    if (this.state.showOnlyVeg) {
                        getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) });
                    }


                    else if (this.state.showOnlyNonVeg) {
                        getGlobalItems = getItems.filter(obj => { return (obj.foodType === 2) });
                    }



                    let clfList = this.getFilterClassification(getGlobalItems);
                    this.setState({ getClassification: clfList });

                    this.handleClassificationClick(0, getClassification[0].classificationName, getClassification[0].classification)
                }

                this.searchInp.focus();
                this.setState({ loading: false });
                window.scrollTo(0, 0);
            }, 100);
        }
    }

    handleclearfilter(){
        
        sessionStorage.removeItem("item-tag-filter-list");   // Added by vijay on 31-01-2025
        sessionStorage.removeItem("selectedFilters");      // Added by vijay on 31-01-2025
        this.getUpdateMenus();
    }

    getUpdateMenus(callback) {
        sessionStorage.removeItem("item-tag-filter-list");   // Added by vijay on 31-01-2025
        sessionStorage.removeItem("selectedFilters");      // Added by vijay on 31-01-2025
        this.setState({ switch1: false, switch2: false });  // Added by vijay on 31-01-2025
        isCheckNonVeg = false; // Added by vijay on 31-01-2025
        isCheckVeg = false;
        this.setState({ selectedFiltersCount: 0 });

        if (serviceType === "FINE-DINE") {
            if (guestLoggedIn === "true") {
                //  this.getGuestDashboardDetails();
                this.handleCheckGuestTableStatus(mobileNo, tableNo);
            }
        }

        if (!isUpdateMenu) {
            isUpdateMenu = true;
            window.scrollTo(0, 0);
            this.setState({ loading: true });
            setTimeout(() => {
                let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
                sessionStorage.getItem('menuFetchDateTime') !== null ? urlParam += '&menuDateTime=' + sessionStorage.getItem('menuFetchDateTime') : urlParam += '';
                fetch(Config.basePOSUrl + 'Getguestappmenus' + urlParam, {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + token,
                        'content-type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(response => {
                        //  console.log("refresh api response");
                        //  console.log(response);
                        if (response.errorCode === 0) {

                            // ////////////////console.log(JSON.stringify(response.response.menuGroups));          
                            if (response.response !== null) {
                                // Filter active Menu Items
                                let activeMenuGroups = [];
                                let activeMenuItems = [];
                                if (response.response.menuGroups.length > 0)
                                    activeMenuGroups = response.response.menuGroups.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
                                        return new Date(a.displayOrder) - new Date(b.displayOrder);
                                    });
                                ////////////////console.log("activeMenuGroups")
                                ////////////////console.log(activeMenuGroups)


                                if (response.response.menuItems.length > 0)
                                    activeMenuItems = response.response.menuItems.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
                                        return new Date(a.displayOrder) - new Date(b.displayOrder);
                                    });


                                sessionStorage.setItem("getMenuGroups", JSON.stringify(activeMenuGroups));
                                sessionStorage.setItem("getmenuItems", JSON.stringify(activeMenuItems));
                                sessionStorage.setItem("getModifierGroups", JSON.stringify(response.response.modifierGroups));
                                sessionStorage.setItem("getTaxes", JSON.stringify(response.response.taxes));
                                sessionStorage.setItem("getCharges", JSON.stringify(response.response.charges));
                                sessionStorage.setItem("menuFetchDateTime", response.response.menuDateTime);
                                getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
                                getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
                                //  console.log("getItems111", getItems)
                                // added by dasprakash on 24-11-2023
                                getGlobalItems = getItems;
                                getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
                                getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
                                getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));
                                recommendedList = getItems.filter(itm => itm.isRecommended);

                                this.setState({
                                    recommendedList: recommendedList,
                                    getMenus: getMenus,
                                    getItems: getItems
                                }, () => {
                                    ////////console.log("recommendedList", recommendedList);
                                    //console.log("getMesdsdsdnus", getMenus);
                                    //console.log("getMenus", this.state.getMenus);
                                    //console.log("getItems", getItems);

                                });

                                this.setState({ searchText: "" });
                                // Update Item Qty Mode

                                // Commended by vijay on 04-03-2024 - If change any item price in menumaster - while refersh time menugroup scroll not loading properly  
                                // if (sessionStorage.getItem("cartList") != null) {
                                //     cartList = JSON.parse(sessionStorage.getItem("cartList"));
                                //     //  console.log("cartList:", cartList);
                                //     // group by item
                                //     Array.prototype.forEach.call(cartList.itemList, itm => {
                                //         //let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);                               
                                //         let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
                                //         this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
                                //     });
                                // }

                                if (getClassification.length > 0 && this.state.searchSelectedMenuGroup.length === 0) {
                                    // Default -> First Classification Select
                                    //console.log("dasuauku")
                                    this.setState({ activeClassification: 0 })

                                    // Added by vijay on 06-01-2024 Jira id -2075
                                    this.handleClassificationClick(0, getClassification[0].classificationName, getClassification[0].classification)
                                }


                                // added by daspraksh on 1-10-2023 jira-1302
                                ////////console.log("refreshing")
                                // window.location.reload()
                            }



                        }
                        else {
                            this.setState({ APIResponseMsg: "Failed to fetch" })
                            this.showMessage(response.message, "danger");
                        }
                        this.setState({ loading: false });
                        // Added by vijay - Due to refersh time menugroupscroll not coming recommended menugroupname -{Its fixed}
                        getClassification = sessionStorage.getItem("getclf") === null ? [] : JSON.parse(sessionStorage.getItem("getclf"));
                        //  console.log(getClassification, 'getClassification');
                        this.setState({ getClassification: getClassification });
                        this.handleClassificationClick(0, getClassification[0].classificationName, getClassification[0].classification)
                        isUpdateMenu = false;


                    })
                    .catch(err => {
                        this.setState({ APIResponseMsg: "Failed to fetch" });
                        this.setState({ loading: false });
                        isUpdateMenu = false;
                        //////////////////console.log(err);
                        // this.showMessage("An error occured", "danger");
                    });
            }, Config.minMsgTimeout);

        }

    }

    goBill() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {

                this.setState({ loading: false });
                this.props.history.push('/mybills' + urlPattern);
            }, Config.minMsgTimeout);
        }
        else {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 7); // PayBill        
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
    }

    // Currently we are not using this function 
    handleGenerateRecommendedListDom() {
        return (
            <div className="content-from" id="focus-mnugrp-recommended" >
                <div className='collapse'>

                    <div className="itm-heading" style={this.state.styleMenuGroup}>{this.state.recommendedTxt}</div>

                    <div className="wrap-list-rc recommended-item" id="item-list">
                        {
                            this.state.recommendedList.map(itm =>
                                <div className="lrc-rec-item" key={"mnu-rec-" + itm.itemId}>
                                    <div className="lrc-content">

                                        <div className="lrc-img">
                                            <img src={(itm.imageUrl !== null && itm.imageUrl !== "") ? imageUrl : itm.imageUrl} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, (itm.imageUrl === null ? imageUrl : itm.imageUrl))} loading="lazy" alt="item" />
                                        </div>
                                        <div className="lrc-desc">
                                            <div className="lrc-title" style={this.state.styleMenuItem}>
                                                {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            <span className="itm-title">{itm.itemName}</span> */}
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>

                                                {/* <div className={"row align-items-start item-name-row" + (itm.outOfStock ? ' tag-soldout-item' : '')} >
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{itm.itemName}</div>
                                                </div>
                                            </div> */}
                                            </div>
                                            {/* <div className="lrc-text">
                                                                {itm.description.toLowerCase()}
                                                            </div> */}
                                            <div className="lrc-button">
                                                <div>
                                                    <div className={"qty-detail item-entry-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide"} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-entry-" + itm.itemId}>
                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div>

                                                    {!itm.outOfStock &&
                                                        <div className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                            <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                        </div>
                                                    }
                                                    {itm.outOfStock &&
                                                        <div className={"rec-qty-detail item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                            <span className="soldout">SOLD OUT</span>
                                                        </div>}

                                                </div>
                                                {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 &&
                                                    <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", fontSize: "13px", width: "116px", textAlign: "center", color: "#7e808c", top: "212px" }}>
                                                        <div className="modifier-notify">customisable</div></div>}
                                                <div className="lrcb-left">
                                                    <span className="lhc price" style={this.state.styleMenuPrice}>₹{itm.mrp}</span>
                                                </div>
                                                <div className="lrcb-right">
                                                </div>
                                                {this.handleRibbonList(itm)}
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="clear clear-bottom" style={{ display: "none" }}></div>
            </div >
        );
    };

    // Currently we are not using this function 
    handleVerticalGenerateRecommendedListDom() {
        return (
            <div className="content-from" id="focus-mnugrp-recommended">
                <div className='collapse'>
                    <div className="itm-heading" style={this.state.styleMenuGroup}>{this.state.recommendedTxt}</div>

                    <div className="wrap-list-rc recommended-item" id="item-list">
                        {
                            this.state.recommendedList.map(itm =>
                                <div className="lrc-rec-1-item" key={"mnu-rec-" + itm.itemId}>
                                    <div className="lrc-content">
                                        <div className="lrc-img">
                                            <img src={itm.imageUrl === null ? imageUrl : itm.imageUrl} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, (itm.imageUrl === null ? imageUrl : itm.imageUrl))} loading="lazy" alt="item" />
                                        </div>
                                        <div className="lrc-desc">
                                            <div className="lrc-title" style={this.state.styleMenuItem}>
                                                {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            <span className="itm-title">{itm.itemName}</span> */}
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>

                                                {/* <div className={"row align-items-start item-name-row" + (itm.outOfStock ? ' tag-soldout-item' : '')} >
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{itm.itemName}</div>
                                                </div>
                                            </div> */}
                                            </div>
                                            {/* <div className="lrc-text">
                                                                {itm.description.toLowerCase()}
                                                            </div> */}
                                            <div className="lrc-button">
                                                <div>
                                                    <div className={"rec-qty-detail item-entry-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide"} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-entry-" + itm.itemId}>
                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>

                                                    </div>

                                                    {!itm.outOfStock && <div className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                        <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                    </div>
                                                    }
                                                    {itm.outOfStock && <div className={"rec-qty-detail item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                        <span className="soldout">SOLD OUT</span>
                                                    </div>}

                                                </div>
                                                {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 && <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", fontSize: "13px", width: "78px", textAlign: "center", color: "#7e808c", top: "314px" }}><div className="modifier-notify">customisable</div></div>}
                                                <div className="lrcb-left">
                                                    <div className="lhc price" style={this.state.styleMenuPrice}>₹{itm.mrp}</div>
                                                </div>
                                                <div className="lrcb-right">


                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="clear clear-bottom" style={{ display: "none" }}></div>
            </div>
        );
    };

    handleRibbonList(item) {
        const ribbonLabels = [];
        
        if (item.mustTryItem) {
            ribbonLabels.push({
                text: "Must Try",
                className: "ribbon-must-try"
            });
        }
        if (item.todaySpecialItem) {
            ribbonLabels.push({
                text: "Today Special",
                className: "ribbon-today"
            });
        }
        if (item.popularItem) {
            ribbonLabels.push({
                text: "Popular",
                className: "ribbon-popular"
            });
        } 
        if (item.chefSpecialItem) {
            ribbonLabels.push({
                text: "Chef Special",
                className: "ribbon-chef"
            });
        }
    
        return ribbonLabels.length > 0 ? (
            <div className="multiple-ribbon-container">
                <div className="multiple-ribbon">
                    {ribbonLabels.map((label, index) => (
                        <span 
                            key={`ribbon-${index}`} 
                            className={`txt ${label.className}`}
                        >
                            {label.text}
                        </span>
                    ))}
                </div>
            </div>
        ) : null;
    }

    handleGenerateRowRecommendedListDom() {
        const { scrollPosition } = this.props;
        return (
            <div className="content-from" id="focus-mnugrp-recommended">
                {/* recommended count added by dasprakash 17-08-2023 jira-1302 */}
                <div className="itm-heading" style={this.state.styleMenuGroup}>{this.state.recommendedTxt}
                    ({this.state.recommendedList.length})</div>

                <div className="wrap-list-rc recommended-item" id="item-list">
                    {
                        this.state.recommendedList.map(itm =>

                            <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"mnu-rec-" + itm.itemId} >
                                {/* {itm.bestSellerItem === false && <span className="ribbon"><span className="txt">BEST SELLER</span></span>} */}

                                <div className="lrc-content">
                                    {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                    {itm.newItem && itm.bestSellerItem ? (
                                        <>
                                            <span className="ribbon">
                                            <span className="txt-new">NEW</span>
                                            <span className="txt-bestseller">Bestseller</span>

                                            </span>
                                        </>
                                    ) : itm.newItem ? ( 
                                        <span className="ribbon">
                                            <span className="txt-new">NEW</span> </span>
                                    ) : itm.bestSellerItem ? ( 
                                        <span className="ribbon">
                                            <span className="txt-bestseller">Bestseller</span> </span>
                                    ) : null}
                                    <div className="lrc-desc">
                                        {/* {this.entryMode()} */}
                                        <div style={{ padding: "1px" }} className={"qty-detail item-entry-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-entry-" + itm.itemId}>
                                            <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                            <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                            <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                        </div>

                                        {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                            // first rec page 
                                            <div className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                            </div>
                                        }

                                        {/* level2 change */}

                                        {/* commented by dasprakash on 4-08-2023 jira-1302 */}
                                        {/* {outletDetails.enableGuestAppOrders === false &&
                                            <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                            </div>
                                        } */}

                                        {outletDetails.enableGuestAppOrders === false && (
                                            itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                // If both conditions are true
                                                !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                    <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                        Customisable
                                                    </span>
                                                </div>
                                            ) : (
                                                // If the first condition is true but the second one is false
                                                // Condition Added by vijay on 21-03-2024
                                                // <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                    </div>
                                                ) : (
                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                    </div>
                                                )
                                            )
                                        )}

                                        {/*  */}

                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                            <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                <span className="soldout">SOLD OUT</span>
                                            </div>}
                                        {/* recomended item customisable margin top change by windowsinnerwidth  changed  by dasprakash on 18-08-2023 jira-1302 */}
                                        {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true && <div className={itm.imageUrl !== null && itm.imageUrl !== "" ? 'desktop-modifier-no-img-items' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: itm.imageUrl !== null && itm.imageUrl !== "" ? "102px" : (window.innerWidth >= 420 ? "18px" : "18px"), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">Customisable</div></div>}
                                        {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                            <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-rec-", "")}>
                                                {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}
                                                {/* added function to blure the out of stock img in recomended page by dasprakash on 18-08-2023 jira-1302 */}
                                                {itm.outOfStock ? (
                                                    <LazyLoadImage
                                                        alt="item"
                                                        className="item-imgs"
                                                        effect="blur"
                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                        scrollPosition={scrollPosition}
                                                        src={itm.imageUrl}
                                                        delayTime={10000}
                                                        threshold={100}

                                                    />
                                                ) : (
                                                    <LazyLoadImage
                                                        alt="item"
                                                        className="item-img"
                                                        effect="blur"
                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                        scrollPosition={scrollPosition}
                                                        src={itm.imageUrl}
                                                        delayTime={10000}
                                                        threshold={100}
                                                    />
                                                )}


                                            </div>
                                        }
                                        {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                        {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                            <div className='tag-soldout-txt'>
                                                <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                    marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                    marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                }}>
                                                    SOLD OUT
                                                </span>
                                            </div>
                                        )}

                                        <div className="lrc-title" style={this.state.styleMenuItem}>
                                            {/* <div className={"row align-items-start item-name-row" + (itm.outOfStock ? ' tag-soldout-item' : '')} >
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{itm.itemName}</div>
                                                </div>

                                            </div> */}


                                            {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            <span className="itm-title">{itm.itemName}</span>
                                           */}
                                            <app-item-type>
                                                <div className="image-type">
                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                </div>
                                            </app-item-type>
                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>

                                            {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <span className="soldout-txt">SOLD OUT</span>} */}
                                        </div>


                                        {/* recomended menu items price and des change position by dasprakash on 17-08-2023 jira-1302 */}
                                        <div className="lrc-button">
                                            <div className="lrcb-left">
                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                    <Currency />
                                                    {itm.mrp}
                                                </span>
                                            </div>
                                            {/* <div className="lrcb-right">
                <button className="itm-add" type="button" data-toggle="modal" data-target="#modifier-modal" data-Qty><span>ADD</span></button>
            // </div> */}
                                            {/* handleSetPopupImage function for image popup by dasprakash on 22-08-2023 jira-1302 */}

                                            <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-rec-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                {/* {itm.description.toLowerCase()} */}
                                                {/* // Added by vijay on 10-02-2024 -Jira id -2075 -More control div comes to single line only */}

                                                <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                    { /* Media Query for Desktop Mode */}
                                                    <span className="desktop-description">
                                                        {itm.description.trim().length > 55
                                                            ? (
                                                                <>
                                                                    {itm.description.slice(0, 55) + "..."}
                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                </>
                                                            )
                                                            : itm.description.trim()}
                                                    </span>
                                                    { /* Media Query for Mobile Mode */}
                                                    <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                        {itm.imageUrl ? // If imageUrl is true
                                                            (itm.description.trim().length > 18
                                                                ? (
                                                                    <>
                                                                        {itm.description.slice(0, 18) + "..."}
                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                    </>
                                                                )
                                                                : itm.description)
                                                            : // If imageUrl is false/null/empty
                                                            (itm.description.trim().length > 18
                                                                ? (
                                                                    <>
                                                                        {itm.description.slice(0, 18) + "..."}
                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                    </>
                                                                )
                                                                : itm.description)
                                                        }
                                                    </span>
                                                </p>

                                            </div>
                                            {this.handleRibbonList(itm)}
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="clear clear-bottom" style={{ display: "none" }}></div>

            </div>

        );
    };

    // due to collapse based on switch wise added by vijay 
    handleGenerateRowRecommendedListAccordionDom() {
        const { scrollPosition } = this.props;
        const { activeAccordionItemId, isComponentMounted } = this.state;

        const handleRecAccordionItemClick = (recMngId) => {
            if (activeAccordionItemId === recMngId) {
                this.setState({ activeAccordionItemId: null });
            } else {
                this.setState({ activeAccordionItemId: recMngId });
            }
        };

        // Check if the component is mounting for the first time
        if (!isComponentMounted) {
            this.setState({ activeAccordionItemId: null, isComponentMounted: true });
        }
        return (
            <div className="accordion" id="accordion4">
                <div className="content-from" id="focus-mnugrp-recommended">
                    <div className="accordion-group" key={"acc-rec-grp-0"}>
                        <div className="accordion-heading">
                            <div className='panel-heading'>
                                <h4 className="panel-title">
                                    {/* <a className={"accordion-toggle " + (this.state.isExpanded ? "expand" : "expand collapsed")} style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion4" href={"#menugroupcollapse" + this.state.recommendedList.menuGroupId}> */}
                                    <a className={"accordion-toggle " + ((activeAccordionItemId === this.state.recommendedList.menuGroupId) ? "expand" : "expand collapsed")} style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion4" href={"#menugroupcollapse" + this.state.recommendedList.menuGroupId} onClick={() => handleRecAccordionItemClick(this.state.recommendedList.menuGroupId)}>
                                        <p style={{ marginBottom: "-4px", fontSize: "16px" }}>
                                            {this.state.recommendedTxt}   ({this.state.recommendedList.length})
                                        </p>
                                    </a>
                                </h4>
                            </div>
                        </div>
                        <div id={"menugroupcollapse" + this.state.recommendedList.menuGroupId} className={"accordion-body collapse " + ((activeAccordionItemId === this.state.recommendedList.menuGroupId) ? "show" : "")}>
                            {/* <div id={"menugroupcollapse" + this.state.recommendedList.menuGroupId} className={"accordion-body collapse " + (this.state.isExpanded ? "show" : "")}> */}
                            <div className="accordion-inner">
                                <div className="wrap-list-rc recommended-item" id="item-list">
                                    {
                                        this.state.recommendedList.map(itm =>

                                            <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"mnu-rec-" + itm.itemId} >
                                                <div className="lrc-content">
                                                    {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                    {itm.newItem && itm.bestSellerItem ? (
                                                        <>
                                                            <span className="ribbon">
                                                            <span className="txt-new">NEW</span>
                                                            <span className="txt-bestseller">Bestseller</span>

                                                            </span>
                                                        </>
                                                    ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                        <span className="ribbon">
                                                            <span className="txt-new">NEW</span> </span>
                                                    ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                        <span className="ribbon">
                                                            <span className="txt-bestseller">Bestseller</span> </span>
                                                    ) : null}
                                                    <div className="lrc-desc">
                                                        {/* {this.entryMode()} */}
                                                        <div style={{ padding: "1px" }} className={"qty-detail item-entry-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-entry-" + itm.itemId}>
                                                            <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                            <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                            <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                        </div>

                                                        {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                                            // first rec page 
                                                            <div className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                            </div>
                                                        }

                                                        {/* level2 change */}

                                                        {/* commented by dasprakash on 4-08-2023 jira-1302 */}
                                                        {/* {outletDetails.enableGuestAppOrders === false &&
                                            <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                            </div>
                                        } */}

                                                        {outletDetails.enableGuestAppOrders === false && (
                                                            itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                // If both conditions are true
                                                                !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                    <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                        Customisable
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                // If the first condition is true but the second one is false
                                                                // Condition Added by vijay on 21-03-2024
                                                                // <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                    </div>
                                                                ) : (
                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                    </div>
                                                                )
                                                            )
                                                        )}

                                                        {/*  */}

                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                                            <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                <span className="soldout">SOLD OUT</span>
                                                            </div>}
                                                        {/* recomended item customisable margin top change by windowsinnerwidth  changed  by dasprakash on 18-08-2023 jira-1302 */}
                                                        {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true && <div className={itm.imageUrl !== null && itm.imageUrl !== "" ? 'desktop-modifier-no-img-items' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: itm.imageUrl !== null && itm.imageUrl !== "" ? "102px" : (window.innerWidth >= 420 ? "18px" : "18px"), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">Customisable</div></div>}
                                                        {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                            <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-rec-", "")}>
                                                                {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}
                                                                {/* added function to blure the out of stock img in recomended page by dasprakash on 18-08-2023 jira-1302 */}
                                                                {itm.outOfStock ? (
                                                                    <LazyLoadImage
                                                                        alt="item"
                                                                        className="item-imgs"
                                                                        effect="blur"
                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                        scrollPosition={scrollPosition}
                                                                        src={itm.imageUrl}
                                                                        delayTime={10000}
                                                                        threshold={100}

                                                                    />
                                                                ) : (
                                                                    <LazyLoadImage
                                                                        alt="item"
                                                                        className="item-img"
                                                                        effect="blur"
                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                        scrollPosition={scrollPosition}
                                                                        src={itm.imageUrl}
                                                                        delayTime={10000}
                                                                        threshold={100}
                                                                    />
                                                                )}


                                                            </div>
                                                        }
                                                        {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                        {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                            <div className='tag-soldout-txt'>
                                                                <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                    marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                    marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                }}>
                                                                    SOLD OUT
                                                                </span>
                                                            </div>
                                                        )}

                                                        <div className="lrc-title" style={this.state.styleMenuItem}>
                                                            {/* <div className={"row align-items-start item-name-row" + (itm.outOfStock ? ' tag-soldout-item' : '')} >
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{itm.itemName}</div>
                                                </div>

                                            </div> */}


                                                            {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            <span className="itm-title">{itm.itemName}</span>
                                           */}
                                                            <app-item-type>
                                                                <div className="image-type">
                                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                </div>
                                                            </app-item-type>
                                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>

                                                            {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <span className="soldout-txt">SOLD OUT</span>} */}
                                                        </div>


                                                        {/* recomended menu items price and des change position by dasprakash on 17-08-2023 jira-1302 */}
                                                        <div className="lrc-button">
                                                            <div className="lrcb-left">
                                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                    <Currency />
                                                                    {itm.mrp}
                                                                </span>
                                                            </div>
                                                            {/* <div className="lrcb-right">
                <button className="itm-add" type="button" data-toggle="modal" data-target="#modifier-modal" data-Qty><span>ADD</span></button>
            // </div> */}
                                                            {/* handleSetPopupImage function for image popup by dasprakash on 22-08-2023 jira-1302 */}

                                                            <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-rec-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                                {/* {itm.description.toLowerCase()} */}
                                                                {/* // Added by vijay on 10-02-2024 -Jira id -2075 -More control div comes to single line only */}

                                                                <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                    { /* Media Query for Desktop Mode */}
                                                                    <span className="desktop-description">
                                                                        {itm.description.trim().length > 55
                                                                            ? (
                                                                                <>
                                                                                    {itm.description.slice(0, 55) + "..."}
                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                </>
                                                                            )
                                                                            : itm.description.trim()}
                                                                    </span>
                                                                    { /* Media Query for Mobile Mode */}
                                                                    <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                        {itm.imageUrl ? // If imageUrl is true
                                                                            (itm.description.trim().length > 18
                                                                                ? (
                                                                                    <>
                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                    </>
                                                                                )
                                                                                : itm.description)
                                                                            : // If imageUrl is false/null/empty
                                                                            (itm.description.trim().length > 18
                                                                                ? (
                                                                                    <>
                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                    </>
                                                                                )
                                                                                : itm.description)
                                                                        }
                                                                    </span>
                                                                </p>

                                                            </div>
                                                            {this.handleRibbonList(itm)}

                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="clear clear-bottom" style={{ display: "none" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    };

    goToOrderTracking() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {

                this.setState({ loading: false });
                this.props.history.push('/ordertrack' + urlPattern);
            }, Config.minMsgTimeout);
        }
        // else {
        //     this.setState({ loading: true });
        //     sessionStorage.setItem("login-page-session", 7); // PayBill        
        //     setTimeout(() => {
        //         this.setState({ loading: false });
        //         this.props.history.push('/login');
        //     }, 3000);
        //     return false;
        // }
    };

    goToHelpRequest() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            // setTimeout(() => {

            //     this.setState({ loading: false });
            //     this.props.history.push('/helprequest');
            // }, 1200);

            setTimeout(() => {
                this.setState({ loading: false });
                this.handleRequest();
            }, Config.minMsgTimeout);


        }
    };

    goToGroupMemberList() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });

            setTimeout(() => {
                this.setState({ loading: false });
                this.handleGroupMemberList();
            }, Config.minMsgTimeout);
        }
    };

    getGuestProcessOrders() {
        //////////////////console.log('order getGuestProcessOrders()...');
        // ////////////////console.log(mobileNo);
        mobileNo = sessionStorage.getItem('user-mobileno');
        this.setState({ loading: true });
        if (outletCode !== null && mobileNo !== null && mobileNo !== '0000000000') {
            fetch(Config.baseGuestUrl + 'GetGuestOrderList?OutletCode=' + outletCode + '&GuestMobileNo=' + mobileNo, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
            })
                .then(res => res.json())
                .then(response => {
                    this.setState({ loading: false });
                    if (response.errorCode === 0) {
                        // ////////////////console.log(response.response.orders);
                        this.setState({ orders: response.response.orders });
                        if (response.response.orders.length > 0) {
                            sessionStorage.setItem('order-track-status', "true");
                            this.setState({ ordertrack: true });
                        }
                    }
                    else {
                        this.setState({ orders: [] });
                        sessionStorage.setItem('order-track-status', "false");
                        this.setState({ ordertrack: false });
                        this.showMessage(response.message, "danger");
                    }
                })
                .catch(err => {
                    this.setState({ loading: false, orders: [] });
                    sessionStorage.setItem('order-track-status', "false");
                    this.setState({ ordertrack: false });
                    this.showMessage('Guest Order List - Failed to fetch - ' + err, "danger");
                });
        }
    }

    //https://lucid.atlassian.net/browse/L21-320
    handleRequest(_this) {
        if (reqeustRef.current) {
            reqeustRef.current.handleRequest();
        }
    }

    handleGroupMemberList(_this) {
        if (groupMemberRef.current) {
            groupMemberRef.current.handleGroupMemberList();
        }
    }
    // added bu dasprakash on 23-08-2023 jira-1302
    handleSetPopupImage = (itmName, itmDesc, itmPrice, imgFType, imgUrl, item, id, menugroups) => {
        // Added by vijay on 29-01-2024 Jira id-2075 - Testing Points
        // const itemImgUrl = imgUrl !== null ? imgUrl : '';
        const itemImgUrl = imgUrl !== null && imgUrl !== '' ? imgUrl : '';

        this.setState({
            imgItemName: itmName,
            imgItemDesc: itmDesc,
            imgItemPrice: itmPrice,
            imgItemFType: imgFType,
            itemImgUrl: itemImgUrl,
            item: item,
            id: id,
            menuGroupsId: menugroups
        });

        setTimeout(() => {
            this.handlePopupImage();
        }, Config.minMsgTimeout);
    };

    handlePopupImage() {
        if (imageRef.current) {
            imageRef.current.handlePopupImage();
        }
    };

    getGuestDashboardDetails() {
        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&TableNumber=' + tableNo + '&guestmobileNumber=' + mobileNo;
        // ////////////////console.log("urlParam");
        fetch(Config.basePOSUrl + 'GetGuestAppDashBoardDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    // if (mobileNo === mainGuestMobileNo) {
                    //     //  this.setState({ groupPin: response.response.groupDetails.groupCode });
                    //     // group pin commended by dasprakash on 29-09-2023 jira-1493

                    //     // sessionStorage.setItem('group-pin', response.response.groupDetails.groupCode);
                    //     // groupPin = sessionStorage.getItem('group-pin') == null ? '' : sessionStorage.getItem('group-pin');

                    //     // 
                    //     //Addded by vijay on 30-12-2023 Jira id -2075
                    //     this.setState({ tablePin: response.response.groupDetails.groupCode });
                    //     sHelpers.secureStorage.setItem('user-table-pin', response.response.groupDetails.groupCode);



                    //     if (groupPin !== '') {
                    //         grouppinstyle = {
                    //             marginBottom: "40px",
                    //             marginTop: "256px"
                    //         }
                    //         this.setState({ stylegroupPin: grouppinstyle });
                    //     }
                    // }
                    // else {
                    //     groupPin = '';
                    //     grouppinstyle = {
                    //         marginBottom: "40px",
                    //         marginTop: "214px"
                    //     }
                    //     this.setState({ stylegroupPin: grouppinstyle });
                    // }

                    this.setState({ tablePin: response.response.groupDetails.groupCode });
                    tablePins = response.response.groupDetails.groupCode;
                    sHelpers.secureStorage.setItem('user-table-pin', response.response.groupDetails.groupCode);

                    this.setState({ groupMemberList: response.response.groupDetails.guestDetails });
                }
                else {
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                ////////////////////console.log(err);            
                this.showMessage('An error occured - ' + err, "danger");
            });
    };
    // added by dasprakash on 11-10-2023 jira-1302
    menuGroupInitialScroll() {
        ////////////console.log("calling");
        const container = document.querySelector('.mng-list');

        if (container) {
            // Scroll to the starting of the div
            container.scrollLeft = 0;
        }
    }

    handleClassificationScroll(clfid) {
        const container = document.querySelector('.classifications-v2');
        const selectedElement = document.getElementById('clf-' + clfid); // Replace clfId with your actual ID

        if (container && selectedElement) {
            const containerRect = container.getBoundingClientRect();
            const selectedRect = selectedElement.getBoundingClientRect();

            // Calculate the scroll amount to bring the selected element to the center
            const scrollAmount = selectedRect.left - containerRect.left - (containerRect.width - selectedRect.width) / 2;

            const duration = 500; // Adjust the duration as needed
            const startTime = performance.now();
            const initialScrollLeft = container.scrollLeft;

            function scroll(timestamp) {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                container.scrollLeft = initialScrollLeft + scrollAmount * progress;

                if (progress < 1) {
                    requestAnimationFrame(scroll);
                }
            }

            requestAnimationFrame(scroll);
        }

    }

    handleClassificationClick(index, clfName, clfid, Isfocus = false, clf,) {
        //console.log("handleClassificationClick");
        this.menuGroupInitialScroll();
        this.handleClassificationScroll(clfid);


        //commented by dasprakash on 22-11-2023
        // const getMenuGroups = JSON.parse(sessionStorage.getItem("getMenuGroups"));
        // // eslint-disable-next-line
        // const filteredMenus = getMenuGroups.filter(item => item.classification == clfid);        
        // const indices = filteredMenus.map((item, index) => index);
        // const lastIndex = Math.max(...indices);
        // this.setState({ paginationEnable: true });


        this.setState({ classificationRecommendedImageIndex: index });

        let grecList = [];
        // Assuming this.state.getMenus is an array of menu items
        // added by dasprakash on 25-08-2023 jira-1302
        this.setState({ pagnitationPrvShow: - 1 });
        if (outletDetails.showOnlySelectedMenuGroupItems) {
            // Added by vijay on 31-01-2025
            const getFiltersLists = sessionStorage.getItem("item-tag-filter-list") === null ? [] : JSON.parse(sessionStorage.getItem("item-tag-filter-list"));
            // console.log(getFiltersLists, 'getFiltersList');
            if (getFiltersLists.length > 0) {
                // Get the filtered items
                getItems = sessionStorage.getItem("item-tag-filter-list") === null ? [] : JSON.parse(sessionStorage.getItem("item-tag-filter-list"));
            }
            else {
                getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
                if (this.state.switch1 === false && this.state.switch2 === false)  // due to clcik on referesh button on that time this code will excute all items list need to show 
                {
                    getGlobalItems = [];
                }

            }

            getGlobalItems = getGlobalItems.length === 0 ? getItems : getGlobalItems;
            let filterItems = getGlobalItems.filter(obj => { return (obj.classification === clfid) });

            // jk-27-01-2024 - veg filter issue solved
            if (isCheckVeg) {
                filterItems = filterItems.filter(obj => { return (obj.foodType === 1) });
            }
            // jk-27-01-2024 - veg filter issue solved - end
            else if (isCheckNonVeg) {
                filterItems = filterItems.filter(obj => { return (obj.foodType === 2) });
            }

            //added by dasprakash on 22-11-2023
            let mgList = this.getFilterMenGroupList(filterItems);
            this.setState({ fliteredMenuGroup: mgList })
            //console.log("in classification method upper", this.state.getMenus);

            const indices = mgList.map((item, index) => index);
            const lastIndex = Math.max(...indices);
            this.setState({ pagnitationNextShow: lastIndex, nextPage: mgList[0], classificationRecommendedImageIndex: index })
            this.setState({ paginationEnable: true });

            let recList = filterItems.filter(itm => itm.isRecommended);
            recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })


            grecList = recList;
            //console.log("grecList", grecList)
            // ////////////////console.log(this.state.isRecommendedItemEnabled);
            this.setState({ fliteredRecList: recList })

            if (recList.length > 0) {
                this.filterMenus([], recList, filterItems);

            }
            else {
                // this.setState             

                let mgList = this.getFilterMenGroupList(filterItems);
                // added by dasprakash on 23-11-2023
                this.setState({ fliteredMenuGroup: mgList }, () => {

                    this.handleMenugroupsClick(0, clfid, mgList[0].menuGroupId, grecList);
                });

            }

        }
        else {
            let filterItems = getItems.filter(obj => { return (obj.classification === clfid) });

            // jk-27-01-2024 - veg filter issue solved
            if (isCheckVeg)
                filterItems = filterItems.filter(obj => { return (obj.foodType === 1) });
            // jk-27-01-2024 - veg filter issue solved - end
            else if (isCheckNonVeg) {
                filterItems = filterItems.filter(obj => { return (obj.foodType === 2) });
            }


            let recList = filterItems.filter(itm => itm.isRecommended);
            recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })
            grecList = recList;
            //console.log("grecList", grecList)

            this.filterMenus(filterItems, recList, filterItems);
            //console.log("in classification method", this.state.getMenus);

            this.setState({ searchText: "" });
        }
        ////////////////console.log(grecList.length)
        ////////////////console.log("this.state.searchSelectedMenuGroup.length")
        ////////////////console.log(this.state.searchselectedMenugroupIndex)
        this.setState(
            {
                activeClassification: index,
                recommendedTxt: "Recommended " + clfName,
                loading: true,
                selectedClassification: clfid,
                // activeMenuGroup: grecList.length > 0 && this.state.searchSelectedMenuGroup.length === 0 ? -1 : this.state.searchselectedMenugroupIndex
                //    added by dasprakash on 05-10-2023 jira-1302
                activeMenuGroup: grecList.length > 0 && this.state.searchSelectedMenuGroup.length === 0 ? -1 : 0
            },

            () => {
                ////console.log("this.state.activeMenuGroup")
                ////console.log(this.state.activeMenuGroup)

                ////console.log("this.state.activeMenuGroup"); // This logs the string "this.state.activeMenuGroup"
                ////console.log(this.state.activeMenuGroup); // This logs the actual state value
                ////console.log("this.state.searchSelectedMenuGroup.length"); // This logs the string "this.state.searchSelectedMenuGroup.length"
                ////console.log(this.state.searchSelectedMenuGroup.length); // This logs the actual state value
            }
        );

        if (this.state.searchSelectedMenuGroup.length !== 0) {

            this.handleMenugroupsClick(this.state.searchselectedMenugroupIndex, clfid, this.state.searchSelectedMenuGroup.menuGroupId);
        }

        // ////////////////console.log(Isfocus)

        // if (Isfocus === true) {
        //     // var stickyContainer = document.getElementById("fist-item-row");
        //     window.scrollTo({
        //         top: 0,
        //         behavior: 'smooth', // Use 'smooth' for smooth scrolling
        //     });
        // } else {
        //     // var stickyContainer = document.getElementById("fist-item-row");
        //     window.scrollTo({
        //         left: 0,
        //        // top: stickyContainer.offsetTop - 0,
        //         top: 0,
        //         behavior: 'smooth', // Use 'smooth' for smooth scrolling
        //     });
        // }
        //  this.handleMenugroupsClick(-1, 1, -1, -1, true, true);
        setTimeout(() => {
            this.setState({
                loading: false
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Use 'smooth' for smooth scrolling
            });
        }, Config.minMsgTimeout);

        // ////////////////console.log(this.state.recommendedList)


    }

    // added by dasprakash on 06-10-2023 jira-1302
    menuGroupScroll(mgid) {
        const container = document.querySelector('.mng-list');
        const selectedMenuGroup = document.getElementById('mng-' + mgid);

        if (container && selectedMenuGroup) {
            const containerRect = container.getBoundingClientRect();
            const selectedMenuGroupRect = selectedMenuGroup.getBoundingClientRect();

            // Calculate the scroll amount to bring the selected menu group to the center
            const scrollAmount = selectedMenuGroupRect.left - containerRect.left - (containerRect.width - selectedMenuGroupRect.width) / 2;

            const duration = 500; // Adjust the duration as needed
            const startTime = performance.now();
            const initialScrollLeft = container.scrollLeft;

            function scroll(timestamp) {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                container.scrollLeft = initialScrollLeft + scrollAmount * progress;

                if (progress < 1) {
                    requestAnimationFrame(scroll);
                }
            }

            requestAnimationFrame(scroll);
        }
    }

    handleMenugroupsClick(index, clfid, mgid, mng, Isfocus = false, isRecom = false, display, grecList) {
        // added by dasprakash on 06-10-2023 jira-1302
        // this.menuGroupScroll(mgid);
        // added by dasprakash on 09-09-2023 jira-1302

        // const getMenuGroups = JSON.parse(sessionStorage.getItem("getMenuGroups"));
        // eslint-disable-next-line
        const filteredMenus = this.state.fliteredMenuGroup.filter(item => item.classification == clfid);
        // // let mgList = this.getFilterMenGroupList(filterItems);
        const nextIndex = (index + 1) % filteredMenus.length;
        const previousIndex = (index - 1 + filteredMenus.length) % filteredMenus.length;
        // console.log('filteredMenus',filteredMenus);
        // ////////////////console.log("index");
        // ////////////////console.log(index);
        // ////console.log("Next Item:", filteredMenus[nextIndex]);
        // ////console.log("Previous Item:", filteredMenus[previousIndex]);
        // if (filteredMenus[nextIndex] === filteredMenus[previousIndex] && !this.state.fliteredRecList) {
        //     this.setState({ paginationEnable: false })
        //     ////////////////console.log(this.state.isRecommendedItemEnabled)
        //     ////////////////console.log("state false")
        // } else {
        //     this.setState({ paginationEnable: true })
        //     ////////////////console.log("state true")

        // }

        // ////////////////console.log("this.state.isRecommendedItemEnabled")
        // ////////////////console.log(this.state.isRecommendedItemEnabled)

        // if (index == 0){
        //     this.state.isRecommendedItemEnabled
        // }
        // added by dasprakash on 25-08-2023 jira-1302
        this.setState({ lastIndex: this.state.getMenus.length - 1 }, () => { });
        //     // ////////////////console.log(this.state.lastIndex); // This will log the updated value

        // // added by dasprakash on 09-09-2023 jira-1302
        this.setState({ expanded: false })

        this.setState({ menuGroupIndex: index }, () => {
            ////////////////console.log(this.state.menuGroupIndex); // This will log the updated value
        });
        let mgFilterItems = [];
        let clfFilterItems = [];
        let recList = [];
        // let mgList = [];
        this.setState({
            activeMenuGroup: index, loading: true,
            selectedMenuGroupId: mgid
        });

        if (outletDetails.showOnlySelectedMenuGroupItems) {
            getGlobalItems = getGlobalItems.length === 0 ? getItems : getGlobalItems;
            //   console.log('getGlobalItems',getGlobalItems)
            // if (this.state.searchText !== "")
            //     clfFilterItems = getGlobalItems.filter(obj => { return (obj.classification === clfid && obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });
            // else
            //     clfFilterItems = getGlobalItems.filter(obj => { return (obj.classification === clfid) });
            // let mgFilterItems = getItems.filter(obj => { return (obj.classification === clfid && obj.menuGroupId === mgid) });

            // eslint-disable-next-line
            clfFilterItems = getGlobalItems.filter(obj => { return (obj.classification == clfid) });
            //  console.log('clfFilterItems',clfFilterItems)
            // added by dasprakash on 10-09-2023 jira-1302
            if (index === -1) {
                isRecom = true
            }

            recList = isRecom ? clfFilterItems.filter(itm => itm.isRecommended) : [];
            // ////////////////console.log("recListdasdasdaded");
            // ////////////////console.log(recList);
            // if (filteredMenus[nextIndex] === filteredMenus[previousIndex] && recList.length <= 0) {
            //     this.setState({ paginationEnable: false })
            // }else{
            //     this.setState({ paginationEnable: true })

            // }
            clfFilterItems.filter(itm => itm.isRecommended).length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: clfFilterItems.filter(itm => itm.isRecommended).length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 });

            const fl = clfFilterItems.filter(itm => itm.isRecommended)
            // ////////////////console.log("fl");
            // level 6 change
            if (index === 0) {
                if (fl.length > 0) {

                    this.setState({
                        nextPage: filteredMenus[nextIndex],
                        perviousPage: [],
                        pagnitationPrvShow: index,
                        recpagination: true

                    })
                }
                else {

                    this.setState({
                        nextPage: filteredMenus[nextIndex],
                        perviousPage: [],
                        pagnitationPrvShow: index,
                        recpagination: false
                    })
                }

            } else {
                this.setState({
                    nextPage: filteredMenus[nextIndex],
                    perviousPage: filteredMenus[previousIndex],
                    pagnitationPrvShow: index,
                    recpagination: true

                })
            }
            if (filteredMenus.length <= 1 && fl.length <= 0) {
                this.setState({ paginationEnable: false })

            } else {
                this.setState({ paginationEnable: true })

            }
            // level 6 change
            //  mgList = this.getFilterMenGroupList(clfFilterItems);
            // let filterMGList = mgList.filter(mg => mg.menuGroupId === mgid);
            let filterMGList = getMenus.filter(mg => mg.menuGroupId === mgid);
            mgFilterItems = this.getFilterItemList(clfFilterItems, filterMGList);
            // console.log('mgFilterItems',mgFilterItems);
            // console.log('clfFilterItems',clfFilterItems);
            // console.log('filterMGList',filterMGList);
            // const fliters = mgFilterItems.filter(itm => itm.isRecommended)
            // ////////////////console.log("fliters");
            // ////////////////console.log(fliters);

            this.filterMenus(mgFilterItems, recList, clfFilterItems);

            if (Isfocus === true) {
                var stickyContainer = document.getElementById("fist-item-row");
                window.scrollTo({
                    top: stickyContainer.offsetTop - 200,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Use 'smooth' for smooth scrolling
            });
        }
        else {
            this.menuGroupScroll(mng.menuGroupId);
            if (Isfocus === true) {
                stickyContainer = document.getElementById("fist-item-row");
                window.scrollTo({
                    top: stickyContainer.offsetTop - 330,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else {
                // commended by vijay on 12-01-2024  Jira id- LDT-2234
                // stickyContainer = document.getElementById("focus-mnugrp-" + mgid + "");
                // window.scrollTo({
                //     top: stickyContainer.offsetTop - 235,
                //     left: 0,
                //     behavior: 'smooth'
                // });
                // Added by vijay on 12-01-2024  Jira id- LDT-2234
                let scrollToOffset = "";
                if (!outletDetails.showOnlySelectedMenuGroupItems && !outletDetails.showMenuGroupImage) {
                    scrollToOffset = -235; // Adjust the common offset
                } else {
                    scrollToOffset = -316; // Adjust the offset for the other case
                }

                stickyContainer = document.getElementById("focus-mnugrp-" + mgid + "");

                window.scrollTo({
                    top: stickyContainer.offsetTop + scrollToOffset,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, Config.minMsgTimeout);

    }
    // ScrollHide() {
    //     const target = document.querySelector('.scroll-hide');

    //     ////////////////console.log(target);
    //     target.addEventListener("scroll", function () {
    //         // setIsVisible(false)

    //     }, false);

    //     window.scroll(function () {
    //         ////////////////console.log('jk');
    //         if (window.scrollTop() > 250) {

    //             target.hide(1000);
    //         }
    //         else {
    //             target.show(1000);
    //         }
    //     });

    // }

    getFilterMenGroupList(itemslist) {
        let filterGroups = [];

        //Menu Group Filter
        let _uniquesGroups = [];

        var _taxItmFound = {};
        for (var md = 0, lenc = itemslist.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(itemslist[md].menuGroupId);
            if (_taxItmFound[stringified]) { continue; }
            _uniquesGroups.push({ groupId: itemslist[md].menuGroupId });
            _taxItmFound[stringified] = true;
        };
        //  ////////////////console.log(_uniquesGroups);

        Array.prototype.forEach.call(getMenus, grp => {
            let grplst = [];
            let subgrplst = []
            let itemCount = itemslist.filter(itm => itm.menuGroupId === grp.menuGroupId).length;
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    grplst = _uniquesGroups.filter(x => x.groupId === sgp.menuGroupId);
                    if (grplst.length > 0) {
                        itemCount += itemslist.filter(itm => itm.menuGroupId === sgp.menuGroupId).length;
                        subgrplst.push({ classification: sgp.classification, description: sgp.description, displayOrder: sgp.displayOrder, hasSubMenuGroup: sgp.hasSubMenuGroup, hsnsac: sgp.hsnsac, isActive: sgp.isActive, menuGroupId: sgp.menuGroupId, menuGroupName: sgp.menuGroupName, subMenuGroup: sgp.subMenuGroup, noOfItems: itemCount });

                    }
                    // third Level menus 
                    Array.prototype.forEach.call(sgp.subMenuGroup, ssgp => {
                        grplst = _uniquesGroups.filter(x => x.groupId === ssgp.menuGroupId);
                        if (grplst.length > 0) {
                            itemCount += itemslist.filter(itm => itm.menuGroupId === ssgp.menuGroupId).length;
                            subgrplst.push({ classification: ssgp.classification, description: ssgp.description, displayOrder: ssgp.displayOrder, hasSubMenuGroup: ssgp.hasSubMenuGroup, hsnsac: ssgp.hsnsac, isActive: ssgp.isActive, menuGroupId: ssgp.menuGroupId, menuGroupName: ssgp.menuGroupName, subMenuGroup: ssgp.subMenuGroup, noOfItems: itemCount });
                        }

                    });

                });
                if (subgrplst.length > 0) {
                    //    added by dasprakash on 22-11-2023
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: subgrplst, noOfItems: itemCount, imageUrl: grp.imageUrl });
                    //    commented by dasprakash on 22-11-2023
                    // filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: subgrplst, noOfItems: itemCount});
                }
            }
            else {
                grplst = _uniquesGroups.filter(x => x.groupId === grp.menuGroupId);
                if (grplst.length > 0)
                    //    added by dasprakash on 22-11-2023
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: [], noOfItems: itemCount, imageUrl: grp.imageUrl });
                //    commented by dasprakash on 22-11-2023
                // filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: [], noOfItems: itemCount });
            }
        });

        filterGroups = [...filterGroups].sort((a, b) => a.displayOrder - b.displayOrder);


        return filterGroups;

    }

    getFilterItemList(itemslist, mgList) {

        let filterItemList = [];
        let items = [];
        Array.prototype.forEach.call(mgList, grp => {

            items = itemslist.filter(itm => itm.menuGroupId === grp.menuGroupId);

            if (items.length > 0) filterItemList = this.getFilterItems(items, filterItemList);

            // 2nd lvele items
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    items = itemslist.filter(itm => itm.menuGroupId === sgp.menuGroupId);
                    if (items.length > 0) filterItemList = this.getFilterItems(items, filterItemList);

                    // third Level menus 
                    Array.prototype.forEach.call(sgp.subMenuGroup, ssgp => {
                        items = itemslist.filter(itm => itm.menuGroupId === ssgp.menuGroupId);
                        if (items.length > 0) filterItemList = this.getFilterItems(items, filterItemList);
                    })
                })
            }
        });

        return filterItemList;
    }

    getFilterItems(aList, oList) {
        let itemlist = [];
        Array.prototype.forEach.call(aList, i => {
            itemlist = {
                itemType: i.itemType, itemId: i.itemId, itemName: i.itemName,
                description: i.description, rate: i.rate, mrp: i.mrp, foodType: i.foodType, spiciness: i.spiciness,
                menuGroupId: i.menuGroupId, classification: i.classification, taxId: i.taxId, modifierGroupIds: i.modifierGroupIds,
                chargeIds: i.chargeIds, imageUrl: i.imageUrl, outOfStock: i.outOfStock, bestSellerItem: i.bestSellerItem, isRecommended: i.isRecommended,
                displayOrder: i.displayOrder, hsnsac: i.hsnsac, subItems: i.subItems, isActive: i.isActive,
                // if any new field add here need to add the column names here 
                newItem: i.newItem,
                popularItem: i.popularItem,
                todaySpecialItem: i.todaySpecialItem,
                chefSpecialItem: i.chefSpecialItem,
                mustTryItem: i.mustTryItem,
            }
            oList.push(itemlist);
        })
        return oList;
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            // this.setState({ msgShow: false });
        }, Config.averageMsgTimeout);
    };


    handleButtonClick = (index, list) => {
        // ////////////////console.log(index)
        // ////////////////console.log(list.classification)
        ////////////console.log("list.menuGroupId")
        ////////////console.log(list.menuGroupId)
        this.menuGroupScroll(list.menuGroupId);

        // ////////////////console.log("index")
        // ////////////////console.log(index)
        if (index === -1) {
            this.handleMenugroupsClick(-1, this.state.selectedClassification, -1, true, true)

        } else {

            this.handleMenugroupsClick(index, list.classification, list.menuGroupId, list, true, false)
        }
    }

    condition = (idx, classificationName, classification, clf) => {
        this.setState({ searchSelectedMenuGroup: [] }, () => {
            // Callback after the state is updated
            this.handleClassificationClick(idx, classificationName, classification, true, clf);

            // Scroll the div to the starting position
            const container = document.querySelector('.mng-list');
            if (container) {
                container.scrollLeft = 0;
            }
        });
    }

    getCartDetails() {
        if (sessionStorage.getItem("cartList") != null) {

            cartList = JSON.parse(sessionStorage.getItem("cartList"));
            this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
            totalItems = cartList.totalItems;
            totalAmount = cartList.totalAmount;
            taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
            chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
            // console.log("Cart List Total Items:",cartList.totalItems);

            // refresh the item qty  // added by vijay on 04-03-2024 
            Array.prototype.forEach.call(cartList.itemList, itm => {
                if (document.getElementById("" + itm.id + "") != null) {
                    document.getElementById("" + itm.id + "").innerHTML = 0;
                    let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
                    this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
                }
            });

            // group by item            
            Array.prototype.forEach.call(cartList.itemList, itm => {
                //  console.log("Total itemlist =",cartList.itemList);
                if (document.getElementById("" + itm.id + "") != null) {
                    let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);
                    //  console.log("Total Quantity =",totlaQty);
                    document.getElementById("" + itm.id + "").innerHTML = (totlaQty + itm.qty);
                    let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
                    this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
                }
            });

        }
        else {
            cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
            this.setState({ cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] } });
            this.setState({ totalItems: 0, totalAmount: 0 });
            totalAmount = 0;
            totalItems = 0;
            taxList = [];
            chargeList = [];
        }

    }
    // added by dasprakash on 11-11-2023 jira-1744
    callbackAccessMode = () => {
        //////////console.log('Callback function called from Changeaccessmode component');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        outletDetails = JSON.parse(outletDetailsStr);
        //console.log("outletDetails.showOnlySelectedMenuGroupItems", outletDetails.showOnlySelectedMenuGroupItems)
        this.setState({ switch1: false });
        this.setState({ switch2: false });
        if (outletDetails.showOnlySelectedMenuGroupItems === false) {
            //////console.log("running in did mount")
            window.addEventListener('scroll', this.handleScroll);
        }
        this.loadClassficiation(true);
    }

    loadClassficiation(isLoadiItem, callback) {
        // ////////////console.log(new Date())
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        fetch(Config.basePOSUrl + 'GetClassificationList' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //////console.log("loadClassficiation api")
                //////console.log(response)
                if (response.errorCode === 0) {
                    this.setState({ getClassification: response.response.classificationList });
                    ////////console.log(this.state.getClassification)

                    sessionStorage.setItem("getclf", JSON.stringify(response.response.classificationList));
                    // if (isLoadiItem) this.getUpdateMenus();
                    if (response.response.classificationList.length < 1) {
                        this.props.history.push('/home' + urlPattern);
                    } else {
                        this.getUpdateMenus();
                    }

                }
                else {
                    this.setState({ APIResponseMsg: "Failed to fetch" })
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Failed to fetch" })
                //////////////console.log(err);
                // this.showMessage("An error occured", "danger");
            });
        // ////////////console.log("loadClassficiationEnd")
        // ////////////console.log(new Date())
    }
    // added by dasprakash on 21-11-2023 jira-1741
    handleScroll = () => {
        if (outletDetails.showOnlySelectedMenuGroupItems === false && outletDetails.showMenuGroupInGuestApp === true) {
            //console.log("dsdasdad")    showMenuGroupInGuestApp
            // const getMenuGroups = JSON.parse(sessionStorage.getItem("getMenuGroups"));
            const getMenuGroups = this.state.fliteredMenuGroup;
            const mngListElement = document.querySelector('.mng-list');
            const contentFromElements = document.querySelectorAll('.content-froms');
            const contentElement = document.getElementById('focus-mnugrp-recommended');

            if (contentElement && mngListElement) {
                const contentRect = contentElement.getBoundingClientRect();
                const mngListRect = mngListElement.getBoundingClientRect();

                // Check if the bottom of the contentElement touches the top of the mngListElement
                if (contentRect.bottom >= mngListRect.top && contentRect.top <= mngListRect.bottom) {
                    this.setState({ activeMenuGroup: -1 });
                }
            }


            if (contentElement && mngListElement) {
                const contentRect = contentElement.getBoundingClientRect();
                const mngListRect = mngListElement.getBoundingClientRect();
                if (contentRect.bottom >= mngListRect.top && contentRect.bottom <= mngListRect.bottom) {
                    this.setState({ activeMenuGroup: 0 });
                }
            }


            contentFromElements.forEach(element => {
                const rect = element.getBoundingClientRect();

                if (rect.bottom >= mngListElement.getBoundingClientRect().top && rect.top <= mngListElement.getBoundingClientRect().bottom) {

                    const numericId = element.id.match(/\d+/);
                    const itmgrpData = element.getAttribute('data-itmgrp');
                    const itmgrp = itmgrpData ? JSON.parse(itmgrpData) : null;
                    // eslint-disable-next-line
                    const filteredMenus = getMenuGroups.filter(item => item.classification == itmgrp.classification);
                    // eslint-disable-next-line
                    const activeMenuGroupIndex = filteredMenus.findIndex(item => item.menuGroupId == itmgrp.menuGroupId);

                    if (numericId && previousNumericId !== numericId[0]) {
                        this.menuGroupScroll(numericId);
                    }

                    this.setState({ activeMenuGroup: activeMenuGroupIndex });
                    previousNumericId = numericId ? numericId[0] : previousNumericId;
                }

            });
        }
    }

    handleCheckGuestTableStatus(mobileNo, ptableNo) {
        let postObj = {
            OutletId: propertyId + outletCode,
            GuestMobileCountryCode: "91",
            GuestMobileNumber: mobileNo,
            TableNumber: ptableNo,
        };
        fetch(Config.basePOSUrl + "GetGuestTableStatus", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            },
            body: JSON.stringify(postObj),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.errorCode === 0) {
                    if (!response.response.guestTableStatus.isCheckedIn) {
                        this.showMessage(
                            "Your selected table has been checked out.",
                            "danger"
                        );
                        this.setState({
                            errorMessage: "Your selected table has been checked out.",
                        });
                        this.setState({ loading: true });
                        setTimeout(() => {
                            this.props.history.push("/app-error" + urlPattern, {
                                errorMessage: this.state.errorMessage,
                            });
                        }, Config.averageMsgTimeout);
                        return false;
                    } else {
                        // check in true
                        if (ptableNo !== response.response.guestTableStatus.currentTableNumber) {
                            this.setState({
                                errorMessage:
                                    "Your table has been transfer from " +
                                    ptableNo +
                                    " to " +
                                    response.response.guestTableStatus.currentTableNumber,
                            });
                            this.showMessage("Your table has been transfer from " + ptableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");

                            sessionStorage.setItem(
                                "user-tableno",
                                response.response.guestTableStatus.currentTableNumber
                            );
                            tableNo = sessionStorage.getItem("user-tableno");
                            this.getGuestDashboardDetails();

                        }
                        return false;
                    }
                } else {
                    this.setState({ errorMessage: response.message });
                }
            })
            .catch((err) => {
                this.setState({ errorMessage: err });
            });
    };

    // Due to collapse based on swithes added by vijay 
    handleGenerateItemListAccordionDom() {
        let mnugrp = "mnu-grp-";
        const { scrollPosition } = this.props; // handleAccordionItemClick handled by vijay
        const { activeAccordionItemId } = this.state;
        const handleAccordionItemClick = (mngid) => {

            if (activeAccordionItemId === mngid) {
                this.setState({ activeAccordionItemId: null });
            } else {
                this.setState({ activeAccordionItemId: mngid });
            }

            let scrollToOffset = "";
            if (!outletDetails.showOnlySelectedMenuGroupItems && !outletDetails.showMenuGroupImage && outletDetails.showMenuGroupInGuestApp) {
                scrollToOffset = -235;
            }
            else if (!outletDetails.showMenuGroupInGuestApp) {
                scrollToOffset = -160;
            }
            else {
                scrollToOffset = -315;
            }

            let menugroupContainer = document.getElementById("focus-mnugrp-" + mngid + "");

            window.scrollTo({
                top: menugroupContainer.offsetTop,
                left: 0,
                behavior: "smooth",
            });

            setTimeout(() => {
                // let accordionHeading = document.querySelector(`#focus-mnugrp-${mngid} .accordion-heading`);
                //   let accordionHeight = accordionHeading.offsetHeight;   

                const isMobile = window.innerWidth <= 768;

                if (isMobile) {
                    window.scrollTo({
                        top: menugroupContainer.offsetTop + scrollToOffset,
                        left: 0,
                        behavior: "smooth",
                    });
                } else {
                    window.scrollTo({
                        top: menugroupContainer.offsetTop + scrollToOffset,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            }, 100);

        };

        return (
            <>

                {this.state.getMenus.length > 0 && (
                    <div className="accordion" id="accordion4">
                        {this.state.getMenus.map(itmgrp =>
                            <div data-itmgrp={JSON.stringify(itmgrp)} className="content-froms" key={mnugrp + itmgrp.menuGroupId} id={"focus-mnugrp-" + itmgrp.menuGroupId}>
                                <div className="accordion-group" key={"acc-grp-" + itmgrp.menuGroupId}>
                                    <div className="accordion-heading">
                                        <div className='panel-heading'>
                                            <h4 className="panel-title">
                                                <a className={"accordion-toggle " + ((activeAccordionItemId === itmgrp.menuGroupId) ? "expand" : "expand collapsed")} style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion4" href={"#menugroupcollapse" + itmgrp.menuGroupId} onClick={() => handleAccordionItemClick(itmgrp.menuGroupId)}>
                                                    <p style={{ marginBottom: "-4px", fontSize: "16px" }} >

                                                        {/* <div className="itm-heading" style={this.state.styleMenuGroup}> */}
                                                        {itmgrp.menuGroupName.toLowerCase()}   ({itmgrp.noOfItems})
                                                        {/* </div> */}
                                                    </p>
                                                </a>
                                            </h4>
                                        </div>
                                    </div>
                                    <div id={"menugroupcollapse" + itmgrp.menuGroupId} className={"accordion-body collapse " + ((activeAccordionItemId === itmgrp.menuGroupId) ? "show" : "")}>
                                        <div className="accordion-inner">
                                            {(itmgrp.subMenuGroup.length > 0) &&
                                                (this.state.getItems.filter(i => i.menuGroupId === itmgrp.subMenuGroup[0].menuGroupId).length > 0) &&
                                                <div className="itm-heading" style={this.state.styleMenuGroup}>
                                                    <p className='description'>
                                                        <SmartText
                                                            text={this.state.getMenus.find(menu => menu.menuGroupId === itmgrp.menuGroupId)?.description.toLowerCase()}
                                                            length={80}
                                                        />
                                                    </p>
                                                </div>
                                            }
                                            {(this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0 && itmgrp.subMenuGroup.length === 0) &&

                                                <div className="itm-heading" style={this.state.styleMenuGroup}>
                                                    <p className='description'>
                                                        <SmartText
                                                            text={this.state.getMenus.find(menu => menu.menuGroupId === itmgrp.menuGroupId)?.description.toLowerCase()}
                                                            length={100}
                                                        />
                                                    </p>
                                                </div>
                                            }
                                            {itmgrp.subMenuGroup.length > 0 &&
                                                <div className="accordion" id="accordion2">
                                                    {
                                                        itmgrp.subMenuGroup.map(sgrp =>
                                                            < >
                                                                {this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length > 0 &&
                                                                    <div className="accordion-group" key={"acc-grp-" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                                        <div className="accordion-heading">
                                                                            <div className='panel-heading'>
                                                                                <h4 className="panel-title">
                                                                                    <a className="accordion-toggle expand collapsed" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                                                        <p style={{ marginBottom: "-4px", fontSize: "16px" }} >
                                                                                            {outletDetails.showMenuGroupImage && (
                                                                                                <>
                                                                                                    {sgrp.imageUrl === "" ?
                                                                                                        <img style={{ borderRadius: "11px", marginRight: "6px" }} width="47" height="47" alt="recommended" src={defaultimg} />
                                                                                                        :
                                                                                                        <img style={{ borderRadius: "11px", marginRight: "6px" }} src={sgrp.imageUrl} alt="submenu" width="47" height="47" />
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                            {sgrp.menuGroupName.toLowerCase() + " (" + this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length + ")"}
                                                                                        </p>
                                                                                    </a>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                        <div id={"subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId} className="accordion-body collapse">
                                                                            <div className="accordion-inner">
                                                                                <div className="wrap-list-rc regular-item">
                                                                                    {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                                                    {
                                                                                        this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).map(itm =>
                                                                                            <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + sgrp.menuGroupId + "-" + itm.itemId}>
                                                                                                <div className="lrc-content">
                                                                                                    {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                                                                    {itm.newItem && itm.bestSellerItem ? (
                                                                                                        <>
                                                                                                            <span className="ribbon">
                                                                                                                <span className="txt-new">NEW</span>
                                                                                                                <span className="txt-bestseller">Bestseller</span>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                                                                        <span className="ribbon">
                                                                                                            <span className="txt-new">NEW</span> </span>
                                                                                                    ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                                                                        <span className="ribbon">
                                                                                                            <span className="txt-bestseller">Bestseller</span> </span>
                                                                                                    ) : null}
                                                                                                    <div className="lrc-desc">
                                                                                                        <div style={{ padding: "2px" }} className={"qty-detail item-entry-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                                                            <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                                            <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                                            <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                                        </div>
                                                                                                        {/* submenu add button */}
                                                                                                        {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                                                                                            <div className={"qty-detail item-add-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                <span style={{ marginTop: "-4px" }} className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                                                            </div>}
                                                                                                        {outletDetails.enableGuestAppOrders === false && (
                                                                                                            itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                                                                // If both conditions are true
                                                                                                                !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                                                                <div className={"qty-detail item-add-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                    <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                        customisable
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )
                                                                                                        )}
                                                                                                        {/*  end*/}

                                                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                            <span className="soldout">SOLD OUT</span>
                                                                                                        </div>}
                                                                                                        {
                                                                                                            (itm.modifierGroupIds !== null &&
                                                                                                                // submenu groups customisable margin top change by windowsinnerwidth changed by dasprakash on 18-08-2023
                                                                                                                itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) &&
                                                                                                            // <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "relative", right: "-120px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "108px" : (window.innerWidth >= 420 ? "18px" : "18px")), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>
                                                                                                            <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'}
                                                                                                                style={{
                                                                                                                    float: "right",
                                                                                                                    position: "absolute",
                                                                                                                    right: "1px",
                                                                                                                    marginTop: (itm.imageUrl !== null && itm.imageUrl !== "")
                                                                                                                        ? (window.innerWidth >= 420 ? "108px" : "102px") // Adjusted marginTop for mobile mode
                                                                                                                        : (window.innerWidth >= 420 ? "18px" : "18px"),
                                                                                                                    fontSize: "13px",
                                                                                                                    width: "130px",
                                                                                                                    textAlign: "center",
                                                                                                                    color: "#7e808c"
                                                                                                                }}>
                                                                                                                <div className="modifier-notify">customisable</div>
                                                                                                            </div>

                                                                                                        }

                                                                                                        {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                                                            <div className={"itm-img " + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", itmgrp.menuGroupId)} >
                                                                                                                {itm.outOfStock ? (
                                                                                                                    <LazyLoadImage
                                                                                                                        alt="item"
                                                                                                                        className="item-imgs"
                                                                                                                        effect="blur"
                                                                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                                                        scrollPosition={scrollPosition}
                                                                                                                        src={itm.imageUrl}
                                                                                                                        delayTime={10000}
                                                                                                                        threshold={100}
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <LazyLoadImage
                                                                                                                        alt="item"
                                                                                                                        className="item-img"
                                                                                                                        effect="blur"
                                                                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                                                        scrollPosition={scrollPosition}
                                                                                                                        src={itm.imageUrl}
                                                                                                                        delayTime={10000}
                                                                                                                        threshold={100}
                                                                                                                    />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        }
                                                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                                                            <div className='tag-soldout-txt'>
                                                                                                                <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                                                                    marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                                                                    marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                                                                }}>
                                                                                                                    SOLD OUT
                                                                                                                </span>

                                                                                                            </div>
                                                                                                        )}

                                                                                                        <div className="lrc-title" style={this.state.styleMenuItem}>
                                                                                                            <app-item-type>
                                                                                                                <div className="image-type">
                                                                                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                                                </div>
                                                                                                            </app-item-type>
                                                                                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                                                                        </div>
                                                                                                        <div className="lrc-button">
                                                                                                            <div className="lrcb-left">
                                                                                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                                                    <Currency />
                                                                                                                    {itm.mrp}
                                                                                                                </span>                                                                                                    </div>
                                                                                                            <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", itmgrp.menuGroupId)} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                                                                                <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                                                                    { /* Media Query for Desktop Mode */}
                                                                                                                    <span className="desktop-description">
                                                                                                                        {itm.description.trim().length > 55
                                                                                                                            ? (
                                                                                                                                <>
                                                                                                                                    {itm.description.slice(0, 55) + "..."}
                                                                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                            : itm.description.trim()}
                                                                                                                    </span>
                                                                                                                    { /* Media Query for Mobile Mode */}
                                                                                                                    <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                                                                        {itm.imageUrl ? // If imageUrl is true
                                                                                                                            (itm.description.trim().length > 18
                                                                                                                                ? (
                                                                                                                                    <>
                                                                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                                : itm.description.trim())
                                                                                                                            : // If imageUrl is false/null/empty
                                                                                                                            (itm.description.trim().length > 18
                                                                                                                                ? (
                                                                                                                                    <>
                                                                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                                : itm.description.trim())
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </p>

                                                                                                            </div>
                                                                                                            {this.handleRibbonList(itm)}
                                                                                                            <div className="clear"></div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) // End Item

                                                                                    }
                                                                                </div>
                                                                                {/* Third Level Menugroup - Start */}
                                                                                {sgrp.subMenuGroup.length > 0 &&
                                                                                    <>
                                                                                        {sgrp.subMenuGroup.map(ssgrp =>
                                                                                            <div className="accordion-group" key={"acc-grp-" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                                                <div className="accordion-heading" style={this.state.styleMenuGroup}>
                                                                                                    <a className="accordion-toggle expand collapsed" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                                                        <p style={{ marginBottom: "-4px" }}> {ssgrp.menuGroupName.toLowerCase() + " (" + this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).length + ")"}</p>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div id={"subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId} className="accordion-body collapse">
                                                                                                    <div className="accordion-inner">
                                                                                                        <div className="wrap-list-rc regular-item">
                                                                                                            {
                                                                                                                this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).map(itm =>

                                                                                                                    <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + ssgrp.menuGroupId + "-" + itm.itemId}>
                                                                                                                        <div className="lrc-content">
                                                                                                                            {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                                                                                            {itm.newItem && itm.bestSellerItem ? (
                                                                                                                                <>
                                                                                                                                    <span className="ribbon">
                                                                                                                                    <span className="txt-new">NEW</span>
                                                                                                                                    <span className="txt-bestseller">Bestseller</span>

                                                                                                                                    </span>
                                                                                                                                </>
                                                                                                                            ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                                                                                                <span className="ribbon">
                                                                                                                                    <span className="txt-new">NEW</span> 
                                                                                                                                    </span>
                                                                                                                            ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                                                                                                <span className="ribbon">
                                                                                                                                    <span className="txt-bestseller">Bestseller</span> </span>
                                                                                                                            ) : null}

                                                                                                                            <div className="lrc-desc">
                                                                                                                                <div style={{ padding: "4px" }} className={"qty-detail item-entry-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                                                                                    <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                                                                    <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                                                                    <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                                                                </div>

                                                                                                                                {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                                </div>}

                                                                                                                                {outletDetails.enableGuestAppOrders === false && (
                                                                                                                                    itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                                                                                        // If both conditions are true
                                                                                                                                        !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                                                                                        <div className={"qty-detail item-add-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                                            <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                                                Customisable
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                    ) : (

                                                                                                                                        (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                                                                                            <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                                                                                            </div>
                                                                                                                                        ) : (
                                                                                                                                            <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>  </div>
                                                                                                                                        )
                                                                                                                                    )
                                                                                                                                )}

                                                                                                                                {/*  */}


                                                                                                                                {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + sgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                                                    <span className="soldout">SOLD OUT</span>
                                                                                                                                </div>}
                                                                                                                                {

                                                                                                                                    (itm.modifierGroupIds !== null &&
                                                                                                                                        itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={(itm.imageUrl !== null && itm.imageUrl !== "") ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "162px" : "46px"), fontSize: "13px", width: "116px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>
                                                                                                                                }
                                                                                                                                {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                                                                                    <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")}>
                                                                                                                                        {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}
                                                                                                                                        <LazyLoadImage
                                                                                                                                            alt={"item"}
                                                                                                                                            className="item-img"
                                                                                                                                            effect="blur"
                                                                                                                                            placeholderSrc={"/img/no-image/itm-df-img.png"}
                                                                                                                                            scrollPosition={scrollPosition}
                                                                                                                                            src={itm.imageUrl}
                                                                                                                                            delayTime={10000}
                                                                                                                                            threshold={100}
                                                                                                                                        />
                                                                                                                                    </div>}
                                                                                                                                {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                                                                                                {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                                                                                    <div className='tag-soldout-txt'>
                                                                                                                                        <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                                                                                            marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                                                                                            marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                                                                                        }}>
                                                                                                                                            SOLD OUT
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                )}

                                                                                                                                <div className="lrc-title" style={this.state.styleMenuItem}>

                                                                                                                                    <app-item-type>
                                                                                                                                        <div className="image-type">
                                                                                                                                            {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                                                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                                                                        </div>
                                                                                                                                    </app-item-type>



                                                                                                                                    <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                                                                                                </div>
                                                                                                                                <div className="lrc-button">
                                                                                                                                    <div className="lrcb-left">
                                                                                                                                        <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                                                                            <Currency />
                                                                                                                                            {itm.mrp}
                                                                                                                                        </span>                                                                                                                            </div>

                                                                                                                                    <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>

                                                                                                                                        <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                                                                                            { /* Media Query for Desktop Mode */}
                                                                                                                                            <span className="desktop-description">
                                                                                                                                                {itm.description.trim().length > 55
                                                                                                                                                    ? (
                                                                                                                                                        <>
                                                                                                                                                            {itm.description.slice(0, 55) + "..."}
                                                                                                                                                            <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                    : itm.description.trim()}
                                                                                                                                            </span>
                                                                                                                                            { /* Media Query for Mobile Mode */}
                                                                                                                                            <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                                                                                                {itm.imageUrl ? // If imageUrl is true
                                                                                                                                                    (itm.description.trim().length > 18
                                                                                                                                                        ? (
                                                                                                                                                            <>
                                                                                                                                                                {itm.description.slice(0, 18) + "..."}
                                                                                                                                                                <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                        : itm.description.trim())
                                                                                                                                                    : // If imageUrl is false/null/empty
                                                                                                                                                    (itm.description.trim().length > 18
                                                                                                                                                        ? (
                                                                                                                                                            <>
                                                                                                                                                                {itm.description.slice(0, 18) + "..."}
                                                                                                                                                                <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                        : itm.description.trim())
                                                                                                                                                }
                                                                                                                                            </span>
                                                                                                                                        </p>

                                                                                                                                    </div>
                                                                                                                                    {this.handleRibbonList(itm)}
                                                                                                                                    <div className="clear"></div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) // End Item

                                                                                                            }
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {/* Third Level Menugroup - End */}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </>

                                                        )
                                                    }
                                                </div>

                                            }
                                            {(itmgrp.subMenuGroup.length === 0 && this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0) &&

                                                <div className="wrap-list-rc regular-item">
                                                    {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                    {/* beve */}
                                                    {
                                                        this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                                            <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                                                <div className="lrc-content">
                                                                    {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                                    {itm.newItem && itm.bestSellerItem ? (
                                                                        <>
                                                                            <span className="ribbon">
                                                                            <span className="txt-new">NEW</span>
                                                                            <span className="txt-bestseller">Bestseller</span>

                                                                            </span>
                                                                        </>
                                                                    ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                                        <span className="ribbon">
                                                                           <span className="txt-new">NEW</span>
                                                                            </span>
                                                                    ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                                        <span className="ribbon">
                                                                            <span className="txt-bestseller">Bestseller</span> </span>
                                                                    ) : null}

                                                                    <div className="lrc-desc">
                                                                        <div style={{ padding: "1px" }} className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                            <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                            <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                            <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                        </div>

                                                                        {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                            <span style={{ padding: "-1px" }} className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                        </div>}

                                                                        {outletDetails.enableGuestAppOrders === false && (
                                                                            itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                                // If both conditions are true
                                                                                !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                                <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                    <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                        Customisable
                                                                                    </span>
                                                                                </div>
                                                                            ) : (

                                                                                (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>  </div>
                                                                                )
                                                                            )
                                                                        )}

                                                                        {/*  */}

                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                            <span className="soldout">SOLD OUT</span>
                                                                        </div>}

                                                                        {(itm.modifierGroupIds !== null &&
                                                                            // rice and breads customisable margin top change by windowsinnerwidth changed by dasprakash on 18-08-2023 jira-1302 2
                                                                            itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={(itm.imageUrl !== null && itm.imageUrl !== "") === null ? 'desktop-modifier-no-img-items' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? (window.innerWidth >= 420 ? "108px" : "102px") : (window.innerWidth >= 420 ? "18px" : "18px")), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>}
                                                                        {(itm.imageUrl !== null && itm.imageUrl !== "") &&

                                                                            <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")}>
                                                                                {itm.outOfStock ? (
                                                                                    <LazyLoadImage
                                                                                        alt="item"
                                                                                        className="item-imgs"
                                                                                        effect="blur"
                                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                        scrollPosition={scrollPosition}
                                                                                        src={itm.imageUrl}
                                                                                        delayTime={10000}
                                                                                        threshold={100}
                                                                                    />
                                                                                ) : (
                                                                                    <LazyLoadImage
                                                                                        alt="item"
                                                                                        className="item-img"
                                                                                        effect="blur"
                                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                        scrollPosition={scrollPosition}
                                                                                        src={itm.imageUrl}
                                                                                        delayTime={10000}
                                                                                        threshold={100}
                                                                                    />
                                                                                )}

                                                                            </div>}
                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                            <div className='tag-soldout-txt'>
                                                                                <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                                    marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                                    marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                                }}>
                                                                                    SOLD OUT
                                                                                </span>

                                                                            </div>
                                                                        )}

                                                                        <div className="lrc-title" style={this.state.styleMenuItem}>
                                                                            <app-item-type>
                                                                                <div className="image-type">
                                                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                </div>
                                                                            </app-item-type>
                                                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                                        </div>


                                                                        {/* menugroup items price and des position change by dasprakash on 17-08-2023 jira-1302 */}
                                                                        <div className="lrc-button">
                                                                            <div className="lrcb-left">
                                                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                    <Currency />
                                                                                    {itm.mrp}
                                                                                </span>
                                                                            </div>

                                                                            <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                                                <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                                    <span className="desktop-description">
                                                                                        {itm.description.length > 55
                                                                                            ? (
                                                                                                <>
                                                                                                    {itm.description.slice(0, 55) + "..."}
                                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                </>
                                                                                            )
                                                                                            : itm.description.trim()}
                                                                                    </span>
                                                                                    { /* Media Query for Mobile Mode */}
                                                                                    <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                                        {itm.imageUrl ? // If imageUrl is true
                                                                                            (itm.description.trim().length > 18
                                                                                                ? (
                                                                                                    <>
                                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                    </>
                                                                                                )
                                                                                                : itm.description.trim())
                                                                                            : // If imageUrl is false/null/empty
                                                                                            (itm.description.trim().length > 18
                                                                                                ? (
                                                                                                    <>
                                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                    </>
                                                                                                )
                                                                                                : itm.description.trim())
                                                                                        }
                                                                                    </span>
                                                                                </p>

                                                                            </div>
                                                                            {this.handleRibbonList(itm)}
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) // End Item

                                                    }
                                                </div>
                                            }
                                            {/* Menu Item with submeu group */}
                                            {(itmgrp.subMenuGroup.length > 0 && this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0) &&

                                                <div className="wrap-list-rc regular-item">
                                                    {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                    {
                                                        this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                                            <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                                                <div className="lrc-content">
                                                                    {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                                    {itm.newItem && itm.bestSellerItem ? (
                                                                        <>
                                                                            <span className="ribbon">
                                                                            <span className="txt-new">NEW</span>
                                                                            <span className="txt-bestseller">Bestseller</span>

                                                                            </span>
                                                                        </>
                                                                    ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                                        <span className="ribbon">
                                                                            <span className="txt-new">NEW</span> </span>
                                                                    ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                                        <span className="ribbon">
                                                                            <span className="txt-bestseller">Bestseller</span> </span>
                                                                    ) : null}
                                                                    <div className="lrc-desc">
                                                                        <div style={{ padding: "1px" }} className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                            <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                            <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                            <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                        </div>

                                                                        {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                            <span style={{ marginTop: "-4px" }} className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                        </div>}

                                                                        {outletDetails.enableGuestAppOrders === false && (
                                                                            itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                                // If both conditions are true
                                                                                !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                                <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                    <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                        customisable
                                                                                    </span>
                                                                                </div>
                                                                            ) : (

                                                                                (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        )}

                                                                        {/*  */}

                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                            <span className="soldout">SOLD OUT</span>
                                                                        </div>}
                                                                        {(itm.modifierGroupIds !== null &&
                                                                            // starters menuitems customisable margin top by windowsinnerwidth  change by dasprakash on 18-08-2023 jira-1320 1
                                                                            itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? (window.innerWidth >= 420 ? "108px" : "102px") : (window.innerWidth >= 420 ? "18px" : "18px")), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>}
                                                                        {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                            <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")}>

                                                                                {itm.outOfStock ? (
                                                                                    <LazyLoadImage
                                                                                        alt="item"
                                                                                        className="item-imgs"
                                                                                        effect="blur"
                                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                        scrollPosition={scrollPosition}
                                                                                        src={itm.imageUrl}
                                                                                        delayTime={10000}
                                                                                        threshold={100}
                                                                                    />
                                                                                ) : (
                                                                                    <LazyLoadImage
                                                                                        alt="item"
                                                                                        className="item-img"
                                                                                        effect="blur"
                                                                                        placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                        scrollPosition={scrollPosition}
                                                                                        src={itm.imageUrl}
                                                                                        delayTime={10000}
                                                                                        threshold={100}
                                                                                    />
                                                                                )}
                                                                            </div>}
                                                                        {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                                        {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                            <div className='tag-soldout-txt'>
                                                                                <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                                    marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                                    marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                                }}>
                                                                                    SOLD OUT
                                                                                </span>

                                                                            </div>
                                                                        )}

                                                                        <div className="lrc-title" style={this.state.styleMenuItem}>

                                                                            <app-item-type>
                                                                                <div className="image-type">
                                                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                </div>
                                                                            </app-item-type>
                                                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                                        </div>


                                                                        {/* menuitems in menugroup which have submenu groups and also menuitems price and des position change by dasprakash on 17-08-2023 jira-1302  */}
                                                                        <div className="lrc-button">
                                                                            <div className="lrcb-left">
                                                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                    <Currency />
                                                                                    {itm.mrp}
                                                                                </span>
                                                                            </div>
                                                                            <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                                                <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                                    { /* Media Query for Desktop Mode */}
                                                                                    <span className="desktop-description">
                                                                                        {itm.description.trim().length > 55
                                                                                            ? (
                                                                                                <>
                                                                                                    {itm.description.slice(0, 55) + "..."}
                                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                </>
                                                                                            )
                                                                                            : itm.description.trim()}
                                                                                    </span>
                                                                                    { /* Media Query for Mobile Mode */}
                                                                                    <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                                        {itm.imageUrl ? // If imageUrl is true
                                                                                            (itm.description.trim().length > 18
                                                                                                ? (
                                                                                                    <>
                                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                    </>
                                                                                                )
                                                                                                : itm.description.trim())
                                                                                            : // If imageUrl is false/null/empty
                                                                                            (itm.description.trim().length > 18
                                                                                                ? (
                                                                                                    <>
                                                                                                        {itm.description.slice(0, 18) + "..."}
                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                    </>
                                                                                                )
                                                                                                : itm.description.trim())
                                                                                        }
                                                                                    </span>
                                                                                </p>

                                                                            </div>
                                                                            {this.handleRibbonList(itm)}
                                                                            <div className="clear"></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) // End Item
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                )}

            </>
        );
    };
    // Due to collapse based on swithes added by vijay 
    handleGenerateItemListDom() {
        let mnugrp = "mnu-grp-";
        //let recmnu = "mnu-rec-";

        // let itmindex = -1;     
        const { scrollPosition } = this.props;
        // console.log(this.state.getItems.filter (i=> i.newItem),'123');
        // console.log(this.state.getItems.filter(item => item.newItem === true), '123');

        return (
            <>
                {this.state.getMenus.map(itmgrp =>
                    // data-itmgrp added by dasprakash on 21-11-2023 jira-1741
                    <div data-itmgrp={JSON.stringify(itmgrp)} className="content-froms " key={mnugrp + itmgrp.menuGroupId} id={"focus-mnugrp-" + itmgrp.menuGroupId} >
                        {(itmgrp.subMenuGroup.length > 0) &&
                            (this.state.getItems.filter(i => i.menuGroupId === itmgrp.subMenuGroup[0].menuGroupId).length > 0) &&
                            <div className="itm-heading" style={this.state.styleMenuGroup}>
                                {itmgrp.menuGroupName.toLowerCase()}   ({itmgrp.noOfItems})
                                {/* description added on submenu groups and classname description added by dasprakash on 19-08-2023 jira-1302 */}
                                <p className='description'>
                                    <SmartText
                                        text={this.state.getMenus.find(menu => menu.menuGroupId === itmgrp.menuGroupId)?.description.toLowerCase()}
                                        length={80}
                                    />
                                </p>
                            </div>
                        }

                        {(this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0 && itmgrp.subMenuGroup.length === 0) &&

                            <div className="itm-heading" style={this.state.styleMenuGroup}>
                                {/* {itmgrp.menuGroupName.toLowerCase()} ({this.state.getItems.length}) */}
                                {/* Added by vijay on 10-01-2024  noOfItems menugroupitemwise */}
                                {itmgrp.menuGroupName.toLowerCase()}   ({itmgrp.noOfItems})
                                {/* description added on submenu groups and classname description added by dasprakash on 19-08-2023 jira-1302 */}

                                <p className='description'>
                                    <SmartText
                                        text={this.state.getMenus.find(menu => menu.menuGroupId === itmgrp.menuGroupId)?.description.toLowerCase()}
                                        length={100}
                                    />
                                </p>

                            </div>
                        }
                        {itmgrp.subMenuGroup.length > 0 &&
                            <div className="accordion" id="accordion2">
                                {
                                    itmgrp.subMenuGroup.map(sgrp =>
                                        < >
                                            {this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length > 0 &&
                                                <div className="accordion-group" key={"acc-grp-" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                    <div className="accordion-heading">
                                                        <div className='panel-heading'>
                                                            <h4 className="panel-title">
                                                                <a className="accordion-toggle expand collapsed" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                                    <p style={{ marginBottom: "-4px", fontSize: "16px" }} >
                                                                        {/* image added by dasprakash on 19-08-2023 jira-1302 */}
                                                                        {/* Condition Added by vijay on 12-01-2024  JIra id -2035 */}
                                                                        {outletDetails.showMenuGroupImage && (
                                                                            <>
                                                                                {sgrp.imageUrl === "" ?
                                                                                    <img style={{ borderRadius: "11px", marginRight: "6px" }} width="47" height="47" alt="recommended" src={defaultimg} />
                                                                                    :
                                                                                    <img style={{ borderRadius: "11px", marginRight: "6px" }} src={sgrp.imageUrl} alt="submenu" width="47" height="47" />
                                                                                }
                                                                            </>
                                                                        )}
                                                                        {sgrp.menuGroupName.toLowerCase() + " (" + this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length + ")"}
                                                                    </p>
                                                                    {/* <div className="itm-cnt">{this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length + " items..."} </div> */}
                                                                </a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div id={"subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId} className="accordion-body collapse">
                                                        <div className="accordion-inner">
                                                            <div className="wrap-list-rc regular-item">
                                                                {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}

                                                                {
                                                                    this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).map(itm =>
                                                                        <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + sgrp.menuGroupId + "-" + itm.itemId}>
                                                                            <div className="lrc-content">
                                                                                {/* <h3>hello world {itm.newItem}</h3> */}
                                                                                {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                                                {itm.newItem && itm.bestSellerItem ? (
                                                                                    <>
                                                                                        <span className="ribbon">
                                                                                        <span className="txt-new">NEW</span>
                                                                                        <span className="txt-bestseller">Bestseller</span>
                                                                                        </span>
                                                                                    </>
                                                                                ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                                                    <span className="ribbon">
                                                                                        <span className="txt-new">NEW</span> </span>
                                                                                ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                                                    <span className="ribbon">
                                                                                        <span className="txt-bestseller">Bestseller</span> </span>
                                                                                ) : null}
                                                                                <div className="lrc-desc">
                                                                                    <div style={{ padding: "2px" }} className={"qty-detail item-entry-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                    </div>
                                                                                    {/* submenu add button */}
                                                                                    {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                                                                        <div className={"qty-detail item-add-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                            <span style={{ marginTop: "-4px" }} className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                                        </div>}
                                                                                    {outletDetails.enableGuestAppOrders === false && (
                                                                                        itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                                            // If both conditions are true
                                                                                            !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                                            <div className={"qty-detail item-add-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                    customisable
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : (
                                                                                            // If the first condition is true but the second one is false
                                                                                            // Condition Added by vijay on 21-03-2024
                                                                                            // <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                            (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                                                <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                </div>
                                                                                            )
                                                                                        )
                                                                                    )}
                                                                                    {/*  end*/}

                                                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                        <span className="soldout">SOLD OUT</span>
                                                                                    </div>}
                                                                                    {
                                                                                        (itm.modifierGroupIds !== null &&
                                                                                            // submenu groups customisable margin top change by windowsinnerwidth changed by dasprakash on 18-08-2023
                                                                                            itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) &&
                                                                                        // <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "relative", right: "-120px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "108px" : (window.innerWidth >= 420 ? "18px" : "18px")), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>
                                                                                        <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'}
                                                                                            style={{
                                                                                                float: "right",
                                                                                                position: "absolute",
                                                                                                right: "1px",
                                                                                                marginTop: (itm.imageUrl !== null && itm.imageUrl !== "")
                                                                                                    ? (window.innerWidth >= 420 ? "108px" : "102px") // Adjusted marginTop for mobile mode
                                                                                                    : (window.innerWidth >= 420 ? "18px" : "18px"),
                                                                                                fontSize: "13px",
                                                                                                width: "130px",
                                                                                                textAlign: "center",
                                                                                                color: "#7e808c"
                                                                                            }}>
                                                                                            <div className="modifier-notify">customisable</div>
                                                                                        </div>

                                                                                    }

                                                                                    {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                                        <div className={"itm-img " + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", itmgrp.menuGroupId)} >
                                                                                            {/* <img className='item-img' src={itm.imageUrl} loading="lazy" />                            onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-")} */}
                                                                                            {/* added function to blure the out of stock img in submenu group page by dasprakash on 18-08-2023 jira-1302 */}
                                                                                            {itm.outOfStock ? (
                                                                                                <LazyLoadImage
                                                                                                    alt="item"
                                                                                                    className="item-imgs"
                                                                                                    effect="blur"
                                                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                                    scrollPosition={scrollPosition}
                                                                                                    src={itm.imageUrl}
                                                                                                    delayTime={10000}
                                                                                                    threshold={100}
                                                                                                />
                                                                                            ) : (
                                                                                                <LazyLoadImage
                                                                                                    alt="item"
                                                                                                    className="item-img"
                                                                                                    effect="blur"
                                                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                                    scrollPosition={scrollPosition}
                                                                                                    src={itm.imageUrl}
                                                                                                    delayTime={10000}
                                                                                                    threshold={100}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    }
                                                                                    {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                                                    {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                                        <div className='tag-soldout-txt'>
                                                                                            <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                                                marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                                                marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                                            }}>
                                                                                                SOLD OUT
                                                                                            </span>

                                                                                        </div>
                                                                                    )}

                                                                                    <div className="lrc-title" style={this.state.styleMenuItem}>

                                                                                        <app-item-type>
                                                                                            <div className="image-type">
                                                                                                {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                                {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                            </div>
                                                                                        </app-item-type>
                                                                                        <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                                                        {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <span className="soldout-txt">SOLD OUT</span>} */}
                                                                                    </div>

                                                                                    {/* submenu group price and des position change by dasprakash jira-1302 */}
                                                                                    <div className="lrc-button">
                                                                                        <div className="lrcb-left">
                                                                                            <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                                <Currency />
                                                                                                {itm.mrp}
                                                                                            </span>                                                                                                    </div>

                                                                                        {/* handleSetPopupImage function for image popup by dasprakash on 22-08-2023 jira-1302 */}
                                                                                        <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", itmgrp.menuGroupId)} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>

                                                                                            <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                                                { /* Media Query for Desktop Mode */}
                                                                                                <span className="desktop-description">
                                                                                                    {itm.description.trim().length > 55
                                                                                                        ? (
                                                                                                            <>
                                                                                                                {itm.description.slice(0, 55) + "..."}
                                                                                                                <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                            </>
                                                                                                        )
                                                                                                        : itm.description.trim()}
                                                                                                </span>
                                                                                                { /* Media Query for Mobile Mode */}
                                                                                                <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                                                    {itm.imageUrl ? // If imageUrl is true
                                                                                                        (itm.description.trim().length > 18
                                                                                                            ? (
                                                                                                                <>
                                                                                                                    {itm.description.slice(0, 18) + "..."}
                                                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                </>
                                                                                                            )
                                                                                                            : itm.description.trim())
                                                                                                        : // If imageUrl is false/null/empty
                                                                                                        (itm.description.trim().length > 18
                                                                                                            ? (
                                                                                                                <>
                                                                                                                    {itm.description.slice(0, 18) + "..."}
                                                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                </>
                                                                                                            )
                                                                                                            : itm.description.trim())
                                                                                                    }
                                                                                                </span>
                                                                                            </p>

                                                                                        </div>
                                                                                        {this.handleRibbonList(itm)}
                                                                                        <div className="clear"></div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) // End Item

                                                                }
                                                            </div>
                                                            {/* Third Level Menugroup - Start */}
                                                            {sgrp.subMenuGroup.length > 0 &&
                                                                <>
                                                                    {sgrp.subMenuGroup.map(ssgrp =>
                                                                        <div className="accordion-group" key={"acc-grp-" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                            <div className="accordion-heading" style={this.state.styleMenuGroup}>
                                                                                {/* <a className="accordion-toggle" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                            {ssgrp.menuGroupName.toLowerCase()}                                                                                           
                                                                            <div className="itm-cnt">{this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).length + " items..."} </div>
                                                                        </a> */}
                                                                                <a className="accordion-toggle expand collapsed" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                                    <p style={{ marginBottom: "-4px" }}> {ssgrp.menuGroupName.toLowerCase() + " (" + this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).length + ")"}</p>
                                                                                    {/* <div className="itm-cnt">{this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length + " items..."} </div> */}
                                                                                </a>
                                                                            </div>
                                                                            <div id={"subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId} className="accordion-body collapse">
                                                                                <div className="accordion-inner">
                                                                                    <div className="wrap-list-rc regular-item">
                                                                                        {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                                                        {
                                                                                            this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).map(itm =>

                                                                                                <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + ssgrp.menuGroupId + "-" + itm.itemId}>
                                                                                                    <div className="lrc-content">
                                                                                                        {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                                                                        {itm.newItem && itm.bestSellerItem ? (
                                                                                                            <>
                                                                                                                <span className="ribbon">
                                                                                                                <span className="txt-new">NEW</span>
                                                                                                                <span className="txt-bestseller">Bestseller</span>

                                                                                                                </span>
                                                                                                            </>
                                                                                                        ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                                                                            <span className="ribbon">
                                                                                                                <span className="txt-new">NEW</span> </span>
                                                                                                        ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                                                                            <span className="ribbon">
                                                                                                                <span className="txt-bestseller">Bestseller</span> </span>
                                                                                                        ) : null}
                                                                                                        <div className="lrc-desc">
                                                                                                            <div style={{ padding: "4px" }} className={"qty-detail item-entry-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                                                                <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                                                <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                                                <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                                            </div>

                                                                                                            {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                {/* <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span> */}
                                                                                                            </div>}


                                                                                                            {outletDetails.enableGuestAppOrders === false && (
                                                                                                                itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                                                                                    // If both conditions are true
                                                                                                                    !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                                                                                    <div className={"qty-detail item-add-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                        <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                                            Customisable
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    // If the first condition is true but the second one is false
                                                                                                                    // Condition Added by vijay on 21-03-2024
                                                                                                                    // <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                                    (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                                                                        <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>  </div>
                                                                                                                    )
                                                                                                                )
                                                                                                            )}

                                                                                                            {/*  */}


                                                                                                            {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + sgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                                <span className="soldout">SOLD OUT</span>
                                                                                                            </div>}
                                                                                                            {

                                                                                                                (itm.modifierGroupIds !== null &&
                                                                                                                    itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={(itm.imageUrl !== null && itm.imageUrl !== "") ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "162px" : "46px"), fontSize: "13px", width: "116px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>
                                                                                                            }
                                                                                                            {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                                                                <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")}>
                                                                                                                    {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}
                                                                                                                    <LazyLoadImage
                                                                                                                        alt={"item"}
                                                                                                                        className="item-img"
                                                                                                                        effect="blur"
                                                                                                                        placeholderSrc={"/img/no-image/itm-df-img.png"}
                                                                                                                        scrollPosition={scrollPosition}
                                                                                                                        src={itm.imageUrl}
                                                                                                                        delayTime={10000}
                                                                                                                        threshold={100}
                                                                                                                    />
                                                                                                                </div>}
                                                                                                            {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                                                                            {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                                                                <div className='tag-soldout-txt'>
                                                                                                                    <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                                                                        marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                                                                        marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                                                                                    }}>
                                                                                                                        SOLD OUT
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            )}

                                                                                                            <div className="lrc-title" style={this.state.styleMenuItem}>
                                                                                                                {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                                        <span className="itm-title">{itm.itemName}</span> */}
                                                                                                                <app-item-type>
                                                                                                                    <div className="image-type">
                                                                                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                                                    </div>
                                                                                                                </app-item-type>
                                                                                                                <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>

                                                                                                                {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <span className="soldout-txt">SOLD OUT</span>} */}
                                                                                                            </div>


                                                                                                            {/* not known */}
                                                                                                            <div className="lrc-button">
                                                                                                                <div className="lrcb-left">
                                                                                                                    <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                                                        <Currency />
                                                                                                                        {itm.mrp}
                                                                                                                    </span>                                                                                                                            </div>

                                                                                                                {/* handleSetPopupImage function for image popup by dasprakash on 22-08-2023 jira-1302 */}
                                                                                                                <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>

                                                                                                                    <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                                                                        { /* Media Query for Desktop Mode */}
                                                                                                                        <span className="desktop-description">
                                                                                                                            {itm.description.trim().length > 55
                                                                                                                                ? (
                                                                                                                                    <>
                                                                                                                                        {itm.description.slice(0, 55) + "..."}
                                                                                                                                        <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                                : itm.description.trim()}
                                                                                                                        </span>
                                                                                                                        { /* Media Query for Mobile Mode */}
                                                                                                                        <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                                                                            {itm.imageUrl ? // If imageUrl is true
                                                                                                                                (itm.description.trim().length > 18
                                                                                                                                    ? (
                                                                                                                                        <>
                                                                                                                                            {itm.description.slice(0, 18) + "..."}
                                                                                                                                            <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                    : itm.description.trim())
                                                                                                                                : // If imageUrl is false/null/empty
                                                                                                                                (itm.description.trim().length > 18
                                                                                                                                    ? (
                                                                                                                                        <>
                                                                                                                                            {itm.description.slice(0, 18) + "..."}
                                                                                                                                            <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                    : itm.description.trim())
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                                {this.handleRibbonList(itm)}
                                                                                                                <div className="clear"></div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) // End Item

                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    }
                                                                </>
                                                            }
                                                            {/* Third Level Menugroup - End */}
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </>

                                    )
                                }
                            </div>

                        }
                        {(itmgrp.subMenuGroup.length === 0 && this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0) &&

                            <div className="wrap-list-rc regular-item">

                                {
                                    this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                        <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                            <div className="lrc-content">
                                                {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                {itm.newItem && itm.bestSellerItem ? (
                                                    <>
                                                        <span className="ribbon">
                                                        <span className="txt-new">NEW</span>
                                                        <span className="txt-bestseller">Bestseller</span>

                                                        </span>
                                                    </>
                                                ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                    <span className="ribbon">
                                                        <span className="txt-new">NEW</span> </span>
                                                ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                    <span className="ribbon">
                                                        <span className="txt-bestseller">Bestseller</span> </span>
                                                ) : null}
                                                <div className="lrc-desc">
                                                    <div style={{ padding: "1px" }} className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div>

                                                    {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                        <span style={{ padding: "-1px" }} className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                    </div>}

                                                    {outletDetails.enableGuestAppOrders === false && (
                                                        itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                            // If both conditions are true
                                                            !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                            <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                    Customisable
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            // If the first condition is true but the second one is false
                                                            // Condition Added by vijay on 21-03-2024
                                                            // <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                            (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                </div>
                                                            ) : (
                                                                <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>  </div>
                                                            )
                                                        )
                                                    )}

                                                    {/*  */}

                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                        <span className="soldout">SOLD OUT</span>
                                                    </div>}

                                                    {(itm.modifierGroupIds !== null &&
                                                        // rice and breads customisable margin top change by windowsinnerwidth changed by dasprakash on 18-08-2023 jira-1302 2
                                                        itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={(itm.imageUrl !== null && itm.imageUrl !== "") === null ? 'desktop-modifier-no-img-items' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? (window.innerWidth >= 420 ? "108px" : "102px") : (window.innerWidth >= 420 ? "18px" : "18px")), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>}
                                                    {(itm.imageUrl !== null && itm.imageUrl !== "") &&

                                                        <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")}>
                                                            {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}
                                                            {/* added function to blure the out of stock img in other all group page by dasprakash on 18-08-2023 jira-1302 */}
                                                            {itm.outOfStock ? (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-imgs"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            ) : (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-img"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            )}

                                                        </div>}
                                                    {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                    {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                        <div className='tag-soldout-txt'>
                                                            <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                            }}>
                                                                SOLD OUT
                                                            </span>

                                                        </div>
                                                    )}

                                                    <div className="lrc-title" style={this.state.styleMenuItem}>

                                                        <app-item-type>
                                                            <div className="image-type">
                                                                {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                            </div>
                                                        </app-item-type>
                                                        <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                    </div>


                                                    {/* menugroup items price and des position change by dasprakash on 17-08-2023 jira-1302 */}
                                                    <div className="lrc-button">
                                                        <div className="lrcb-left">
                                                            <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                <Currency />
                                                                {itm.mrp}
                                                            </span>
                                                        </div>

                                                        <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                            <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                <span className="desktop-description">
                                                                    {itm.description.length > 55
                                                                        ? (
                                                                            <>
                                                                                {itm.description.slice(0, 55) + "..."}
                                                                                <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                            </>
                                                                        )
                                                                        : itm.description.trim()}
                                                                </span>
                                                                { /* Media Query for Mobile Mode */}
                                                                <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                    {itm.imageUrl ? // If imageUrl is true
                                                                        (itm.description.trim().length > 18
                                                                            ? (
                                                                                <>
                                                                                    {itm.description.slice(0, 18) + "..."}
                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                </>
                                                                            )
                                                                            : itm.description.trim())
                                                                        : // If imageUrl is false/null/empty
                                                                        (itm.description.trim().length > 18
                                                                            ? (
                                                                                <>
                                                                                    {itm.description.slice(0, 18) + "..."}
                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                </>
                                                                            )
                                                                            : itm.description.trim())
                                                                    }
                                                                </span>
                                                            </p>

                                                        </div>
                                                        {this.handleRibbonList(itm)}
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) // End Item

                                }
                            </div>
                        }
                        {/* Menu Item with submeu group */}
                        {(itmgrp.subMenuGroup.length > 0 && this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0) &&

                            <div className="wrap-list-rc regular-item">
                                {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                {
                                    this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                        <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                            <div className="lrc-content">
                                                {/* {itm.newItem === true && <span className="ribbon"><span className="txt">NEW</span></span>} */}
                                                {itm.newItem && itm.bestSellerItem ? (
                                                    <>
                                                        <span className="ribbon">
                                                        <span className="txt-new">NEW</span>
                                                        <span className="txt-bestseller">Bestseller</span>

                                                        </span>
                                                    </>
                                                ) : itm.newItem ? ( // Show "New" if only newItem is true
                                                    <span className="ribbon">
                                                        <span className="txt-new">NEW</span> </span>
                                                ) : itm.bestSellerItem ? ( // Show "Bestseller" if only bestSellerItem is true
                                                    <span className="ribbon">
                                                        <span className="txt-bestseller">Bestseller</span> </span>
                                                ) : null}
                                                <div className="lrc-desc">
                                                    <div style={{ padding: "1px" }} className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div>

                                                    {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                        <span style={{ marginTop: "-4px" }} className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                    </div>}
                                                    {outletDetails.enableGuestAppOrders === false && (
                                                        itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                                            // If both conditions are true
                                                            !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) &&
                                                            <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                    customisable
                                                                </span>
                                                            </div>
                                                        ) : (

                                                            (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (
                                                                <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}>
                                                                </div>
                                                            ) : (
                                                                <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                </div>
                                                            )
                                                        )
                                                    )}

                                                    {/*  */}

                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                        <span className="soldout">SOLD OUT</span>
                                                    </div>}
                                                    {(itm.modifierGroupIds !== null &&
                                                        // starters menuitems customisable margin top by windowsinnerwidth  change by dasprakash on 18-08-2023 jira-1320 1
                                                        itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "1px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? (window.innerWidth >= 420 ? "108px" : "102px") : (window.innerWidth >= 420 ? "18px" : "18px")), fontSize: "13px", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>}
                                                    {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                        <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")}>
                                                            {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}

                                                            {/* added function to blure the out of stock img in starters menuitems group page by dasprakash on 18-08-2023 jira-1302 */}
                                                            {itm.outOfStock ? (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-imgs"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            ) : (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-img"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            )}
                                                        </div>}
                                                    {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                    {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                        <div className='tag-soldout-txt'>
                                                            <span className={`soldout-txt ${itm.imageUrl !== null && itm.imageUrl !== "" ? '' : 'no-food-type-no-img'}`} style={{
                                                                marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px',
                                                                marginLeft: (itm.imageUrl !== null && itm.imageUrl !== "" && itm.foodType === 1) ? '-130px' : ''
                                                            }}>
                                                                SOLD OUT
                                                            </span>

                                                        </div>
                                                    )}

                                                    <div className="lrc-title" style={this.state.styleMenuItem}>

                                                        <app-item-type>
                                                            <div className="image-type">
                                                                {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                            </div>
                                                        </app-item-type>
                                                        <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-33px", marginLeft: "28px" }}>{itm.itemName}</span>

                                                    </div>


                                                    {/* menuitems in menugroup which have submenu groups and also menuitems price and des position change by dasprakash on 17-08-2023 jira-1302  */}
                                                    <div className="lrc-button">
                                                        <div className="lrcb-left">
                                                            <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                <Currency />
                                                                {itm.mrp}
                                                            </span>
                                                        </div>

                                                        <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-", "")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                            <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>
                                                                { /* Media Query for Desktop Mode */}
                                                                <span className="desktop-description">
                                                                    {itm.description.trim().length > 55
                                                                        ? (
                                                                            <>
                                                                                {itm.description.slice(0, 55) + "..."}
                                                                                <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                            </>
                                                                        )
                                                                        : itm.description.trim()}
                                                                </span>
                                                                { /* Media Query for Mobile Mode */}
                                                                <span className="mobile-description" style={{ marginLeft: "5px" }}>
                                                                    {itm.imageUrl ? // If imageUrl is true
                                                                        (itm.description.trim().length > 18
                                                                            ? (
                                                                                <>
                                                                                    {itm.description.slice(0, 18) + "..."}
                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                </>
                                                                            )
                                                                            : itm.description.trim())
                                                                        : // If imageUrl is false/null/empty
                                                                        (itm.description.trim().length > 18
                                                                            ? (
                                                                                <>
                                                                                    {itm.description.slice(0, 18) + "..."}
                                                                                    <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                                </>
                                                                            )
                                                                            : itm.description.trim())
                                                                    }
                                                                </span>
                                                            </p>

                                                        </div>
                                                        {this.handleRibbonList(itm)}
                                                        <div className="clear"></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ) // End Item
                                }
                            </div>
                        }

                    </div>

                ) // End Group
                }
            </>
        );
    };

    //Added by vijay on 08-09-2024 - Menugroup Name Having alignment issues if first is going on more that 16 letters
    getMenuGroupNameWidth(menuGroupName) {
        const words = menuGroupName.split(' '); // Split into words            
        let width = 110; // Default width
        if (words[0].length > 16) {
            width += (words[0].length - 16) * 5;
        }
        if (words.length > 1) {
            if (words[1].length > 16) {
                width += (words[1].length - 16) * 5;
            } else {
                width += words[1].length * 5;
            }
        }
        return width;
    }


    render() {
        let showCart = cartList.itemList.length > 0 ? "block" : "none";
        return (

            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="items-top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    {/* <Header /> */}
                    <Header loggedIn={guestLoggedIn}
                        mobileApp={mobileApp}
                        clmenu={isappOrders}
                        pageTitle={pageTitle}
                        // added by dasprakash on 11-11-2023 jira-1744
                        callbackAccessModeHome={this.callbackAccessMode}
                    />
                    <div className="content-warp">

                        <div className="section-items" >
                            <div className="heading-page" ref="recommended" style={{ height: "70px" }} >
                                {/* {
                                    sessionStorage.getItem('dashboard-hide') !== "true" &&

                                    <div className="back-btn">
                                        <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left"></i></div>
                                    </div>
                                } */}
                                {/* <div className="refresh-icon" onClick={this.getUpdateMenus.bind(this)}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div> */}
                                {/* <span style={{ whiteSpace: "pre-wrap", paddingLeft: "10px" }}> {sessionStorage.getItem("user-propertyname")}</span> */}

                                {/* comment by dasprakash 17-08-2023 jira-1302 */}
                                {/* <div style={{ whiteSpace: "pre-wrap", }}>{this.state.pageTitle !== '' ? this.state.pageTitle : "Welcome to " + sessionStorage.getItem("user-outletname")}  </div> */}

                                {/* inline style added and position changed by dasprakash 17-08-2023 jira-1302 */}
                                {outletDetails.enableGuestAppOrders === true || outletDetails.enableGuestAppOrders === false ? (
                                    <div style={{ marginTop: "-15px", height: "0px" }}>
                                        <div className='scroll-hide'>
                                            {/* backgroundColor: '#e0e7eb'removed by dasprakash on 17-08-2023 jira-1302 */}
                                            {sessionStorage.getItem("user-servicetype") === "FINE-DINE" && <div className='tbl-no-group-pin'> <div style={{ padding: "4px", display: "inline", marginRight: "6px" }}> Table No:{sessionStorage.getItem("user-tableno")} </div> </div>}
                                            {/* {groupPin !== '' && <div style={{ backgroundColor: '#e0e7eb', padding: "4px", marginTop: "-9px", display: "inline" }}><i className="fa fa-users"></i> <span> Group Pin: {groupPin}</span></div>} */}
                                            {/* backgroundColor: '#e0e7eb'removed and line added by dasprakash on 17-08-2023 jira-1302 */}


                                            { // group pin added by dasprakash on 28-09-2023 jira-1493
                                                sessionStorage.getItem("user-servicetype") === "FINE-DINE" && tablePins !== '' && (
                                                    tablePins && // Check if tablePins is not null, empty, or undefined
                                                    <div className='tbl-no-group-pin'>
                                                        {/* Uncomment this section if you want to display the table number  */}
                                                        {/* <div style={{ padding: "4px", display: "inline", marginRight: "6px" }}>
                                             Table No: {sessionStorage.getItem("user-tableno")}
                                           </div>  */}
                                                        {/* added by dasprakash on 29-08-2023 jira-1302  */}
                                                        {/* condition Added by vijay on 28-12-2023    Jira id-2075     */}
                                                        {outletDetails.enableGroupOrdering && tablePins &&
                                                            <div style={{ padding: "4px", marginTop: "-9px", display: "inline" }}>
                                                                {/* You can use an icon or text here, depending on your design */}
                                                                <span>Table Pin:{tablePins}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                ) : (
                                    // commented by dasprakash on 05-10-2023 jira-1302
                                    // <div style={{ marginTop: "-7px" }} className='header-content_v2'>
                                    //     {/* level2 change */}
                                    //     <img style={{ height: "60px" }} src={this.state.outletImg} alt='logo' />
                                    //     {/*  */}
                                    // </div>

                                    ""
                                )
                                }

                                {/* <span className="city-txt"> {sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")}</span> */}
                                {/* {guestLoggedIn === "false" && <div className='login-txt' onClick={this.gotoLogin}>Login</div>} */}

                                {(guestLoggedIn === "true" && outletDetails.enableHelp) &&
                                    // Condition Added by vijay on 21-11-2023
                                    (serviceType === "FINE-DINE" && (
                                        <div className='login-txt' onClick={this.goToHelpRequest}>
                                            Help

                                            {/* <i className="fa fa-bell" aria-hidden="true"></i> */}
                                        </div>
                                    ))}
                                <div>
                                </div>
                            </div>

                            {/* position changed by dasprakash 16-08-2023 jira-1302 */}
                            {/* {Search , Refersh,Veg only}   styles Added by vijay on 20-12-2023 Jira id -2035 */}
                            <div className='item-filter' style={this.state.styleItemFilterDiv}>
                                {/* <div className='custom-control custom-switch' style={this.state.styleItemFilterVegDiv}>
                                     <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        id='customSwitches-veg'
                                        checked={this.state.switch1}
                                        onChange={this.handleSwitchChange(1)}
                                        readOnly
                                    />
                                    <label className='custom-control-label' htmlFor='customSwitches-veg'>
                                        <div style={{ marginTop: "2px", }}>Veg Only</div>
                                    </label>
                              
                                </div> */}
                                <div className="checkbox-wrapper">
                                    {/* Veg Checkbox */}
                                    <label className="checkbox-label" htmlFor="customSwitches-veg">
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            id="customSwitches-veg"
                                            checked={this.state.switch1}
                                            onChange={this.handleSwitchChange(1)}
                                        />
                                    </label>

                                    {/* Non-Veg Checkbox */}
                                    <label className="checkbox-label" htmlFor="customSwitches-nonveg">
                                        <input
                                            type="checkbox"
                                            className="custom-checkbox-nonveg"
                                            id="customSwitches-nonveg"
                                            checked={this.state.switch2}
                                            onChange={this.handleSwitchChange(2)}
                                        />
                                    </label>
                                </div>

                                <div className="search-wrapper" style={this.state.styleItemFilterSearchDiv}>
                                    <input id="search" autoComplete="Off" ref={(input) => { this.searchInp = input; }} placeholder="Search..." onClick={this.itemsearch} />
                                    {/* comment by dasprakash 16-08-2023 jira-1302 */}
                                    {/* <button className="ssubmit" type="button" name="search_submit" onClick={this.handleClearText.bind(this)}><i className="fas fa-times"></i></button> */}
                                    {/* icon added by dasprakash on 19-08-2023 jira-13 */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                        <rect x="1" y="1" width="33" height="33" fill="#728EB8" fillOpacity="0.25" />
                                        <rect x="0.75" y="0.75" width="33.5" height="33.5" stroke="#666666" strokeOpacity="0.03" strokeWidth="0.5" />
                                        <path fillRule="evenodd" fill="#454545" clipRule="evenodd" d="M22.6288 21.4783C22.6089 21.4586 22.6058 21.4271 22.6228 21.4048C23.6111 20.1101 24.0757 18.4899 23.9225 16.8671C23.7678 15.2277 22.9944 13.7089 21.7595 12.6195C20.5247 11.5302 18.9213 10.9522 17.2754 11.0031C15.6295 11.054 14.0649 11.7301 12.8998 12.8937C11.7336 14.0581 11.0554 15.6233 11.0032 17.2705C10.9511 18.9176 11.5290 20.5226 12.6191 21.7584C13.7092 22.9943 15.2296 23.7680 16.8703 23.9218C18.4944 24.0740 20.1153 23.6077 21.4097 22.6171C21.4324 22.5997 21.4646 22.6020 21.4844 22.6227L24.9176 26.0567C24.9928 26.1319 25.0821 26.1916 25.1803 26.2323C25.2786 26.2730 25.3839 26.2939 25.4902 26.2939C25.5966 26.2939 25.7019 26.2730 25.8001 26.2323C25.8983 26.1916 25.9876 26.1319 26.0628 26.0567C26.1380 25.9815 26.1977 25.8923 26.2384 25.7940C26.2790 25.6958 26.3000 25.5905 26.3000 25.4841C26.3000 25.3778 26.2790 25.2725 26.2384 25.1742C26.1977 25.0760 26.1380 24.9867 26.0628 24.9115L22.6288 21.4783ZM20.9122 14.0389C21.3692 14.4885 21.7326 15.0240 21.9815 15.6148C22.2304 16.2055 22.3599 16.8397 22.3625 17.4807C22.3651 18.1217 22.2408 18.7569 21.9967 19.3496C21.7526 19.9424 21.3935 20.4809 20.9403 20.9342C20.4870 21.3875 19.9485 21.7465 19.3557 21.9906C18.7630 22.2347 18.1278 22.3590 17.4868 22.3564C16.8458 22.3538 16.2116 22.2243 15.6209 21.9754C15.0301 21.7265 14.4945 21.3631 14.0450 20.9061C13.1465 19.9929 12.6454 18.7617 12.6506 17.4807C12.6558 16.1997 13.1670 14.9726 14.0728 14.0667C14.9787 13.1609 16.2057 12.6497 17.4868 12.6445C18.7678 12.6393 19.9990 13.1405 20.9122 14.0389Z" />
                                    </svg>

                                </div>

                                {/* // Added by vijay for filter option */}
                                {showFilterDetails && (
                                    <div className="filter-details">
                                        <button
                                            ref={(input) => { this.filterInp = input; }}
                                            onClick={this.itemsfilterslist}
                                            className=""
                                            style={{ border: 'none', background: '#ffffff' }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                                fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <line x1="4" y1="6" x2="20" y2="6" />
                                                <line x1="4" y1="12" x2="20" y2="12" />
                                                <line x1="4" y1="18" x2="20" y2="18" />
                                                <circle cx="9" cy="6" r="3" fill="black" stroke="none" />
                                                <circle cx="15" cy="12" r="3" fill="black" stroke="none" />
                                                <circle cx="7" cy="18" r="3" fill="black" stroke="none" />
                                            </svg>
                                        </button>
                                        {this.state.selectedFiltersCount > 0 && getFiltersList.length !== 0 && (
                                        <i class="fa fa-close clear-close" onClick={this.handleclearfilter}> ✕</i>
                                   )} 
                                     </div>
                                )}
                                <div className="refresh-icon" onClick={this.getUpdateMenus.bind(this)} style={this.state.styleItemFilterRefreshDiv}>
                                    {/* <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i> */}
                                    {/* level2 change */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 21 21" fill="none">
                                        <path d="M20 10.5C20 15.744 15.744 20 10.5 20C5.256 20 2.0545 14.718 2.0545 14.718M2.0545 14.718H6.3485M2.0545 14.718V19.468M1 10.5C1 5.256 5.218 1 10.5 1C16.8365 1 20 6.282 20 6.282M20 6.282V1.532M20 6.282H15.782" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>

                            {
                                this.state.pagePreview !== "" &&
                                <div className={"item-content-msg" + (outletDetails.enableGuestAppOrders === false ? " item-content-msg-dm" : "")} dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}></div>
                            }

                            <div className="filter-sticky" style={this.state.styleMoveMenuCLFFilterDivTop}>
                                {/* Pay bill, my orders, track orders */}
                                {/* comment by dasprakash 16-08-2023 jira-1302 */}
                                {/* {outletDetails.enableGuestAppOrders === true &&
                                    <div className='pay-order-track'>
                                        {(!enmConfig.checkOutTypewithPay && outletDetails.enableViewBill) &&
                                            <div className='pot-list' onClick={this.goBill}>View Bill</div>
                                        }
                                        {(enmConfig.checkOutTypewithPay && outletDetails.enableViewBill) &&
                                            <div className='pot-list' onClick={this.goBill}>Pay Bill </div>
                                        }
                                        {outletDetails.enableMyOrder && <div className='pot-list' onClick={this.goMyOrders}>My Orders</div>}
                                        {guestLoggedIn === "true" &&
                                            <>
                                                {(this.state.ordertrack && outletDetails.enableOrderTracking) && <div className='pot-list' onClick={this.goToOrderTracking}>Order Tracking</div>}
                                                {(serviceType === "FINE-DINE" && this.state.groupMemberList.length > 0) && <div className='pot-list' onClick={this.goToGroupMemberList}><i className="fa fa-users"></i> Group Members</div>}

                                            </>
                                        }
                                    </div>
                                } */}

                                {/* Classification */}

                                {
                                    // commented by dasprakash on 11-10-2023 jira-1302
                                    // this.state.getClassification.length > 1 &&
                                    // added by dasprakash on 11-10-2023 jira-1302
                                    this.state.getClassification.length > 0 &&

                                    // <div className='clf-list'>
                                    //     {
                                    //         this.state.getClassification.map((clf, idx) =>

                                    //             <div className={idx === this.state.activeClassification ? "clf-name-select" : "clf-name"} key={"clf-" + clf.classification} id={"clf-" + clf.classification} onClick={this.handleClassificationClick.bind(this, idx, clf.classificationName, clf.classification, true)}>
                                    //                 {clf.classificationName + ' (' + getItems.filter(obj => { return (obj.classification === clf.classification) }).length + ')'}
                                    //                 {/* <span> {clf.classificationName} </span><br /><span style={idx === this.state.activeClassification ? { fontSize: "10px", color: "#000" } : { fontSize: "10px", color: "#607d8b" }}>{'' + getItems.filter(obj => { return (obj.classification === clf.classification) }).length + ''}</span> */}
                                    //             </div>

                                    //         )

                                    //     }
                                    // </div>

                                    <div className='clf-list'>

                                        {/* commented by dasprakash on 21-11-2023 jira-1741 */}
                                        {/* <Slider {...clfSettings}>
                                            {
                                                this.state.getClassification.map((clf, idx) =>
                                                    <div key={"clf-main-" + clf.classification}>
                                                        <div className={idx === this.state.activeClassification ? "clf-name-select" : "clf-name"} key={"clf-" + clf.classification} id={"clf-" + clf.classification}
                                                            onClick={() => {
                                                                this.condition(idx, clf.classificationName, clf.classification, clf)
                                                            }}
                                                        >
                                                            comment by dasprakash 16-08-2023 jira-1302
                                                            {clf.classificationName + ' (' + (outletDetails.showOnlySelectedMenuGroupItems ? getGlobalItems.filter(obj => { return (obj.classification === clf.classification) }).length : getItems.filter(obj => { return (obj.classification === clf.classification) }).length) + ')'}
                                                            added by dasprakash 16-08-2023 jira-1302
                                                            {clf.classificationName}
                                                            <span> {clf.classificationName} </span><br /><span style={idx === this.state.activeClassification ? { fontSize: "10px", color: "#000" } : { fontSize: "10px", color: "#607d8b" }}>{'' + getItems.filter(obj => { return (obj.classification === clf.classification) }).length + ''}</span>

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </Slider>*/}

                                        {/* added by dasprakash on 21-11-2023 jira-1741 */}
                                        <div className='classifications-v2'>
                                            {
                                                this.state.getClassification.map((clf, idx) =>
                                                    <div key={"clf-main-" + clf.classification}>
                                                        <div className={idx === this.state.activeClassification ? "clf-name-select" : "clf-name"} key={"clf-" + clf.classification} id={"clf-" + clf.classification}
                                                            onClick={() => {
                                                                this.condition(idx, clf.classificationName, clf.classification, clf)
                                                            }}
                                                        >
                                                            {/* comment by dasprakash 16-08-2023 jira-1302 */}
                                                            {/* {clf.classificationName + ' (' + (outletDetails.showOnlySelectedMenuGroupItems ? getGlobalItems.filter(obj => { return (obj.classification === clf.classification) }).length : getItems.filter(obj => { return (obj.classification === clf.classification) }).length) + ')'} */}
                                                            {/* added by dasprakash 16-08-2023 jira-1302 */}
                                                            {/* {clf.classificationName} */}
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>{clf.classificationName}</span>

                                                            {/* <span> {clf.classificationName} </span><br /><span style={idx === this.state.activeClassification ? { fontSize: "10px", color: "#000" } : { fontSize: "10px", color: "#607d8b" }}>{'' + getItems.filter(obj => { return (obj.classification === clf.classification) }).length + ''}</span> */}

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                                {(this.state.themeId !== 2 && outletDetails.showMenuGroupInGuestApp) &&
                                    // <div style={{ height: outletDetails.showOnlyRecommendedItemImages && outletDetails.showMenuGroupImage ? '156px' : '75px' }} className='mng-list'>
                                    <div style={{ height: outletDetails.showMenuGroupImage ? '156px' : '75px' }} className='mng-list'>
                                        {/* // <div style={{ height: outletDetails.showMenuGroupImage ? '156px' : '75px' }} className={outletDetails.showMenuGroupInGuestApp ? 'mng-list' : 'hide-mng-list'}>  */}

                                        {/* <Slider {...mngpSettings}> */}   {/* Condition Added by vijay on 16-12-2023-Switch Wise */}
                                        {
                                            //this.state.recommendedList.length > 0 
                                            this.state.isRecommendedItemEnabled &&
                                            // <li onClick={this.handleMenuGroupClick.bind(this, -1, "focus-mnugrp-recommended")} data-dismiss="modal">
                                            //     <span className={-1 === this.state.activeMenuGroup ? "clist" : "list"}>Recommended</span>
                                            //     <span className={-1 === this.state.activeMenuGroup ? "ccount" : "count"}>{this.state.recommendedList.length}</span>
                                            // </li>
                                            <div style={{ padding: "0px 0px", width: "110px", display: "flex" }}>
                                                {/* <div className={-1 === this.state.activeMenuGroup ? "mng-name-select" : "mng-name"} key={"mng-" + -1} id={"mng-" + -1} onClick={this.handleMenuGroupClick.bind(this, -1, "focus-mnugrp-recommended")} > */}
                                                <div className={-1 === this.state.activeMenuGroup ? "mng-name-select" : "mng-name"} key={"mng-" + -1} id={"mng-" + -1} onClick={this.handleMenugroupsClick.bind(this, -1, this.state.selectedClassification, -1, true, true)} style={{ display: "grid" }} >


                                                    {/* {'Recommended (' + this.state.recommendedList.length + ')'} */}
                                                    {/* added by dasprakash on 28-09-2023 jira-1490 */}
                                                    {this.state.getClassification.length > 0 &&
                                                        <div>
                                                            {/* Condition Added by vijay on 16-12-2023-Switch Wise */}
                                                            {outletDetails.showMenuGroupImage &&
                                                                <img
                                                                    style={{
                                                                        borderRadius: "11px",
                                                                        marginRight: "6px",
                                                                        marginBottom: "5px"
                                                                    }}
                                                                    width="80"
                                                                    height="80"
                                                                    alt="recommended"
                                                                    src={
                                                                        this.state.getClassification[this.state.classificationRecommendedImageIndex].recommendedItemImageUrl === '' ?
                                                                            defaultimg :
                                                                            this.state.getClassification[this.state.classificationRecommendedImageIndex].recommendedItemImageUrl
                                                                    }
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                    {/* <p style={{ marginBottom: "-7px" }}>Recommended</p>  added by vijay based on conditions */}
                                                    <p style={{ marginBottom: outletDetails.showMenuGroupImage ? "-7px" : "2px" }}>Recommended</p>
                                                </div>
                                            </div>
                                        }

                                        {this.state.getMenus.map((mng, idx) =>
                                            <div style={{ display: "flex" }} key={"mng-main-" + mng.menuGroupId}>
                                                {/* <div className={idx === this.state.activeMenuGroup ? "mng-name-select" : "mng-name"} key={"mng-" + mng.menuGroupId} id={"mng-" + mng.menuGroupId} onClick={this.handleMenugroupsClick.bind(this, idx, this.state.selectedClassification, mng.menuGroupId, true)} > */}
                                                {/* <div className={idx === this.state.activeMenuGroup ? "mng-name-select" : "mng-name"} key={"mng-" + mng.menuGroupId} id={"mng-" + mng.menuGroupId} onClick={this.handleMenuGroupClick.bind(this, idx, "focus-mnugrp-" + mng.menuGroupId)}> */}
                                                {/* <div style={{ padding: "13px 0px", width: "110px", display: "grid" }} className={idx === this.state.activeMenuGroup ? "mng-name-select" : "mng-name"} key={"mng-" + mng.menuGroupId} id={"mng-" + mng.menuGroupId} */}
                                                <div style={{ padding: "13px 0px", width: `${this.getMenuGroupNameWidth(mng.menuGroupName)}px`, display: "grid" }} className={idx === this.state.activeMenuGroup ? "mng-name-select" : "mng-name"} key={"mng-" + mng.menuGroupId} id={"mng-" + mng.menuGroupId}
                                                    ref={(ref) => {
                                                        this.clickedMenuGroupRef = ref;
                                                    }}
                                                    onClick={() => {
                                                        this.handleMenugroupsClick(
                                                            idx,
                                                            this.state.selectedClassification,
                                                            mng.menuGroupId,
                                                            mng,
                                                            idx,
                                                            false,
                                                            false
                                                        );
                                                        this.menuGroupScroll(mng.menuGroupId);
                                                    }}
                                                >
                                                    {/*  added by dasprakash 16-08-2023 jira-1302 */}
                                                    {/* Condition Added by vijay on 16-12-2023-Switch Wise */}
                                                    {outletDetails.showMenuGroupImage &&
                                                        <div>
                                                            {mng.imageUrl === '' ?
                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto" }}>
                                                                    <img style={{ borderRadius: "11px", marginBottom: "5px" }} width="80" height="80" alt={mng.menuGroupName} src={defaultimg} />
                                                                    {/* <p style={{ marginBottom: "-11px", }}> {mng.menuGroupName}</p> */}
                                                                </div>
                                                                :
                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto" }}>
                                                                    <img style={{ borderRadius: "11px", marginBottom: "5px" }} width="80" height="80" alt={mng.menuGroupName} src={mng.imageUrl} />

                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    <p style={{ marginBottom: "-7px" }}>
                                                        {mng.menuGroupName.length > 30
                                                            ? mng.menuGroupName.slice(0, 25) + "..."
                                                            : mng.menuGroupName}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                        }
                                        {/* </Slider> */}
                                    </div>
                                }

                            </div>


                            <div id="fist-item-row"></div>
                            {this.state.recommendedList.length > 0 &&
                                <>
                                    {/* {this.state.themeId === 1 && // Vertical / double item Mode

                                        //  this.handleGenerateRecommendedListDom()
                                        this.handleGenerateRowRecommendedListDom()
                                    }
                                    {this.state.themeId === 2 &&  // horizantal / Multi Item Mode
                                        this.handleVerticalGenerateRecommendedListDom()
                                    }
                                    {this.state.themeId === 3 &&  // horizantal / Row mode single mode
    handleGenerateRowRecommendedAccordionListDom() {
                                        this.handleGenerateRowRecommendedListDom()  
                                    } */}
                                    {outletDetails.showOnlySelectedMenuGroupItems === false ? this.handleGenerateRowRecommendedListAccordionDom() : this.handleGenerateRowRecommendedListDom()}
                                </>
                            }
                            {outletDetails.showOnlySelectedMenuGroupItems === false ? this.handleGenerateItemListAccordionDom() : this.handleGenerateItemListDom()}
                            {this.state.getMenus.length > 0 && (
                                <div className={`clear clear-end ${outletDetails.showOnlySelectedMenuGroupItems ? "hidden" : ""}`}></div>
                            )}
                            {this.state.getMenus.length === 0 &&
                                < div className="no-menu-img"><img src="/img/no-image/no-menus.png" alt="lucid-no-menus" /></div>
                            }
                            <Modifier
                                ref={modifierRef}
                                callBackModifier={this.callBackModifier}
                                modifierItemCFoodType={this.state.modifierFoodType}
                                modifierItemCItemName={this.state.modifierItemName}
                                modifierItemCItemId={this.state.modifierItemId}
                                modifierGroupIds={this.state.modifierGroupIds}
                                modifierList={getModifiers}
                                modifierTotalItemAmount={this.state.totalAmount}
                                modifierTotalItems={this.state.totalItems}
                                modifierItemCQty={this.state.modifierItemCQty}
                                modifierItemCQtyId={this.state.modifierItemCId}
                                modifierItemCRate={this.state.modifierItemCRate}
                                modifierItemCGroupId={this.state.modifierItemCGroupId}
                                modifierItemCTaxId={this.state.modifierItemCTaxId}
                                modifierItemCChargeIds={this.state.modifierItemCChargeIds}
                                getTaxes={getTaxes}
                                getCharges={getCharges}
                                showModal={this.state.showModal}
                            ></Modifier>
                            <Request
                                ref={reqeustRef}
                                callBackRequest={this.callBackRequest}
                                token={sessionStorage.getItem('user-token')}
                                mobileNo={sessionStorage.getItem('user-mobileno')}
                                propertyId={sessionStorage.getItem('user-propertyid')}
                                outletCode={sessionStorage.getItem('user-outletcode')}
                                tableNo={sessionStorage.getItem('user-tableno')}
                                serviceType={sessionStorage.getItem('user-servicetype')}
                            ></Request>
                            <GroupMembers
                                ref={groupMemberRef}
                                callBackGroupMembers={this.callBackGroupMembers}
                                token={sessionStorage.getItem('user-token')}
                                mobileNo={sessionStorage.getItem('user-mobileno')}
                                propertyId={sessionStorage.getItem('user-propertyid')}
                                outletCode={sessionStorage.getItem('user-outletcode')}
                                tableNo={sessionStorage.getItem('user-tableno')}
                                serviceType={sessionStorage.getItem('user-servicetype')}
                            ></GroupMembers>
                            {/* image popup */}


                            <ImagePopup
                                ref={imageRef}
                                imgItemName={this.state.imgItemName}
                                imgItemDesc={this.state.imgItemDesc}
                                imgItemPrice={this.state.imgItemPrice}
                                imgItemFType={this.state.imgItemFType}
                                imgUrl={this.state.itemImgUrl}
                                item={this.state.item}
                                id={this.state.id}
                                menuGroupsId={this.state.menuGroupsId}
                                handleQtyIncDec={this.handleQtyIncDec}                            >
                            </ImagePopup>

                            {/* <Footer></Footer> */}
                            {/* {(this.state.themeId === 2 && this.state.searchText.trim() === '') && <PopupMenus recommendedList={this.state.recommendedList} getMenus={this.state.getMenus} ></PopupMenus>} */}

                            {/* <div className="menu-goup">
                                <button className="theme-button btn" data-toggle="modal" data-target="#filter-menu-group-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU</button>
                            </div> */}
                            {this.state.totalItems > 0 &&
                                <Cart
                                    totalItems={this.state.totalItems}
                                    totalAmount={this.state.totalAmount}
                                    getMenus={getMenus}
                                    getItems={getItems}
                                    getModifiers={getModifiers}
                                    getTaxes={getTaxes}
                                    getCharges={getCharges}
                                    active={showCart}
                                    checkOutTypewithPay={enmConfig.checkOutTypewithPay}
                                    userSessionId={userSessionId}
                                ></Cart>
                            }
                            {/* <div className="cart-div" onClick={this.goCart}>
                                <div className="c-left"> <span>3</span> Items | ₹<span> 525</span></div>
                                <div className="c-right">View Cart <i className="fa fa-shopping-bag"></i></div>
                            </div> */}


                            {/* class paginaion created by dasprakash on 25-08-2023 */}

                            {(this.state.paginationEnable && outletDetails.showOnlySelectedMenuGroupItems) && (
                                <div className={this.state.pagnitationPrvShow !== -1 ? "pagination_v2" : "pagination-fal_v2"}>
                                    {/* level 6 change */}
                                    {this.state.pagnitationPrvShow !== -1 && this.state.recpagination === true ? (
                                        <div className='pagination_v2-previous' onClick={() => this.handleButtonClick(this.state.pagnitationPrvShow - 1, this.state.perviousPage)}>
                                            <div className='pagination_v2-previous-data' style={{ marginTop: "15px" }}>

                                                <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", marginRight: "2px" }}>
                                                    <p style={{ fontWeight: "400", marginLeft: "2px" }}>Previous</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                                                        <path d="M12.5 6.25L6.25 12.5L12.5 18.75" stroke="#18A437" strokeWidth={2.5} strokeLinecap="round" />
                                                    </svg>
                                                </div>
                                                {outletDetails.showMenuGroupImage &&
                                                    <div>
                                                        {this.state.perviousPage.length === 0 ? (
                                                            <img src={this.state.getClassification[this.state.classificationRecommendedImageIndex].recommendedItemImageUrl === '' ? defaultimg : this.state.getClassification[this.state.classificationRecommendedImageIndex].recommendedItemImageUrl} alt="Next Page Icon" />
                                                        ) : (
                                                            <img
                                                                src={this.state.perviousPage.imageUrl === '' ? defaultimg : this.state.perviousPage.imageUrl}
                                                                alt="Next Page Icon"
                                                            />
                                                        )}
                                                    </div>
                                                }
                                                <div className='pagination_v2-previous-prv'>
                                                    {this.state.perviousPage.length === 0 ? (
                                                        <p style={{ marginRight: "5px" }}>Recommended</p>
                                                    ) : (
                                                        <p style={{ marginRight: "5px" }}>
                                                            {this.state.perviousPage.menuGroupName.length > 30
                                                                ? this.state.perviousPage.menuGroupName.slice(0, 30) + "..."
                                                                : this.state.perviousPage.menuGroupName}
                                                        </p>
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    ) : (
                                        <p style={{ visibility: "hidden" }}></p>
                                    )}
                                    {/*  */}


                                    {this.state.pagnitationPrvShow !== this.state.pagnitationNextShow && (
                                        <div className='pagination_v2-next' onClick={() => this.handleButtonClick(this.state.pagnitationPrvShow + 1, this.state.nextPage)}   >
                                            <div className='pagination_v2-next-data' style={{ marginTop: "15px" }}>
                                                {/* lev6 change */}
                                                <div style={{ display: "flex", alignItems: "center", marginLeft: "27px" }}>
                                                    <p style={{ fontWeight: "400", marginRight: "4px" }}>Next</p>
                                                    <svg style={{ transform: "rotate(178deg)" }} xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                                                        <path d="M12.5 6.25L6.25 12.5L12.5 18.75" stroke="#18A437" strokeWidth={2.5} strokeLinecap="round" />
                                                    </svg>

                                                </div>
                                                {outletDetails.showMenuGroupImage &&
                                                    <div>
                                                        {this.state.nextPage.imageUrl === '' ?
                                                            <img src={defaultimg} alt="Next Page Icon"></img>
                                                            :
                                                            <img src={this.state.nextPage.imageUrl} alt="Next Page Icon"></img>
                                                        }
                                                    </div>
                                                }
                                                <div className='pagination_v2-next-Next'>
                                                    <p style={{ marginLeft: "5px" }}>
                                                        {this.state.nextPage.menuGroupName.length > 30
                                                            ? this.state.nextPage.menuGroupName.slice(0, 30) + "..."
                                                            : this.state.nextPage.menuGroupName}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            )}




                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
