import React, { Component } from 'react';
import './itemfilters.scss';
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../components/loadinganimation';
let urlPattern = '';

export default class ItemsFilters extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        const selectedFiltersFromStorage = JSON.parse(sessionStorage.getItem('selectedFilters') || '[]');
        this.state = {
            loading: false,
            getMenus: [],
            getItems: [],
            activeCategories: [],
            msgShow: false,
            alertMessage: '',
            msgType: '',
            selectedCategories: selectedFiltersFromStorage,
            showError: false,
           
        };
    }

    componentDidMount() {
        urlPattern = sessionStorage.getItem('url-pattern');
        this.loadAndFilterItems();
        window.scrollTo(0, 0);
    }
    
 

    loadAndFilterItems = () => {
        const items = sessionStorage.getItem("getmenuItems");
        const menus = sessionStorage.getItem("getMenuGroups");
        
        if (items) {
            const parsedItems = JSON.parse(items);
            
            // Added background and text colors for each category
            const activeCategories = [
                { 
                    key: 'newItem', 
                    label: 'New', 
                    active: parsedItems.some(item => item.newItem),
                    bgColor: '#FFE9D0CC',  // Coral red
                    textColor: '#FF6A00'
                },
                { 
                    key: 'bestSellerItem', 
                    label: 'BestSeller', 
                    active: parsedItems.some(item => item.bestSellerItem),
                    bgColor: '#FFE1E1',  // Purple 
                    textColor: '#FF0000'
                },  
                
                { 
                    key: 'mustTryItem', 
                    label: 'Must Try', 
                    active: parsedItems.some(item => item.mustTryItem),
                    bgColor: '#EBFFFB',  // Dusty rose
                    textColor: '#1ABC9C'
                },
                { 
                    key: 'popularItem', 
                    label: 'Popular', 
                    active: parsedItems.some(item => item.popularItem),
                    bgColor: '#FFFAE7',  // Turquoisebackground: #F4D03F;
                    textColor: '#F4D03F'
                },
                { 
                    key: 'todaySpecialItem', 
                    label: "Today's Special", 
                    active: parsedItems.some(item => item.todaySpecialItem),
                    bgColor: '#E4F4FF',  // Sky blue
                    textColor: '#3498DB'
                },
                { 
                    key: 'chefSpecialItem', 
                    label: "Chef's Special", 
                    active: parsedItems.some(item => item.chefSpecialItem),
                    bgColor: '#FAEFFF',  // Sage green
                    textColor: '#8E44AD'
                },
                
            ].filter(category => category.active);
    
            this.setState({
                getItems: parsedItems,
                getMenus: menus ? JSON.parse(menus) : [],
                activeCategories,
                loading: false
            });
        }
    };

    goBack() {
        this.props.history.push('/items' + urlPattern, { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
    }

    handleCategoryClick = (categoryKey) => {
        this.setState(prevState => {
            const selectedCategories = [...prevState.selectedCategories];
            const index = selectedCategories.indexOf(categoryKey);

            if (index === -1) {
                selectedCategories.push(categoryKey);
            } else {
                selectedCategories.splice(index, 1);
            }

            return { selectedCategories };
        });
    };

    filterItems = () => {
        const selectedCategoryKeys = this.state.selectedCategories;
        sessionStorage.setItem('selectedFilters', JSON.stringify(selectedCategoryKeys));

        const queryParams = new URLSearchParams();
        selectedCategoryKeys.forEach(category => {
            queryParams.append('filters', category);
        });

        const allItems = JSON.parse(sessionStorage.getItem("getmenuItems") || "[]");
        const filteredItems = allItems.filter(item =>
            selectedCategoryKeys.some(filter => {
                const filterMap = {
                    'newItem': item.newItem,
                    'popularItem': item.popularItem,
                    'todaySpecialItem': item.todaySpecialItem,
                    'chefSpecialItem': item.chefSpecialItem,
                    'mustTryItem': item.mustTryItem,
                    'bestSellerItem': item.bestSellerItem
                };
                return filterMap[filter] === true;
            })
        );

        if (filteredItems.length > 0) {
            sessionStorage.setItem("item-tag-filter-list", JSON.stringify(filteredItems));
        }

        this.props.history.push({
            pathname: '/items' + urlPattern,
            search: queryParams.toString(),
            state: {
                selectedCategories: selectedCategoryKeys,
                focusGroup: "focus-mnugrp-focus-mnugrp-recommended"
            }
        });
    };


filterCancelItems = () =>{
    sessionStorage.removeItem('selectedFilters');
    this.setState({ selectedCategories: [] }); // Clear selected categories in state
}

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
             this.setState({ msgShow: false });
        }, Config.averageMsgTimeout);
    };
  
    render() {
        const { loading, activeCategories } = this.state;

        return (
            <div>
                {loading && <LoadingAnimation />}
                <div id="content" className="items-top">
                    {this.state.msgShow && (
                        <ErrorAlert
                            alertMsg={this.state.alertMessage}
                            msgType={this.state.msgType}
                            msgShow={this.state.msgShow}
                        />
                    )}

                    <div className="content-warp">
                        <div className="section-items" style={{height:'89vh'}}>
                            <div className="back-btn flex items-center text-3xl ml-2.5 mt-1.5 p-0.75" style={{ display: 'flex', justifyContent:'space-between', margin: '15px', borderBottom: '1px solid #d7d7d7' }}>
                                <div className="icon-back cursor-pointer" onClick={this.goBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 24" fill="none">
                                        <path d="M12 2L2 12L12 22" stroke="#111111" strokeWidth="2.5" strokeLinecap="round" />
                                    </svg>
                                    <p className="text-gray-700 text-sm font-normal text-[14px] header-part" style={{marginTop:'-19px' ,marginLeft:'2rem'}}>
                                    Filters
                                </p>
                                </div>
                               
                                
                                {
                                    this.state.selectedCategories.length > 0 && (
                                        <div className='clear-filters'>
                                            <button className='clear-button' onClick={this.filterCancelItems}>Clear All</button>
                                        </div>
                                    )
                                }
                                
                               
                            </div>

                            <div className="categories-container">
                                <div className="filter-categories">
                                    {activeCategories.reduce((rows, category, index) => {
                                        if (index % 2 === 0) {
                                            rows.push([category]);
                                        } else {
                                            rows[rows.length - 1].push(category);
                                        }
                                        return rows;
                                    }, []).map((pair, rowIndex) => (
                                        <div key={rowIndex} className="filters-itemlist">
                                            {pair.map(category => (
                                                <div
                                                    key={category.key}
                                                    className={`filter-item ${this.state.selectedCategories.includes(category.key)
                                                            ? 'selected-filters'
                                                            : 'non-selected-filters'
                                                        }`}
                                                    onClick={() => this.handleCategoryClick(category.key)}
                                                    style={{
                                                        backgroundColor: category.bgColor,
                                                        color: category.textColor
                                                    }}
                                                >
                                                    {category.label}
                                                    {this.state.selectedCategories.includes(category.key) && (
                                                        <button
                                                            className="cancel-button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.handleCategoryClick(category.key);
                                                            }}
                                                        >
                                                            ✕
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            {pair.length === 1 && <div className="filter-item-placeholder"></div>}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* <div className="categories-container">
                                <div className="filter-categories">
                                    {activeCategories.reduce((rows, category, index) => {
                                        if (index % 2 === 0) {
                                            rows.push([category]);
                                        } else {
                                            rows[rows.length - 1].push(category);
                                        }
                                        return rows;
                                    }, []).map((pair, rowIndex) => (
                                        <div key={rowIndex} className="filters-itemlist">
                                            {pair.map(category => (
                                                <div
                                                    key={category.key}
                                                    className={`filter-item ${this.state.selectedCategories.includes(category.key)
                                                        ? 'selected-filters'
                                                        : 'non-selected-filters'
                                                        }`}
                                                    onClick={() => this.handleCategoryClick(category.key)}
                                                >
                                                    {category.label}
                                                    {this.state.selectedCategories.includes(category.key) && (
                                                        <button
                                                            className="cancel-button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.handleCategoryClick(category.key);
                                                            }}
                                                        >
                                                            ✕
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            {pair.length === 1 && <div className="filter-item-placeholder"></div>}
                                        </div>
                                    ))}
                                </div>
                            </div> */}

                            {activeCategories.length === 0 && (
                                <div className="no-menu-img">
                                    <img src="/img/no-image/no-menus.png" alt="lucid-no-menus" />
                                </div>
                            )}

                        </div>

                        <div className="filter-items">
                        
                        <button className="apply-filters" onClick={this.filterItems}>
                            Apply
                        </button>
                    </div>
                    </div>
                   
                       
                 
                </div>
            </div>
        );
    }
}