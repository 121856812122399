import React, { Component } from 'react';
import './orderfailed.scss';
import LoadingSVGAnimation from '../../components/checkmarkanimation/failed';
//import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
let outletDetails = null;  // Added by vijay on 04-11-2023-start
let urlPattern = '';
export default class OrderFailed extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotocart = this.gotocart.bind(this);
        this.gotoMyOrders = this.gotoMyOrders.bind(this);
        this.state = {
            cartValue: 1500,
            loading: false,
            orderId: '',
            outLetImage: '', // Added by vijay on 04-11-2023-start
        }
    }
    componentWillMount() {

        outletDetails = sessionStorage.getItem('user-outletDetails');   // Added by vijay on 04-11-2023-start
        const parsedOutletDetails = JSON.parse(outletDetails);
        this.setState({ outLetImage: parsedOutletDetails.outletImageUrl })


        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        sessionStorage.removeItem('redirect-page');
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern);   // Modified by vijay on 27-10-2023 Jira id -1687
        }, Config.minMsgTimeout); // Modified  by vijay on 27-10-2023 Jira id -1687

        sessionStorage.removeItem("paymentInitiatedId");
        sessionStorage.removeItem("vendorTransactionId");
        sessionStorage.removeItem("vendorId");
        sessionStorage.removeItem("vendorName");
        sessionStorage.removeItem("paymentStatus");
        sessionStorage.removeItem("order-instruction");
        //sessionStorage.removeItem("user-vendorOrderId"); Commented by prem 16:20 due to vendor id null pass call api
    }
    goBack() {
        // this.props.history.push('/home');
    }
    gotocart() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern);
        }, Config.minMsgTimeout);

    }
    gotoMyOrders() {
        // this.props.history.push('/myorders');
    }
    render() {
        return (
            <div>
                {/* //Commended by vijay on 26-10-2023 jira id -1067 */}
                {/* {this.state.loading && <LoadingAnimation />} */}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-orderfailed">
                            {/* <div className="heading-page">     //Commended by vijay on 26-10-2023 jira id -1067
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            {/* </div>  */}

                            {/* //Added by vijay on 26-10-2023 Jira id -1067 */}
                            <div className="logo-v2-failed">
                                <img style={{ width: "220px", marginLeft: "12px", marginBottom: "30px" }} src={this.state.outLetImage} alt="this.state.outLetImage" />
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">

                                        <div className="msg">Order not <br />Placed</div>
                                        <div><LoadingSVGAnimation /></div>
                                        {/* //Commended  by vijay on 26-10-2023 Jira id -1067 */}
                                        {/* <div className="msg-order-id">Please Try Again</div> */}
                                        {/* <div><button className="theme-button btn" onClick={this.gotocart}>Retry</button></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

